<template>
  <div class="lug-spinner">
    <div class="container">
      <div ref="item" class="item" @mousedown="onMouseDown" @touchstart="onMouseDown">
        <div class="hr level-1" />
        <div class="hr level-2" />
        <ul ref="list">
          <li v-for="(item, index) in items" :data-value="item" :key="'spinner-item-' + index" :class="{disabled: disabledItems.some((o) => o === item), activated: index === activatedIndex}">
            {{ item }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: [Object, Array],
      default: () => new Array(20).fill(0).map((o, i) => o + i)
    },
    disabledItems: {
      type: [Object, Array],
      default: () => []
    },
    value: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      selectedItem: "",
      itemHeight: 3.25 * parseFloat(getComputedStyle(document.documentElement).fontSize),
      position: 0,
      offset: 0,
      startY: 0,
      previousY: 0,
      isInitialDataSet : false
    };
  },

  computed: {
    activatedIndex() {
      const value = Math.abs(Math.round(this.position / this.itemHeight));

      return value;
    },

  },

  watch: {
    value: {
      handler(value){
        this.selectedItem = value
        const index = this.items.indexOf(value) > -1 ? this.items.indexOf(value) : 0

        if(!this.isInitialDataSet){
          this.position = -1 * this.itemHeight * index;
          this.isInitialDataSet = true;
        }
      },
      immediate: true
    },

    position: {
      handler(position){
        const value = Math.abs(Math.round(position / this.itemHeight));
        this.selectedItem = this.items[value];
      }
    },

    itemHeight(){
      this.position = -1 *  this.items.findIndex( item => item === this.selectedItem) * this.itemHeight;
      this.setPosition();
    },

    selectedItem: {
      handler(value){
        this.$emit('select', value)

        this.$refs.list.querySelectorAll('li.activated').forEach( item => item.classList.remove('activated'))
        this.$refs.list.querySelectorAll('li')[this.activatedIndex].classList.add('activated')
      }
    }
  },

  async mounted() {
    await this.$nextTick();
    window.addEventListener('resize', () => {
      this.itemHeight = this.calculateRem();
    })

    this.setPosition(0);
  },

  methods: {
    calculateRem(){
      const clientWidth = window.innerWidth;
      let rem = 12;

      if(clientWidth < 480){
        rem = 12;
      }else if(480 <= clientWidth && clientWidth < 640){
        rem = 13;
      }else if(640 <= clientWidth && clientWidth < 800){
        rem = 14;
      }else{
        rem = 16;
      }

      return 3.25*rem;
    },

    onMouseDown(e) {
      if (e.cancelable) e.preventDefault();
      this.$emit('mousedown')

      this.$refs.item.classList.add('expand');

      this.previousY = e.touches ? e.touches[0].clientY : e.clientY;
      this.startY = e.touches ? e.touches[0].clientY : e.clientY;

      this.$el.addEventListener("mousemove", this.onMouseMove, {passive: false });
      this.$el.addEventListener("mouseup", this.onMouseUp, { passive: false });
      this.$el.addEventListener("touchmove", this.onMouseMove, {passive: false });
      this.$el.addEventListener("touchend", this.onMouseUp, { passive: false });
    },

    onMouseMove(e) {
      if (e.cancelable) e.preventDefault();

      let clientY = e.touches ? e.touches[0].clientY : e.clientY;
      this.offset = clientY - this.previousY;

      let maxPosition = -(this.items.length - 1) * this.itemHeight;
      let _position = this.position + this.offset / 2;
      this.position = Math.max(maxPosition, Math.min(this.itemHeight, _position));
      this.previousY = e.touches ? e.touches[0].clientY : e.clientY;

      this.setPosition(0);
    },

    onMouseUp(e) {
      if (e.cancelable) e.preventDefault();
      this.$emit('mouseup')

      let maxPosition = -(this.items.length - 1) * this.itemHeight;
      let rounderPosition = Math.round((this.position + this.offset * 5) / this.itemHeight) * this.itemHeight;
      let finalPosition = Math.max(maxPosition, Math.min(0, rounderPosition));

      this.position = finalPosition;

      let currentClientY = e.changedTouches ? e.changedTouches[0].clientY: e.clientY;

      let movedY = currentClientY - this.startY;

      if (this.isDisabledItems(this.activatedIndex)) {
        let value;

        if (Math.abs(movedY) > this.itemHeight * 3) {
          let lowerValidIndexes = [],
            higherValidIndexes = [];

          for (let i = 0; i < this.items.length - 1; i++) {
            if (!this.isDisabledItems(i)) {
              if (i < this.activatedIndex) {
                lowerValidIndexes.push(i);
              } else if (i > this.activatedIndex) {
                higherValidIndexes.push(i);
              }
            }
          }

          if(movedY > 0){
            if (lowerValidIndexes.length > 0) value = lowerValidIndexes[lowerValidIndexes.length - 1];
            else if (higherValidIndexes.length > 0) value = higherValidIndexes[0]
            else value = 0
          }else{
            if (higherValidIndexes.length > 0) value = higherValidIndexes[0];
            else if (lowerValidIndexes.length > 0) value = lowerValidIndexes[lowerValidIndexes.length - 1];
            else value = 0
          }

        } else {
          let loop = true;
          let nth = 1;

          while (loop) {
            let lowerValue, higherValue;

            if (this.activatedIndex - nth >= 0) {
              lowerValue = !this.isDisabledItems(this.activatedIndex - nth);
            }

            if (this.activatedIndex + nth <= this.items.length - 1) {
              higherValue = !this.isDisabledItems(this.activatedIndex + nth);
            }

            if (higherValue) {
              value = this.activatedIndex + nth;
              loop = false;
            } else if (lowerValue) {
              value = this.activatedIndex - nth;
              loop = false;
            } else {
              nth = nth - 1;
            }
          }
        }
        this.position += -1 * (value - this.activatedIndex) * this.itemHeight;
      }

      this.$el.removeEventListener("mousemove", this.onMouseMove);
      this.$el.removeEventListener("mouseup", this.onMouseUp);
      this.$el.removeEventListener("touchmove", this.onMouseMove);
      this.$el.removeEventListener("touchend", this.onMouseUp);

      this.setPosition(Math.abs(this.offset) / 100 + 0.1);
      
      this.$refs.item.classList.remove('expand');
    },

    setPosition(transformDuration = 0) {
      let itemPosition = `
        transition-timing-function: ease-in;
        transition: transform ${transformDuration}s;
        transform: translateY(${this.position}px);
      `;

      this.$refs.list.style.cssText = itemPosition;
    },

    isDisabledItems(index) {
      return this.disabledItems.some((date) =>
        data === this.items[index]
      );
    }
  },
};
</script>
