import Vue from 'vue';
import config from '@/config';
import store from '@/store';
import '@/modules/filters';
import { Language } from '@/modules/language';

import { plugin as VueCustomUtils } from '@/utils';

// Plugins
import VueRichForm from '@/components/modules/RichForm';
import VueMoment from 'vue-moment';
import VuePortal from 'portal-vue';
import VueYoutube from 'vue-youtube';
import VueProgressiveImage from 'vue-progressive-image';
import VueSticky from 'vue-sticky-directive';
import VueAxios from 'vue-axios';
import * as _axios from 'axios';

export const axios = _axios;

axios.interceptors.request.use(
  function (config) {
    const { pathname = '', search = '' } = window.location;
    config.headers['page-location'] = pathname + search;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (res) {
    const { status, error, data } = res.data;
    if (status === 'error' && error === 'SERVER_DOWN') {
      store.commit('SERVER_DOWN', { axios, res });
    }
    else {
      store.commit('SERVER_AVAILABLE', { axios, res });
    }
    return res;
  },
  function (error) {
    if (error?.response?.status >= 500 || JSON.stringify(error).indexOf('Network Error') > -1) {
      store.commit('SERVER_DOWN', { axios, error });
    }

    return Promise.reject(error);
  }
);

import * as VueGoogleMaps from 'vue2-google-maps';
require('default-passive-events');

// PostCode
import VueDaumPostcode from 'vue-daum-postcode';

// WNInterface
import VueWNInterface, { WNInterface } from 'vue-wni';

// Browser
import VueBrowser from './browser';

// Analytics
import VueAnalytics from './analytics';

// Logger
import VueLogger from './logger';

// Directives
import VueDirectives from './directives';

// ChannelTalk
import VueChannelTalk from '@/components/modules/ChannelTalk';

//Skeleton
import VueSkeleton from 'vue-loading-skeleton';

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

import VueClipboard from 'vue-clipboard2';

import VueIframe from '@/mixins/iframe';

import VueLugstayV2 from '@/mixins/lugstay.v2';

import VueConnectSDK from './connect-sdk';

if (config.mode !== 'production' && WNInterface.isEmulator) {
  require('./wni-simulator');
}

Vue.mixin(VueIframe);
Vue.mixin(VueLugstayV2);

Vue.use(VueCustomUtils);
Vue.use(VueRichForm);
Vue.use(VueMoment);
Vue.use(VuePortal);
Vue.use(VueYoutube);
Vue.use(VueSticky);
Vue.use(VueProgressiveImage);
Vue.use(VueAxios, axios);
Vue.use(require('vue-cookies'));
Vue.use(VueDaumPostcode, { name: 'daum-postcode' });
Vue.use(VueWNInterface);
Vue.use(VueBrowser);
Vue.use(VueAnalytics);
Vue.use(VueLogger);
Vue.use(VueDirectives);
Vue.use(VueChannelTalk);
Vue.use(VueSkeleton);
Vue.use(VueViewer);
Vue.use(VueClipboard);
Vue.use(VueGoogleMaps, {
  load: {
    key: config.get('googleMapsApiKey'),
    libraries: ['places', 'geometry'],
    version: 'weekly',
    language: Language.googleMapLocale(localStorage.getItem('lugstay_language'))
  }
});
Vue.use(VueConnectSDK);