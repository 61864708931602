<template>
  <div v-button class="ui-button text-button" :class="'button-color-'+color+' button-size-'+size+' button-display-'+display" @click="$emit( 'click' )">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "medium"
    },
    color: {
      type: String,
      default: "primary"
    },
    display: {
      type: String,
      default: "inline"
    },
  }
}
</script>
