<template>
  <div class="container">
    <div class="message">인증요청을 하였습니다.</div>
    <div class="buttons">
      <button class="ok" @click="$emit('close-modal')">확인</button>
    </div>
  </div>
</template>

<script>
export default {
  props: []
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .message {
    text-align: center;
    font-size: unit(20);
    margin-top: unit(30);
    margin-bottom: unit(58);
  }
  .buttons {
    display: flex;
    flex-direction: row;
    gap: unit(10);
    // position: absolute;
    // top: unit(2);
    button {
      font-size: unit(14);
      width: 100%;
      height: unit(46);
      border-radius: unit(24);
      text-align: center;
      margin: auto;

      &.ok {
        background-color: #48d9eb;
        color: #ffffff;
      }
    }
  }
}
</style>
