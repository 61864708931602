<template>
  <div class="includes" id="promotion-window">
    <img :src="'/img/promotion-'+payload.content+'.jpg'" />
    <button @click="signup">{{ $__t( '회원가입하기' ) }}</button>
  </div>
</template>
<script>
export default {
  props: [ "payload" ],
  methods: {
    signup(){
      this.$store.commit( "modal/REMOVE_ITEM", { component: "Promotion" });
      this.$store.commit( "modal/ADD_ITEM", { component: "LoginForm", size: "small", redirect: "/mypage/coupons", mode: "register" });
    }
  }
}
</script>

<style lang="scss">
#promotion-window {
  background-color: #fff;
}
</style>