import Vue from "vue";
import config from "@/config";

class ChannelService {
  constructor() {
    if (config.get("channelTalkKey")) {
      if (this._instance) return this._instance;
      this._instance = this;
    }
  }

  loadScript() {
    if (this._instance) {
      var w = window;
      if (w.ChannelIO) {
        (window.console.error || window.console.log || function () {})("ChannelIO script included twice.");
        return new Error("ALREADY_USED_DATA");
      }
      var ch = function () {
        ch.c(arguments);
      };
      ch.q = [];
      ch.c = function (args) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      const l = () =>{
        if (w.ChannelIOInitialized) {
          return;
        }
        this._initialized = true;
        w.ChannelIOInitialized = true;
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
        s.charset = "UTF-8";
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      }
      if (document.readyState === "complete") {
        l();
      } else if (window.attachEvent) {
        window.attachEvent("onload", l);
      } else {
        window.addEventListener("DOMContentLoaded", l, false);
        window.addEventListener("load", l, false);
      }
    }
  }

  boot(settings) {
    if (this._instance) {
      window.ChannelIO("boot", settings);
    }
  }

  shutdown() {
    if (this._instance) {
      window.ChannelIO("shutdown");
    }
  }

  show() {
    if (this._instance) {
      let element = document.getElementById("ch-plugin");
      let element2 = document.getElementById("ch-plugin-launcher");

      if (element && element2) {
        element.style.display = "";
        element2.style.display = "";
      }
    }
  }

  hide() {
    if (this._instance) {
      let element = document.getElementById("ch-plugin");
      if (element) {
        element.style.display = "none";
      }
    }
  }

  hideOnlyLauncher() {
    if (this._instance) {
      let element = document.getElementById("ch-plugin-launcher");
      if (element) {
        element.style.display = "none";
      }
    }
  }

  openChat() {
    if (this._instance) {
      if (this._initialized) {
        return ChannelIO("showMessenger");
      }

      try {
        this.loadScript();
        this.boot({
          pluginKey: config.get("channelTalkKey"),
          hideChannelButtonOnBoot: true,
        });
        this.addListeners();

        setTimeout(() => {
          ChannelIO("showMessenger");
        }, 0);
      } catch (e) {
        console.error(e)
      }

      ChannelIO("showMessenger");
    }
  }

  addListeners() {
    if (this._instance) {
      window.ChannelIO("onShowMessenger", function (e) {
        Vue.$analytics.logEvent("channel talk click");
      });
    }
  }

  get initialized() {
    return this._initialized;
  }
}

export const plugin = {
  install: (app, options = {}) => {
    app.$channelTalk = new ChannelService();

    Object.defineProperties(app.prototype, {
      $channelTalk: {
        get() {
          return app.$channelTalk;
        },
      },
    });
  },
};

export default plugin;
