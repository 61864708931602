<template>
  <div class="ui-textarea" :class="{ hover: state.hover, focus: state.focus, fill: state.fill, error: error }">
    <label ref="hover">
      <div class="label">{{ label }}</div>
      <div class="body">
        <textarea ref="input" :placeholder="state.focus ? placeholder : ''" v-model="model" :readonly="readonly"/>
      </div>
    </label>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: [ "value", "type", "placeholder", "label", "error", "readonly" ],
  data(){
    var state = { hover: false, focus: false, fill: true, error: false };
    return { state };
  },
  computed: {
    model: {
      get(){
        if( this.value ){
          this.state.fill = true;
        }else{
          this.state.fill = false;
        };
        return this.value;
      },
      set( value ){
        this.$emit( "input", value );
      }
    }
  },
  mounted(){
    this.$refs.input.addEventListener( "focus", e => this.state.focus = true );
    this.$refs.input.addEventListener( "blur", e => this.state.focus = false );
    if( !this.$store.state.config.isTouchDevice ){
      this.$refs.hover.addEventListener( "mouseover", e => this.state.hover = true );
      this.$refs.hover.addEventListener( "mouseout", e => this.state.hover = false );
    };
  }
}
</script>
