

const loading = {
  namespaced: true,
  state: {
    status: false,
    cover: false
  },
  getters: {
    GET_STATUS: (state) => {
      return state.status;
    },

    GET_COVER: (state) => {
      return state.cover;
    }
  },
  mutations: {
    SET_TRUE(state, payload) {
      state.cover = payload?.cover ?? false,
      state.status = true;
    },
    SET_FALSE(state, payload) {
      state.status = false;
    },
    TOGGLE_STATUS(state, payload) {
      state.cover = payload?.cover ?? false,
      state.status = !state.status;
    }
  }
}

export default loading
