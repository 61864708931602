"use strict";

const map = {
  namespaced: true,
  state: {
    map: null,
    ready: false,
    zoom: 13.5,
    center: {
      lat: 37.5729503,
      lng: 126.97935789999997,
    },
    markers: [],
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      var filteredItem = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItem = state.items[o];
          }
        });
      }
      return filteredItem;
    },
    GET_ITEMS: (state, getters) => (payload) => {
      var filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }
      return filteredItems;
    },
  },
  mutations: {
    SET_MAP(state, payload = {}) {
      if(payload.data?.map) {
        state.map = payload.data?.map;
      }
    },

    UNSET_MAP(state, payload = {}) {
      state.map = null;
    },

    SET_READY(state, payload = {}) {
      return (state.ready = payload.value ?? false);
    },

    CLEAR_READY(state, payload = {}) {
      return (state.ready = false);
    },

    SET_MARKER(state, payload = {}) {
      /**
       * @Params { data: { id: 'store_id', ...args } }
       */
      if (typeof payload.data == "object") {
        const targetIndex = state.markers.findIndex(o => payload.data?.id === o.id);

        if (targetIndex > -1) {
          state.markers.splice(targetIndex, 1, payload.data)
        } else {
          state.markers.unshift(payload.data);
        }
      }
    },

    SET_MARKERS(state, payload = {}) {
      /**
       * @Params { data: [{ id: 'store_id', ...args }, ...] } 
       */
      if (typeof payload.data == "object" && payload.data?.length) {
        payload.data.forEach((_marker) => {
          const targetIndex = state.markers.findIndex(o => o.id === _marker.id);

          if (targetIndex > -1) {
            state.markers.splice(targetIndex, 1, _marker);
          } else {
            state.markers.unshift(_marker)
          }
        })
      }
    },

    UNSET_MARKER(state, payload = {}) {
      if (typeof payload.data == "object") {
        const targetIndex = state.markers.findIndex(o => payload.data?.id === o.id);

        if (targetIndex > -1) {
          state.markers.splice(targetIndex, 1);
        }
      }
    },

    CLEAR_MARKERS(state, payload = {}) {
      state.markers = [];
    },

    SET_CENTER(state, payload = {}) {
      let { lat, lng } = payload.data || {};
      
      if (lat && lng) {
        state.center = { lat, lng };
      }
    },

    CLEAR_CENTER(state, payload = {}) {
      state.center = {
        lat: 37.5729503,
        lng: 126.97935789999997,
      };
    },

    SET_ZOOM(state, payload = {}) {
      state.zoom = payload.zoom;
    }
  },
  actions: {},
};

export default map;
