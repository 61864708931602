import Vue from "vue";

const rest = {
  namespaced: true,
  state: {
    lists: {},
    items: {},
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data == "object" && payload.key) {
        if (state.items[payload.key]) {
          Vue.set(state.items, payload.key, $.extend([], state.items[payload.key], payload.data));
        } else {
          Vue.set(state.items, payload.key, payload.data);
        }
      }
    },
    SET_ITEMS(state, payload) {
      if (typeof payload.data == "object" && payload.data.length) {
        payload.data.forEach((item) => {
          if (state.items[item.ticket_code]) {
            Vue.set(state.items, item.ticket_code, $.extend({}, state.items[item.ticket_code], item));
          } else {
            Vue.set(state.items, item.ticket_code, item);
          }
        });
      }
    },
    UNSET_ITEM(state, payload) {
      if (payload.data.ticket_code) {
        Object.keys(state.lists).forEach((o) => {
          state.lists[o] = state.lists[o].filter((o) => o != payload.data.ticket_code);
        });
        Vue.delete(state.items, payload.data.ticket_code);
      }
    },
    SET_LIST(state, payload) {
      if (payload.list) {
        Vue.set(state.lists, payload.list, []);
      }
    },
    SET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == "object" && payload.data.ticket_code) {
        if (state.lists[payload.list].indexOf(payload.data.ticket_code) < 0) {
          state.lists[payload.list].unshift(payload.data.ticket_code);
        }
      }
    },
    SET_LIST_ITEMS(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == "object" && payload.data.length) {
        payload.data.forEach((o) => {
          if (state.lists[payload.list].indexOf(o.ticket_code) < 0) {
            state.lists[payload.list].push(o.ticket_code);
          }
        });
      }
    },
    CLEAR_LIST(state, payload) {
      if (payload.list && state.lists[payload.list]) {
        state.lists[payload.list] = [];
      }
    },
    CLEAR_ITEMS(state) {
      state.items = {};
    },
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = [];
      if (payload && payload.key) {
        Object.keys(state.items).forEach((o) => {
          if (o == payload.key) {
            filteredItems = state.items[o];
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS_BY_LIST: (state, getters) => (payload) => {
      // payload : { list }
      var filteredItems = [];
      if (payload && payload.list && state.lists[payload.list]) {
        filteredItems = state.lists[payload.list].map((o) => state.items[o]);
      }
      return filteredItems;
    },
  },
  actions: {
		get({ state, rootState, commit, dispatch }, payload){
      return new Promise((resolve, reject) => {
        if (payload.clear) {
          commit("CLEAR_ITEMS");
        }

        Vue.axios.get(rootState.config.apiURL + `/v2/luggage/tickets/${payload.ticket_code}/actions`, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (!error) {
              commit("SET_ITEM", { key: payload.ticket_code, data });
              resolve(data)
            }

            reject(error)
          })
          .catch(reject)
      })
    },
    add({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(rootState.config.apiURL + `/v2/luggage/tickets/${payload.ticket_code}/actions`, payload, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (!error) {
              commit("SET_ITEM", { key: payload.ticket_code, data });
              resolve(data)
            }

            reject(error)
          })
          .catch(reject)
      })
    },
    clear({ commit }, payload) {
      commit("CLEAR_LIST", payload);
      commit("CLEAR_ITEMS");
    },
  },
};

export default rest;
