<template>
  <div ref="popup" class="ui-popup" @click.stop="">
		<slot />
	</div>
</template>

<script>
export default {
	mounted() {
		setTimeout(() => {
			window.addEventListener('click', this.closePopup)
		}, 0)
	},

	beforeDestroy() {
		window.removeEventListener('click', this.closePopup)
	},

	methods: {
		closePopup(e){
			this.$emit('close')
		}
	}
}
</script>
