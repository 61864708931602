import axios from 'axios';
import store from '@/store';
import crypto from 'crypto';
import _ from 'lodash';

// import { DeviceInfo } from "@/store/types/app.types";

const MD5 = function (str) {
  const hash = crypto.createHash('md5');
  hash.update(str);
  return hash.digest('hex');
};

const API_VERSION = '/v2';

// axios.defaults.baseURL = config.apiURL + API_VERSION;

axios.interceptors.request.use(
  function (config) {
    const { pathname = '', search = '' } = window.location;
    config.headers['page-location'] = pathname + search;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

class NetworkCachedRequest {
  get id() {
    return this._id;
  }

  get group() {
    return this._group;
  }

  constructor(id, method, uri, params = {}) {
    this._id = id;
    this._uri = uri;
    this._method = method.toUpperCase();
    this._params = params;
    this._url = new URL(uri);
    this._group = MD5(this._url.protocol + '://' + this._url.hostname + '/' + this._url.pathname);
    this._cancelSource = null;
  }

  _run(options = {}) {
    if (this._method === 'POST') {
      return axios.post(this._uri, this._params, {
        cancelToken: this._cancelSource.token,
        requestId: this._id,
        ...options,
      });
    } else if (this._method === 'PUT') {
      return axios.put(this._uri, this._params, {
        cancelToken: this._cancelSource.token,
        requestId: this._id,
        ...options,
      });
    } else if (this._method === 'GET') {
      return axios.get(this._uri, {
        params: this._params,
        cancelToken: this._cancelSource.token,
        requestId: this._id,
        ...options,
      });
    } else if (this._method === 'DELETE') {
      return axios.delete(this._uri, {
        params: this._params,
        cancelToken: this._cancelSource.token,
        requestId: this._id,
        ...options,
      });
    }
    return Promise.reject(new Error('UNKNOWN_METHOD'));
  }

  run(options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        this._cancelSource = source;

        const response = await this._run(options);

        this._cancelSource = null;

        resolve(response);
      } catch (e) {
        this._cancelSource = null;

        reject(e);
      }
    });
  }

  cancel({ message }) {
    if (this._cancelSource) {
      this._cancelSource.cancel(message);

      setTimeout(() => {
        this._cancelSource = null;
      }, 0);
    }
  }
}

class NetworkCachedRequestManager {
  constructor(network, url) {
    this._network = network;
    this._requestCahced = {};
    this._requestGroups = {};
    this._lastestRequestId = 0;
  }

  addQueue(request, clearGroup = false) {
    let requestGroup = this._requestCahced[request.group];

    if (!requestGroup) {
      requestGroup = {};
    }

    if (clearGroup === true) {
      for (let key in requestGroup) {
        let request = requestGroup[key];
        request.cancel({
          message: 'group cancelled',
        });
      }
    }

    requestGroup[request.id] = request;

    this._requestCahced[request.group] = requestGroup;
    this._requestCahced[request.id] = request;
  }

  createRequest(method, url, params, { clearGroup }) {
    this._lastestRequestId = this._lastestRequestId + 1;

    const request = new NetworkCachedRequest(`request.${this._lastestRequestId}`, method, url, params);

    this.addQueue(request, clearGroup);

    return request;
  }
}

class Network {
  constructor() {
    if (this._instance) return this._instance;

    this._instance = this;
    this._manager = new NetworkCachedRequestManager(this);
  }

  isCancel(e) {
    if (e.message.indexOf('cancelled') !== -1) {
      return true;
    }

    return false;
  }

  post(url, params = {}, options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        let request = this._manager.createRequest('POST', url, params, options);
        let response = await request.run();

        let { error, data } = response.data;
        if (error) {
          throw new Error(error);
        }
        setTimeout(() => {
          resolve(data);
        }, 0);
      } catch (e) {
        reject(e);
      }
    });
  }

  put(url, params = {}, options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        let request = this._manager.createRequest('PUT', url, params, options);
        let response = await request.run();

        let { error, data } = response.data;
        if (error) {
          throw new Error(error);
        }
        setTimeout(() => {
          resolve(data);
        }, 0);
      } catch (e) {
        reject(e);
      }
    });
  }

  get(url, params = {}, options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        let request = this._manager.createRequest('GET', url, params, options);
        let response = await request.run();

        let { error, data } = response.data;
        if (error) {
          throw new Error(error);
        }
        setTimeout(() => {
          resolve(data);
        }, 0);
      } catch (e) {
        reject(e);
      }
    });
  }

  delete(url, params = {}, options = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        let request = this._manager.createRequest('DELETE', url, params, options);
        let response = await request.run();

        let { error, data } = response.data;
        if (error) {
          throw new Error(error);
        }
        setTimeout(() => {
          resolve(data);
        }, 0);
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new Network();
