import Vue from "vue";

const rest = {
  namespaced: true,
  state: {
    lists: {},
    items: {
      invoice: {},
      ticket: {},
    },
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data == "object" && payload.data.type && payload.data.key) {
        Vue.set(state.items[payload.data.type], payload.data.key, payload.data.value);
      }
    },
    SET_ITEMS(state, payload) {},
    UNSET_ITEM(state, payload) {},
    SET_LIST(state, payload) {},
    SET_LIST_ITEM(state, payload) {},
    SET_LIST_ITEMS(state, payload) {},
    CLEAR_LIST(state, payload) {},
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = null;
      if (payload && payload.type && payload.key && payload.value) {
        Object.keys(state.items[payload.type]).forEach((o) => {
          if (state.items[payload.type][o][payload.key] == payload.value) {
            filteredItems = state.items[payload.type][o];
          }
        });
      }
      return filteredItems;
    },
    // GET_ITEMS: (state, getters) => (payload) => {
    // 	// payload : { key, value }
    // 	var filteredItems = [];
    // 	if (payload && payload.key && payload.value) {
    // 		Object.keys(state.items).forEach((o) => {
    // 			if (state.items[o][payload.key] == payload.value) {
    // 				filteredItems.push(state.items[o]);
    // 			}

    //      if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
    //        filteredItems.push(state.items[o]);
    //      }
    // 		});
    // 	}
    // 	return filteredItems;
    // },
    // GET_ITEMS_BY_LIST: (state, getters) => (payload) => {
    // 	// payload : { list }
    // 	var filteredItems = [];
    // 	if (payload && payload.list && state.lists[payload.list]) {
    // 		filteredItems = state.lists[payload.list].map((o) => state.items[o]);
    // 	}
    // 	return filteredItems;
    // },
  },
  actions: {
    new({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + "/v2/delivery/reserve/new?language=" + rootState.lang, payload, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;

            if (error) {
              reject(new Error(error));
              return;
            }
            resolve(data);
          })
          .catch(reject);
      });
    },
    complete({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + "/v2/delivery/reserve/" + payload.id + "/complete?language=" + rootState.lang, payload, {
            headers: rootState.config.userHeaders,
          })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            resolve(data);
          })
          .catch(reject);
      });
    },
    getCourier({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + "/v2/delivery/couriers?ticket_code=" + payload.code + "&language=" + rootState.lang, {
            headers: rootState.config.userHeaders,
          })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            resolve(data);
          })
          .catch(reject);
      });
    },
    getInvoice({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + "/v2/delivery/invoices?ticket_code=" + payload.code + "&language=" + rootState.lang, {
            headers: rootState.config.userHeaders,
          })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit("SET_ITEM", { data: { type: "invoice", key: payload.code, value: data } });
            resolve(data);
          })
          .catch(reject);
      });
    },
  },
};

export default rest;
