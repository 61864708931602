import Vue from "vue";
import store from "@/store";
import json from "@/assets/json/language.json";
import config from "@/config";
import utils from "@/utils";
import { WNInterface } from "vue-wni";
import mustache from "mustache";

function _translate(string) {
  if (store.state.lang === "system") {
    json[string] ? (json[string][store.state.lang] ? json[string][store.state.lang] : string) : string;
  }

  if (store.state.lang == "ko") {
    return json[string] ? (json[string][store.state.lang] ? json[string][store.state.lang] : string) : string;
  }

  return json[string]
    ? json[string][store.state.lang]
      ? json[string][store.state.lang]
      : json[string]["en"]
      ? json[string]["en"]
      : string
    : string;
}

export function translate(string, options) {
  const template = _translate(string);
  if (options) {
    
    return mustache.render(template.replace(/\{/gi, '{{').replace(/\}/gi, '}}'), options);
  }
  return template;
}

Vue.prototype.$__t = translate;

export class Language {
  static getInitialLanguage() {
    let currentLanguage = localStorage.getItem("lugstay_language");

    const urlParams = new URLSearchParams(window.location.search);

    let urlLanguage = urlParams.get("language");

    if (["ko", "en", "cn", "hk", "jp", "vn"].indexOf(urlLanguage) < 0) {
      urlLanguage = null;
    } else {
      currentLanguage = urlLanguage;
      localStorage.setItem("lugstay_language", urlLanguage);
    }

    if (!currentLanguage) {
      currentLanguage = config.userLocale.indexOf("ko") !== -1 ? "ko" : "en";
    }

    return currentLanguage;
  }

  static parseLanguage(localeString) {
    const LOCALES = {
      ko: "ko",
      en: "en",
      cn: "zh-CN",
      hk: "zh-HK",
      jp: "ja",
      vn: "vi",
    };
    const targetIndex = Object.values(LOCALES).findIndex((locale) => localeString.indexOf(locale.substr(0, 2)) > -1);

    if (targetIndex < 0) {
      return localeString;
    }

    return Object.keys(LOCALES)[targetIndex];
  }

  static googleMapLocale(language) {
    const LOCALES = {
      ko: "ko",
      en: "en",
      cn: "zh-CN",
      hk: "zh-HK",
      jp: "ja",
      vn: "vi",
    };

    return LOCALES[language] || "ko";
  }

  static getSystemLanguage() {
    return new Promise((resolve, reject) => {
      if (WNInterface.isNative) {
        WNInterface.execute("wnAppInfo", {
          callback: WNInterface.cb((e) => {
            let { deviceLocale } = e;
            resolve(this.parseLanguage(deviceLocale));
          }),
        });
      } else {
        let browserLanguage = navigator.language || navigator.userLanguage || "ko";
        resolve(this.parseLanguage(browserLanguage));
      }
    });
  }

  static async setLanguage(lang) {
    let language = this.parseLanguage(lang);

    // if (lang === 'system') {
    //   language = await this.getSystemLanguage();
    //   store.commit('SET_LANGUAGE', { label: 'system', value: language });
    // } else {
    // }
    // 시스템 언어 설정 임시 삭제

    localStorage.setItem("lugstay_language", language);

    if (["jp", "hk", "cn"].includes(language)) {
      document.body.classList.add("wb-ba");
    }
  }
}

const main = async () => {
  let currentLanguage = Language.getInitialLanguage();

  if (currentLanguage === "system") {
    currentLanguage = await Language.getSystemLanguage();
  }

  Language.setLanguage(currentLanguage);
};

main();
