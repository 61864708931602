<template>
  <transition name="slide-down">
    <div class="lug-modal" v-if="opened" ref="background">
      <!--alert-modal-->
      <div v-if="id === 'alert-modal'" class="lug-modal-container">
        <!-- <div class="header" v-if="headerLabel">{{ headerLabel }}</div> -->
        <div class="body_umf"><slot name="body" /></div>
        <div class="footer" v-if="!hideFooter">
          <div v-if="weighted" class="submit-weighted-button-area">
            <button type="button" class="false" @click="close">
              {{ cancelButtonLabel }}
            </button>
            <button type="button" class="true" @click="$emit('submit')">
              {{ submitButtonLabel }}
            </button>
          </div>
          <div class="button-area" v-if="cancelButtonLabel && !weighted">
            <ui-border-button color="primary" size="small" v-if="cancelButtonLabel" @click="close">
              <span>{{ cancelButtonLabel }}</span>
            </ui-border-button>
          </div>
          <div class="button-area" v-if="submitButtonLabel && !weighted">
            <ui-solid-button color="primary" size="small" v-if="submitButtonLabel" @click="$emit('submit')">
              <span>{{ submitButtonLabel }}</span>
            </ui-solid-button>
          </div>
        </div>
      </div>

      <!--storage-img-modal(보관소 위치 모달)-->
      <div v-else-if="id === 'storage-img-modal'" class="lug-modal-container">
        <div class="close-modal-button"><img src="/img/icon/header-close.svg" @click="$emit('close-modal')" /></div>
        <div class="header" v-if="headerLabel">{{ headerLabel }}</div>
        <div class="body_storage-img"><slot name="body" /></div>
        <div class="footer" v-if="!hideFooter">
          <div v-if="weighted" class="submit-weighted-button-area">
            <button type="button" class="false" @click="close">
              {{ cancelButtonLabel }}
            </button>
            <button type="button" class="true" @click="$emit('submit')">
              {{ submitButtonLabel }}
            </button>
          </div>
          <div class="button-area" v-if="cancelButtonLabel && !weighted">
            <ui-border-button color="primary" size="small" v-if="cancelButtonLabel" @click="close">
              <span>{{ cancelButtonLabel }}</span>
            </ui-border-button>
          </div>
          <div class="button-area" v-if="submitButtonLabel && !weighted">
            <ui-solid-button color="primary" size="small" v-if="submitButtonLabel" @click="$emit('submit')">
              <span>{{ submitButtonLabel }}</span>
            </ui-solid-button>
          </div>
        </div>
      </div>
      <!--noregister-kakaopay-modal(카카오페이 재등록안됨)-->
      <div v-else-if="id === 'noregister-kakaopay-modal'" class="lug-modal-container">
        <div class="header" v-if="headerLabel">{{ headerLabel }}</div>
        <div class="body_noregister"><slot name="body" /></div>
        <div class="footer" v-if="!hideFooter">
          <div v-if="weighted" class="submit-weighted-button-area">
            <button type="button" class="false" @click="close">
              {{ cancelButtonLabel }}
            </button>
            <button type="button" class="true" @click="$emit('submit')">
              {{ submitButtonLabel }}
            </button>
          </div>
          <div class="button-area" v-if="cancelButtonLabel && !weighted">
            <ui-border-button color="primary" size="small" v-if="cancelButtonLabel" @click="close">
              <span>{{ cancelButtonLabel }}</span>
            </ui-border-button>
          </div>
          <div class="button-area" v-if="submitButtonLabel && !weighted">
            <ui-solid-button color="primary" size="small" v-if="submitButtonLabel" @click="$emit('submit')">
              <span>{{ submitButtonLabel }}</span>
            </ui-solid-button>
          </div>
        </div>
      </div>
      <div v-else class="lug-modal-container">
        <div class="header" v-if="headerLabel">{{ headerLabel }}</div>
        <div class="body"><slot name="body" /></div>
        <div class="footer" v-if="!hideFooter">
          <div v-if="weighted" class="submit-weighted-button-area">
            <button type="button" class="false" @click="close">
              {{ cancelButtonLabel }}
            </button>
            <button type="button" class="true" @click="$emit('submit')">
              {{ submitButtonLabel }}
            </button>
          </div>
          <div class="button-area" v-if="cancelButtonLabel && !weighted">
            <ui-border-button color="primary" size="small" v-if="cancelButtonLabel" @click="close">
              <span>{{ cancelButtonLabel }}</span>
            </ui-border-button>
          </div>
          <div class="button-area" v-if="submitButtonLabel && !weighted">
            <ui-solid-button color="primary" size="small" v-if="submitButtonLabel" @click="$emit('submit')">
              <span>{{ submitButtonLabel }}</span>
            </ui-solid-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    submitButtonLabel: {
      type: String,
      default: null
    },
    cancelButtonLabel: {
      type: String,
      default: null
    },
    headerLabel: {
      type: String,
      default: null
    },
    weighted: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Object,
      default: () => {}
    },
    id: {
      type: String,
      default: null
    },
    hideFooter: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  data() {
    return {
      opened: false
    };
  },
  watch: {
    opened(opened) {
      if (opened && this.$props.id === 'login-modal') {
        this.$nextTick(() => {
          if (this.$refs.background) {
            return;
          }
        });
      } else if (opened) {
        this.$nextTick(() => {
          if (this.$refs.background) {
            this.$refs.background.addEventListener('mousedown', (e) => {
              if (this.$store.state.config.isTouchDevice || e.which == 1) {
                if (e.target == this.$el) {
                  this.close();
                }
              }
            });
          }
          $('html, body').css('overflow', 'hidden');
        });
      } else {
        this.$nextTick(() => {
          $('html, body').removeAttr('style');
        });
      }
    }
    //@원래 있었던 코드. 배경 클릭시 무조건 모달 사라지도록
    // opened(opened) {
    //   if (opened) {
    //     this.$nextTick(() => {
    //       if (this.$refs.background) {
    //         this.$refs.background.addEventListener('mousedown', (e) => {
    //           if (this.$store.state.config.isTouchDevice || e.which == 1) {
    //             if (e.target == this.$el) {
    //               this.close();
    //             }
    //           }
    //         });
    //       }
    //       $('html, body').css('overflow', 'hidden');
    //     });
    //   } else {
    //     this.$nextTick(() => {
    //       $('html, body').removeAttr('style');
    //     });
    //   }
    // }
  },

  destroyed() {
    // @TODO: 나중에 꼭 고쳐야할 로직
    $('html, body').removeAttr('style');
  },

  beforeDestroy() {
    this.$store.commit('modal/REMOVE_ITEM', { data: { id: this.$attrs.id } });
  },

  computed: {
    topLayerPopup() {
      return this.$store.getters['modal/GET_LAST_ITEM']();
    }
  },

  methods: {
    open(ref = '') {
      this.opened = true;
      this.$store.commit('modal/ADD_ITEM', {
        data: { id: this.$attrs.id, context: this }
      });

      this.$emit('modalOpened');
    },

    close() {
      const poppedPopup = this.topLayerPopup;

      if (poppedPopup && poppedPopup.id === this.$attrs.id) {
        this.opened = false;
        this.$store.commit('modal/REMOVE_ITEM', { data: { id: this.$attrs.id } });
        this.$emit('modalClosed');
      }
    }
  }
};
</script>
