const plugin = {
  install(app, { loadComponent = true } = {}) {
    const isMobile = require("is-mobile");

    app.prototype.$browser = {
      isMobile: isMobile(),
    };
  },
};

export default plugin;
