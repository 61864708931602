<template>
  <div
    class="ui-textbox"
    :class="{
      hover: state.hover,
      focus: state.focus,
      fill: state.fill,
      error: error,
      disabled: disabled,
      readonly: readonly,
    }"
  >
    <label ref="hover">
      <div class="label">{{ label }}</div>
      <div class="body">
        <input
          ref="input"
          :type="type || 'text'"
          :placeholder="state.focus ? placeholder : ''"
          v-model="model"
          :readonly="readonly"
          :disabled="disabled"
          @focus="onFocus"
          @blur="onBlur"
          autocapitalize="off"
          autocomplete="off"
          :maxlength="maxLength"
          :inputmode="inputmode"
        />
      </div>
    </label>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: ['value', 'type', 'placeholder', 'label', 'error', 'disabled', 'maxLength', 'readonly', 'inputmode', 'filter'],
  data() {
    var state = { hover: false, focus: false, fill: true, error: false };
    return { state };
  },
  computed: {
    model: {
      get() {
        if (typeof this.value == 'number' || this.value) {
          this.state.fill = true;
        } else {
          this.state.fill = false;
        }
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onFocus(e) {
      if (this.readonly == true) return;
      this.state.focus = true;
      this.$emit('focus', e);
    },
    onBlur(e) {
      if (this.readonly == true) return;
      this.state.focus = false;
      this.$emit('blur', e);
    },
  },
  mounted() {
    if (!this.$store.state.config.isTouchDevice) {
      this.$refs.hover.addEventListener('mouseover', (e) => (this.state.hover = true));
      this.$refs.hover.addEventListener('mouseout', (e) => (this.state.hover = false));
    }
  },
};
</script>
