import crypto from 'crypto';

export const cardLastFourDigits = (card) => {
  if (card?.card_number) {
    return card.card_number.substring(card.card_number.length - 4);
  }

  if (typeof card === 'string') {
    return card.substring(card.length - 4);
  }

  return '';
};

export const isValidHttpURL = (value) => {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const openRef = function (ref) {
  this.$refs[ref].open();
};

export const closeRef = function (ref) {
  this.$refs[ref].close();
};

export const MD5 = function (str) {
  const hash = crypto.createHash('md5');
  hash.update(str);
  return hash.digest('hex');
};

export const setDomain = (domain) => {
  try {
    document.domain = domain;
  } catch (e) {
    document.domain = location.hostname;
  }
};

export const setCookie = (name, value, options) => {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUtcString();
  }
  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};
