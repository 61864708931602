<template>
  <div class="ui-quantity-select">
    <div class="label" v-if="label">{{ $__t( label ) }} ({{ $__t( "최대" )+" " + this.max + " " + $__t( "개" ) }})</div>
    <div class="body">
      <input type="text" v-model="model" />
      <div class="quantity-controll quantity-up" @click="quantityUp"><i class="fa fa-sort-up"></i></div>
      <div class="quantity-controll quantity-down" @click="quantityDown"><i class="fa fa-sort-down"></i></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    placeholder: {},
    label: {},
    error: {},
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 99
    }
  },
  computed: {
    model: {
      get(){
        if( isNaN( this.value ) ){
          this.$emit( "input", this.min );
        }else{
          if( this.max && this.value > this.max ){
            this.$emit( "input", this.max );
          }else if( this.value < this.min ){
            this.$emit( "input", this.min );
          }else{
            return this.value;
          };
        }
      },
      set( value ){
        this.$emit( "input", value );
      }
    }
  },
  methods: {
    quantityUp(){
      if( this.max > this.value ){
        this.$emit( "input", this.value + 1 );
      };
    },
    quantityDown(){
      if( this.min < this.value ){
        this.$emit( "input", this.value - 1 );
      };
    },
  }
}
</script>
