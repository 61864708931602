const iframe = {
  namespaced: true,
  state: {
    items: {},
  },
  getters: {
    GET_ITEM(state, key) {
      return state.items[key];
    },
    GET_ITEMS(state) {
      return Object.keys(state.items).map((key) => state.items[key]);
    },
  },
  mutations: {
    ADD_ITEM(state, iframe) {
      state.items[iframe.id] = iframe;
    },
    REMOVE_ITEM(state, iframe) {
      const { id } = iframe;
      Vue.delete(state.items, id);
    },
  },
};

export default iframe;
