<template>
  <div class="ui-day-picker">
    <calendar calendarType="date" :dayClassesFunction="dayClassesFunction" @selectDay="pickDay" />
  </div>
</template>
<script>
import Calendar from "@/components/modules/RichForm/Calendar";
export default {
  props: [ "value", "min" ],
  components: {
    Calendar
  },
  data(){
    return {
      switch: false
    };
  },
  methods: {
    dayClassesFunction( day ){
      var classes = [];
      if( this.min ){
        if( this.$moment( this.min ).startOf( "day" ).isAfter( day ) ){
          classes.push( "day-disabled" );
        };
      };
      if( this.value && this.value.length == 2 ){
        var before = this.$moment( this.value[0] ).startOf( "day" );
        var after = this.$moment( this.value[1] ).startOf( "day" );
        if( before.isSame( after ) ){
          if( day.isSame( after ) ){
            classes.push( "day-selected" );
          };
        }else{
          if( day.isSame( before ) ){
            classes.push( "day-first" );
            classes.push( "day-selected" );
          }else if( day.isSame( after ) ){
            classes.push( "day-last" );
            classes.push( "day-selected" );
          }else if( day.isAfter( before ) && day.isBefore( after ) ){
            classes.push( "day-between" );
          };
        };
      };
      if( this.$moment().format( "YYYY-MM-DD" ) == day.format( "YYYY-MM-DD" ) ){
        classes.push( "day-today" );
      };
      return classes;
    },
    pickDay( day ){
      if( this.value[0].isAfter( day ) ){
        this.switch = true;
        this.$emit( "input", [ day, this.value[1] ] );
      }else if( this.value[1].isBefore( day ) ){
        this.switch = false;
        this.$emit( "input", [ this.value[0], day ] );
      }else if( this.value[0].isSame( day ) ){
        this.$emit( "input", [ this.value[0], this.value[0] ] );
      }else if( this.value[1].isSame( day ) ){
        this.$emit( "input", [ this.value[1], this.value[1] ] );
      };
      if( day.isBetween( this.value[0], this.value[1] ) ){
        if( this.switch == false ){
          this.switch = true;
          this.$emit( "input", [ day, this.value[1] ] );
        }else{
          this.switch = false;
          this.$emit( "input", [ this.value[0], day ] );
        };
      };
    },
  },
  created(){
    this.$watch( "min", min => {
      if( this.min && this.value && this.value.length == 2 ){
        if( this.min.isAfter( this.value[0] ) ){
          this.switch = false;
          this.$emit( "input", [ this.min, this.value[1] ]);
        };
      };
    }, { immediate: true });
  }
}
</script>
