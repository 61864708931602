<template>
  <div class="ui-search-select" :class="{ hover: state.hover, fill: state.fill, error: error, disabled: disabled }">
    <!-- <div class="label">{{ label }}</div>
    <div class="body">
      {{ displayValue ? value : displayLabel }}
      <i class="fa fa-angle-down"></i>
    </div> -->
    <model-select
      v-model="model"
      :placeholder="placeholder"
      :disabled="disabled"
      :options="items" >
    </model-select>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select'

export default {
  components: { ModelSelect },
  props: [ "value", "items", "placeholder", "label", "error", "disabled", "displayValue" ],
  data(){
    var state = { hover: false, fill: true, error: false };
    return { state };
  },
  computed: {
    model: {
      get(){
        if( typeof this.value == "number" || this.value ){
          this.state.fill = true;
        }else{
          this.state.fill = false;
        };
        return this.value;
      },
      set( value ){
        this.$emit( "input", value );
      }
    },
    displayLabel(){
      if( typeof this.value == "number" || this.value ){
        if( this.items.filter( o => o.value == this.value )[0] && this.items.filter( o => o.value == this.value )[0].label ){
          return this.items.filter( o => o.value == this.value )[0].label;
        }else{
          this.$emit( "input", "" );
          return "";
        };
      }else{
        return "";
      };
    }
  },
  mounted(){
    this.$log.log( 'items', this.items )
    // if( !this.$store.state.config.isTouchDevice ){
    //   this.$refs.input.addEventListener( "mouseover", e => this.state.hover = true );
    //   this.$refs.input.addEventListener( "mouseout", e => this.state.hover = false );
    // };
  }
}
</script>
