import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    items: {}
  },
  mutations: {
    SET_ITEM(state, payload = {}) {
      if (typeof payload.data == 'object' && payload.data?.form_id) {
        if (state.items[payload.data.form_id]) {
          Vue.set(state.items, payload.data.form_id, $.extend({}, state.items[payload.data.form_id], payload.data));
        } else {
          Vue.set(state.items, payload.data.form_id, payload.data);
        }
      }

      Vue.set(state.items, payload.data.form_id, $.extend({}, state.items[payload.data.form_id], { active: true }));
    },

    UNSET_ITEM(state, payload = {}) {
      if (payload.data?.form_id) {
        Vue.delete(state.items, payload.data.form_id);
      }
    },

    UPDATE_ITEM(state, payload) {
      if (typeof payload.data == 'object' && payload.data.form_id) {
        if (state.items[payload.data.form_id]) {
          Object.keys(payload.data).forEach((o) => {
            state.items[payload.data.form_id][o] = payload.data[o];
          });
        }
      }
    },

    CLEAR_ITEMS(state) {
      state.items = {};
    }
  },
  getters: {
    GET_ITEM: (state) => (payload) => {
      let filteredItem = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItem = state.items[o];
          }
        });
      }
      return filteredItem;
    },

    GET_ITEMS: (state) => (payload) => {
      let filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }
      return filteredItems;
    }
  }
};
