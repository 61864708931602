<template>
  <div class="ui-day-picker">
    <calendar
      ref="calendar"
      :calendarType="calendarType"
      :dayClassesFunction="dayClassesFunction"
      :timeClassesFunction="timeClassesFunction"
      @selectDay="pickDay"
      @selectTime="pickTime"
      @shiftNextMonth="shiftNextMonth"
      @shiftPreviousMonth="shiftPreviousMonth"
    />
    <div class="loading" v-if="loading">
      <i class="fa fa-spinner fa-pulse"></i>
    </div>
  </div>
</template>
<script>
import Calendar from '@/components/modules/RichForm/Calendar';
export default {
  props: ['calendarType', 'value', 'min', 'max', 'disableDays', 'disableTimes', 'availableTime', 'loading', 'service_id'],
  components: {
    Calendar
  },
  methods: {
    dayClassesFunction(day) {
      var classes = [];
      if (this.min) {
        if (this.$moment(this.min).startOf('day').isAfter(day)) {
          classes.push('day-disabled');
        }
      }
      if (this.max) {
        if (this.$moment(this.max).startOf('day').isBefore(day)) {
          classes.push('day-disabled');
        }
      }
      if (this.disableDays) {
        if (this.disableDays(day)) classes.push('day-disabled');
      }
      if (this.$moment().format('YYYY-MM-DD') == day.format('YYYY-MM-DD')) {
        classes.push('day-today');
      }
      if (this.value) {
        if (this.$moment(this.value).format('YYYY-MM-DD') == day.format('YYYY-MM-DD')) {
          classes.push('day-selected');
        }
      }
      return classes;
    },
    setCurrentCalendar(value) {
      this.$refs.calendar.setCurrentCalendar(value);
    },
    timeClassesFunction(time) {
      var classes = [];
      if (this.min && this.value) {
        if (this.$moment(this.min).isAfter(this.$moment(this.value).hour(time))) {
          classes.push('time-disabled');
        }
      }
      if (this.max && this.value) {
        if (this.$moment(this.max).isBefore(this.$moment(this.value).hour(time))) {
          classes.push('time-disabled');
        }
      }
      if (this.disableTimes) {
        if (this.disableTimes(time)) classes.push('time-disabled');
      }
      if (this.value) {
        if (this.$moment(this.value).format('H') == time) {
          classes.push('time-selected');
        }
      }
      return classes;
    },
    pickDay(day) {
      if (!this.value || !this.value.isSame(day)) {
        this.$emit('input', day);
        this.$emit('select', day);
      }
    },
    pickTime(time) {
      let value = this.$moment(this.value).hour(time);
      if (value.isValid() && !this.value.isSame(value)) {
        this.$emit('input', value);
        this.$emit('close');
      }
    },
    shiftNextMonth(day) {
      this.$emit('shiftNextMonth', day);
    },
    shiftPreviousMonth(day) {
      this.$emit('shiftPreviousMonth', day);
    }
  },
  created() {
    this.$watch(
      'min',
      (min) => {
        if (this.min && this.value) {
          if (this.min.isAfter(this.value)) {
            this.$emit('input', this.min);
          }
        }
      },
      { immediate: true }
    );
    this.$watch(
      'max',
      (max) => {
        if (this.max && this.value) {
          if (this.max.isBefore(this.value)) {
            this.$emit('input', this.max);
          }
        }
      },
      { immediate: true }
    );
    this.$watch('value', (value, oldVal) => {
      if (oldVal && !oldVal.isSame(value)) {
        if (this.max && this.max.isBefore(value)) {
          this.$emit('input', this.max);
        } else if (this.min && this.min.isAfter(value)) {
          this.$emit('input', this.min);
        } else if (this.disableDays && this.disableDays(value)) {
          if (oldVal && !this.disableDays(oldVal)) {
            this.$emit('input', oldVal);
          }
        }

        if (this.calendarType === 'datetime' || this.calendarType === 'time') {
          if (this.disableTimes && this.disableTimes(value.hour())) {
            this.$emit('input', this.availableTime(value));
          }
        }
      }
    });
  }
};
</script>
