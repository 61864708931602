<template>
  <div
    class="ui-toggle-checkbox"
    :class="{ hover: state.hover, checked: state.checked, error: error }"
    :style="{ color: color }"
  >
    <div class="body">
      <label ref="input">
        <span class="label">{{ label }}</span>
        <input type="checkbox" v-model="model" />
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    items: Array,
    label: String,
    error: String,
    color: String,
  },
  data() {
    var state = { hover: false, checked: false };

    return {
      state,
    };
  },
  computed: {
    model: {
      get() {
        if (this.value == 'false' || !this.value) {
          this.state.checked = false;
          if (this.value == 'false') {
            this.$emit('input', false);
          }
        } else {
          this.state.checked = true;
        }

        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    if (!this.$store.state.config.isTouchDevice) {
      this.$refs.input.addEventListener('mouseover', (e) => (this.state.hover = true));
      this.$refs.input.addEventListener('mouseout', (e) => (this.state.hover = false));
    }
  },
};
</script>

<style lang="scss" scoped>
.ui-toggle-checkbox {
  height: unit(24);

  label {
    display: inline-block;
    vertical-align: middle;
  }

  input[type='checkbox'] {
    @include fsa;

    &:checked {
      & ~ .slider {
        background: $color-primary-7;

        &::before {
          -webkit-transform: translateX(unit(24));
          -ms-transform: translateX(unit(24));
          transform: translateX(unit(24));
        }
      }
    }

    &:focus {
      & ~ .slider {
        box-shadow: 0 0 1px $color-primary-7;
      }
    }
  }

  .label {
    font-size: unit(14);
    line-height: 1.25;
    vertical-align: middle;
  }

  .slider {
    display: inline-block;
    vertical-align: middle;
    margin: 0 unit(8);
    width: unit(48);
    height: unit(24);
    cursor: pointer;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: unit(17);
      width: unit(17);
      left: unit(4);
      bottom: unit(4);
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    &.round {
      border-radius: unit(36);

      &::before {
        border-radius: 50%;
      }
    }
  }
}
</style>
