import Vue from "vue"

const covered = {
  namespaced: true,
  state: {
    covered : false
  },
  mutations: {
    SET_TRUE( state ) {
      state.covered = true;
    },
    SET_FALSE( state ) {
      state.covered = false;
    },
  },
  getters: {
    GET_ITEM: ( state, getters ) => payload => {
      return state.covered
    }
  },
  actions: {}
};

export default covered;
