<template>
  <li class="ui-form-list-item">
    <slot />
  </li>
</template>

<script>
export default {
}
</script>
