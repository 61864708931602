import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
// import { WNInterface } from 'vue-wni';
import store from '@/store';

// import Layout from '@/containers/Layout';

// import webRoutes from './routes/web.js';
// import nativeRoutes from './routes/native.js';
// import customRoutes from './routes/custom.js';
// import renewalRoutes from './routes';

import navigationGuard from './navigation.guard';

Vue.use(VueRouter);
Vue.use(VueMeta);

const isTouchDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WNEmulator|WNInterface/i.test(navigator.userAgent);

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: isTouchDevice ? () => import('@/containers/Blank') : () => import('@/containers/Layout'),
      // children: [...(WNInterface.isNative ? nativeRoutes : webRoutes), ...customRoutes, ...renewalRoutes]
      children: [
        {
          name: 'Home',
          path: '/',
          component: isTouchDevice ? () => import('@/components/pages/landings/PageMobileLanding') : () => import('@/components/pages/landings/PageLanding'),
          default: true,
          meta: { hideHeader: !isTouchDevice, hideSidebar: !isTouchDevice, allowPc: true, reserve_form: true }
        },
        {
          name: 'HostLanding',
          path: '/host/land',
          component: () => import('@/components/pages/host/PageHostLanding'),
          meta: { hideHeader: true, hideFooter: true, hideSidebar: true, allowPc: true }
        },
        {
          name: 'Redirect',
          path: '/r/:code',
          component: () => import('@/components/pages/redirect/PageRedirect'),
          props: true,
          meta: { hideHeader: !isTouchDevice, hideSidebar: !isTouchDevice, hideFooter: true, allowPc: true }
        },
        {
          name: 'CsPolicy',
          path: '/cs/policy',
          component: () => import('@/components/pages/cs/PageCsPolicy'),
          meta: { headerType: 'more-depth', title: '약관 및 정책', hideChannelTalk: true, allowPc: true }
        },
        {
          name: 'CsPrivacyPolicy',
          path: '/cs/privacy-policy',
          component: () => import('@/components/pages/cs/PageCsPrivacyPolicy'),
          meta: { title: '개인정보 처리방침', hideChannelTalk: true, hideSidebar: !isTouchDevice, allowPc: true }
        },
        {
          name: 'CsPrivacyPolicyTheThird',
          path: '/cs/privacy-policy-the-third',
          component: () => import('@/components/pages/cs/PageCsPrivacyPolicyTheThird'),
          meta: { title: '개인정보 제3자 제공', hideChannelTalk: true },
        },
        {
          name: 'CsTermsOfUse',
          path: '/cs/terms-of-use',
          component: () => import('@/components/pages/cs/PageCsTermsOfUse'),
          meta: { hideSidebar: !isTouchDevice, allowPc: true }
        },
      ]
    },
    { path: '*', redirect: '/' }
  ],
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  if (!(from && from.name)) {
    await store.dispatch('auth/refresh');
  }

  if (to.hash) {
    if (from && from.name === to.name && from.fullPath === to.fullPath) {
      return next(false);
    }
  }

  navigationGuard.changeStatusBar(to, from);
  navigationGuard.checkPermission(to, from);
  return navigationGuard.permissionHandler(next);
});

export default router;
