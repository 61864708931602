<template>
  <transition name="slide-up">
    <div
      class="lug-guide-modal"
      :class="{ external: externalClose }"
      v-if="opened"
      ref="background"
      :style="{ backgroundColor: transparent ? 'transparent' : null }"
    >
      <div class="lug-guide-modal-container" ref="viewport">
        <img
          v-if="!noCloseButton"
          @click.stop="close"
          src="/img/modal-close.png"
          srcset="/img/modal-close@2x.png 2x, /img/modal-close@3x.png 3x"
        />
        <div class="header" v-if="headerTitle">
          <span class="title">{{ headerTitle }}</span>
        </div>
        <div class="body"><slot name="body" /></div>
        <!-- <div v-if="arrow" class="ui-carousel-arrow prev" ref="arrowPrev" @click="slidePrev">
          <img src="/img/arrow-right@3x.png" v-if="currentIndex == 0" />
          <img src="/img/arrow-right-darker.png" v-else />
        </div>
        <div v-if="arrow" class="ui-carousel-arrow next" ref="arrowNext" @click="slideNext">
          <img src="/img/arrow-right@3x.png" v-if="currentIndex == maxIndex" />
          <img src="/img/arrow-right-darker.png" v-else />
        </div> -->
        <div class="button-area" v-if="noAppear">
          <button type="button" @click.stop="close(true)">
            <span class="text">{{ $__t("{pop-up}.no-appear-7") }}</span>
          </button>
          <button type="button" @click.stop="close">
            <span class="text">{{ $__t("닫기") }}</span>
          </button>
        </div>
      </div>
      <div v-if="externalClose" class="external-button-area">
        <button type="button" @click="close">
          <img
            src="/img/modal-close.png"
            srcset="/img/modal-close@2x.png 2x, /img/modal-close@3x.png 3x"
          />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";

export default {
  props: [
    "headerTitle",
    "arrow",
    "noAppear",
    "name",
    "noCloseButton",
    "noBgClick",
    "externalClose",
    "transparent",
  ],
  data() {
    var dragEventNames = {
      start: ["mousedown", "touchstart"],
      move: ["mousemove", "touchmove"],
      end: ["mouseup", "touchend"],
    };
    var dragStartX = 0;
    var dragMoveX = 0;

    return {
      opened: false,
      hidePopup: false,
      dragEventNames,
      dragStartX,
      dragMoveX,
    };
  },

  beforeDestroy() {
    this.$store.commit("popup/REMOVE_ITEM", { data: { id: this.$attrs.id } });
  },

  watch: {
    opened(opened) {
      if (opened) {
        this.$nextTick(() => {
          if (this.$refs.background && this.noBgClick !== true) {
            this.$refs.background.addEventListener("mousedown", (e) => {
              if (this.$store.state.config.isTouchDevice || e.which == 1) {
                if (e.target == this.$el) {
                  this.close();
                }
              }
            });
          }
        });
      }
    },
    maxIndex: {
      handler(value) {
        if (value > 0 && this.$refs.viewport) {
          this.bindEvent();
        }
      },
    },
    hidePopup(value) {
      if (value) {
        this.close();
      }
    },
  },

  computed: {
    currentIndex() {
      return (
        this.$store.getters["carousel/GET_ITEM"]({ key: "currentPage" }) - 1 ||
        Number(0)
      );
    },
    maxIndex() {
      return (
        this.$store.getters["carousel/GET_ITEM"]({ key: "maxPage" }) - 1 ||
        Number(0)
      );
    },
    topLayerPopup() {
      return this.$store.getters["popup/GET_LAST_ITEM"]();
    },
  },
  methods: {
    async close(setCookie = false) {
      const poppedPopup = this.topLayerPopup;

      if (poppedPopup && poppedPopup.id === this.$attrs.id) {
        this.opened = false;
        await this.$store.dispatch("popup/pop");
        this.$emit("modalClosed");

        if (setCookie === true) {
          this.setCookie(this.name);
        }
      }
    },
    clickClose: _.debounce(function (setCookie = false) {
      if (setCookie === true) {
        this.setCookie(this.name);
      }

      window.history.go(-1);
    }, 10),
    open(ref = "") {
      this.opened = true;
      this.$store.commit("popup/PUSH_ITEM", {
        data: { id: this.$attrs.id, context: this },
      });
      this.$emit("modalOpened");
    },
    slideNext() {
      this.$store.commit("carousel/INCREMENT");
    },
    slidePrev() {
      this.$store.commit("carousel/DECREMENT");
    },
    setCookie(name) {
      this.$store.dispatch("userData/set", {
        name: name,
        value: true,
        expires: 60 * 60 * 24 * 7,
      });
    },
    bindEvent() {
      this.$refs.viewport.addEventListener(
        this.dragEventNames.start[
          this.$store.state.config.isTouchDevice ? 1 : 0
        ],
        (e) => {
          this.bindDragStart(e);
        }
      );
    },
    bindDragStart(e) {
      this.dragStartX = this.$store.state.config.isTouchDevice
        ? e.touches[0].clientX
        : e.x;
      window.addEventListener(
        this.dragEventNames.move[
          this.$store.state.config.isTouchDevice ? 1 : 0
        ],
        this.bindDragMove
      );
      window.addEventListener(
        this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragEnd
      );
    },
    bindDragMove(e) {
      this.dragMoveX = this.$store.state.config.isTouchDevice
        ? this.dragStartX - e.touches[0].clientX
        : this.dragStartX - e.x;
    },
    bindDragEnd(e) {
      if (this.dragMoveX > this.$refs.viewport.offsetWidth / 4) {
        this.slideNext();
      } else if (this.dragMoveX < (-1 * this.$refs.viewport.offsetWidth) / 4) {
        this.slidePrev();
      }
      this.dragStartX = 0;
      this.dragMoveX = 0;
      window.removeEventListener(
        this.dragEventNames.move[
          this.$store.state.config.isTouchDevice ? 1 : 0
        ],
        this.bindDragMove
      );
      window.removeEventListener(
        this.dragEventNames.end[this.$store.state.config.isTouchDevice ? 1 : 0],
        this.bindDragEnd
      );
    },
  },
};
</script>
