import Vue from 'vue';
import { WNInterface } from 'vue-wni';
import config from '@/config';
import device from 'ua-parser-js';
import { v4 as uuid } from 'uuid';

const app = {
  namespaced: true,
  state: {
    config: config,
    wni: WNInterface
  },
  actions: {
    device({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        try {
          if (window.navigator.userAgent.indexOf('WNInterface') !== -1) {
            WNInterface.execute('wnAppInfo', {
              callback: WNInterface.cb((e) => {
                try {
                  let info = {
                    app_id: e.appId,
                    app_version: e.appVersion,
                    os_type: e.osType,
                    os_version: e.osVersion,
                    device_id: e.deviceId,
                    device_locale: e.deviceLocale,
                    device_type: e.deviceType,
                    device_model: e.deviceModel,
                    device_user_agent: navigator.userAgent,
                    push_token: e.pushToken
                  };

                  rootState.config.userHeaders['x-device-info'] = JSON.stringify(info);
                  resolve(info);
                } catch (e) {
                  reject(e);
                }
              })
            });
          } else {
            let deviceId = localStorage.getItem('__MOTIF_DEVICE_ID__') || uuid();
            if (!localStorage.getItem('__MOTIF_DEVICE_ID__')) {
              localStorage.setItem('__MOTIF_DEVICE_ID__', deviceId);
            }

            let deviceInfo = device(navigator.userAgent);
            let info = {
              app_id: 'com.lugstay.web',
              app_version: state.config.version,
              os_type: deviceInfo.os.name.toLowerCase(),
              os_version: deviceInfo.os.version,
              device_id: deviceId,
              device_locale: state.config.userLocale,
              device_type: 'web',
              device_model: deviceInfo.browser.name,
              device_user_agent: navigator.userAgent,
              push_token: ''
            };

            rootState.config.userHeaders['x-device-info'] = JSON.stringify(info);
            resolve(info);
          }
        } catch (e) {
          reject(e);
        }
      });
    },

    initialize({ state, rootState, commit, dispatch }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!rootState.config.userHeaders['x-device-info']) {
            await dispatch("device", {});
          }

          let deviceInfo = JSON.parse(rootState.config.userHeaders['x-device-info']);

          const res = await Vue.axios.get(rootState.config.apiURL + '/v2/app/initialize', {
            params: { ...payload, ...deviceInfo },
            headers: rootState.config.userHeaders
          });

          let { data, error } = res.data;

          if (error) {
            throw new Error(error);
          }

          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },

    pushRegister({ state, rootState, commit, dispatch }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          if (!rootState.config.userHeaders['x-device-info']) {
            await dispatch("device", {});
          }

          const res = await Vue.axios.post(rootState.config.apiURL + '/v2/app/push/register', payload, { headers: rootState.config.userHeaders });

          let { data, error } = res.data;

          if (error) {
            throw new Error(error);
          }

          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    }
  }
};

export default app;
