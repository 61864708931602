import Vue from "vue"

const popup = {
  namespaced: true,
  state: {
    items : []
  },
  mutations: {
    PUSH_ITEM(state, payload){
      if (typeof payload.data == "object" && payload.data.id) {
        state.items.push(payload.data)
      }
    },
    POP_ITEM(state){
      if(state.items.length > 0){
        state.items.splice(state.items.length - 1, 1)
      }
    },
    REMOVE_ITEM(state, payload){
      if (typeof payload.data == "object" && payload.data.id) {
        const targetIndex = state.items.findIndex( o => o.id === payload.data.id )
        if(targetIndex > -1){
          state.items.splice(targetIndex, 1);
        }
      }
    },
    CLEAR_ITEMS(state) {
      state.items = [];
    },
  },

  getters:{
    GET_LAST_ITEM: (state, getters)  => () => {
      if(state.items.length < 1) return null;

      return state.items[state.items.length - 1];
    },
  },

  actions: {
    pop({ state, commit, dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        if(state.items.length < 1) resolve('')

        const value = state.items[state.items.length - 1];
        commit('POP_ITEM')

        resolve(value)
      })
    }
  }
};

export default popup;
