<template>
  <div class="ui-coupon-select-v2" :class="{ hover: state.hover, fill: state.fill, error: error, disabled: disabled }">
    <div class="label">{{ label }}</div>
    <div class="body">
      <!-- <div class="placeholder">{{ value || placeholder }} <i class="fa fa-angle-down"></i></div> -->
      <div class="placeholder">{{ placeholder }} <i class="fa fa-angle-down"></i></div>
      <select ref="input" v-model="model" :disabled="disabled">
        <option value="">{{ $__t('선택안함') }}</option>
        <option v-for="(item, index) in items" :value="item" :key="`item-${index + 1}`" :disabled="item.expired === true">
          {{ item.coupon_name || item.pass_code }}
        </option>
      </select>
    </div>
    <div class="error">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'items', 'placeholder', 'label', 'error', 'disabled', 'required'],

  data() {
    return {
      state: {
        hover: false,
        fill: false
      }
    };
  },
  mounted() {
    // console.log('this.model', this.model);
  },

  watch: {
    model() {
      // console.log(this.model);
    }
  },

  computed: {
    model: {
      get() {
        // console.log('this.value', this.value);
        if (!this.value?.coupon_code) {
          return this.value;
        }
        if (typeof this.value == 'number' || this.value) {
          this.state.fill = true;
        } else {
          this.state.fill = false;
        }

        return this.value;
      },
      set(value) {
        // console.log('set value', value);
        this.$emit('input', value);
      }
    }
  },

  methods: {
    onFocus() {
      this.state.focus = true;
    }
  }
};
</script>
