<template>
  <div class="container">{{ duration }}</div>
</template>

<script>
export default {
  name: 'CertnumTimer',
  data() {
    return {
      duration: '03:00',
      countdownTime: 180,
      timerId: null
    };
  },
  props: {
    start: {
      type: Number
    }
  },
  mounted() {
    // this.startTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },

  watch: {
    start: function () {
      this.stopTimer();
      this.countdownTime = 180;
      this.startTimer();
    }
  },

  methods: {
    timer() {
      this.countdownTime = this.countdownTime - 1;

      let minute = '0' + Math.floor(this.countdownTime / 60); // 분
      let second = this.countdownTime - minute * 60; // 초
      second = second.toString().padStart(2, '0');
      this.duration = minute + ':' + second;

      if (this.countdownTime <= 0) {
        this.stopTimer();
        return;
      }
    },

    startTimer() {
      // this.stopTimer();
      this.timerId = setInterval(this.timer, 1000); // 1초마다 timer함수 실행된다.
      // if (this.duration === "00:00:00") {
      //   this.stopTimer();
      // }
    },

    stopTimer() {
      clearInterval(this.timerId);
      this.timerId = null;
    }
    // tick() {
    //   this.timeDuration = this.timeDuration - 1000;
    // }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
}
img {
  margin-right: unit(5);
}
</style>
