<template>
	<div class="ui-rating-select" :class="{ hover: state.hover }">
		<div class="label">{{ model }}{{ $__t('점') }}</div>
		<div class="body" ref="hover">
			<div class="background">
				<i class="fa fa-star"></i>
				<i class="fa fa-star"></i>
				<i class="fa fa-star"></i>
				<i class="fa fa-star"></i>
				<i class="fa fa-star"></i>
			</div>
			<div class="point" v-if="model">
				<i class="fa fa-star" v-for="i in parseInt(model / 2)" :key="i"></i>
				<i class="fa fa-star-half" v-if="model / 2 != parseInt(model / 2)"></i>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['value', 'items', 'label', 'error'],
	data() {
		var state = { hover: false, focus: false, fill: true, error: false };
		return { state };
	},
	computed: {
		model: {
			get() {
				if (this.value) {
					return this.value;
				} else {
					this.$emit('input', 8);
				}
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},
	mounted() {
		if (!this.$store.state.config.isTouchDevice) {
			this.$refs.hover.addEventListener('mouseover', (e) => (this.state.hover = true));
			this.$refs.hover.addEventListener('mouseout', (e) => (this.state.hover = false));
		}
		this.$refs.hover.addEventListener('mousedown', (e) => {
			var bound = this.$refs.hover.getBoundingClientRect();
			var point = 0;
			point = (e.x - bound.x) / bound.width;
			point = point * 10;
			point = point.toFixed(1);
			point = Math.ceil(point);
			this.$emit('input', point);
		});
	},
};
</script>
