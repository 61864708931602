<template>
  <div v-if="!disabled" class="ui-button border-round-button" :class="'button-color-'+color+' button-size-'+size+' button-display-'+display" @click="$emit( 'click' )">
    <slot />
  </div>
  <div v-else class="ui-button border-round-button" :class="'button-color-disabled button-size-'+size+' button-display-'+display" style="cursor: not-allowed;">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiBorderRoundButton',
  props: {
    size: {
      type: String,
      default: "medium"
    },
    color: {
      type: String,
      default: "primary"
    },
    display: {
      type: String,
      default: "inline"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
