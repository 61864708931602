/**
 * @author 이건호 <rheegon@gmail.com>
 */

import config from '@/config';
import { MD5 } from '@/helpers';

/**
 *
 * @param {string} src iframe에 연동할 src
 * @description Lugstay 2.0 연동 iframe을 실행한다.
 * @returns { HTMLIFrameElement } iframe
 */

function _initFrame(src) {
  const iframe = document.createElement('IFRAME');

  iframe.id = MD5(src);
  iframe.frameBorder = 0;
  iframe.accessFullScreen = true;
  iframe.scrolling = false;
  iframe.allow = 'autoplay; fullscreen';

  iframe.style = 'width: 100%; height: 100%;';
  iframe.src = src;
  iframe.async = true;

  return iframe;
}

function _getIframeDoc(iframe) {
  return iframe?.contentDocument || iframe?.contentWindow?.document;
}

function _getIframeWindow(iframe) {
  return iframe?.contentWindow;
}

function _checkIframeLoaded(iframe) {
  return new Promise((resolve, reject) => {
    const iframeDoc = _getIframeDoc(iframe);
    let timeout = 5000;
    const interval = 100;

    let checkInterval = setInterval(() => {
      if (iframeDoc?.readyState === 'complete') {
        resolve(true);
      }

      timeout -= interval;

      if (timeout <= 0) {
        reject(false);
        clearInterval(checkInterval);
        checkInterval = null;
      }
    }, interval);
  });
}

export default {
  methods: {
    createIframe(src) {
      const iframe = _initFrame(src);
      return iframe;
    },

    async checkIframeLoaded(iframe) {
      const loaded = await _checkIframeLoaded(iframe);
      return loaded;
    },
  },
};
