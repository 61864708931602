<template>
	<transition name="lug-popup">
		<div
			v-click-outside="closePopup"
			v-if="opened"
			id="popup"
			class="lug-popup"
			:style="{ top: coordinates.clientY + 'px', left: coordinates.clientX + 'px' }"
			style="transform: translateX(-100%);"
		>
			<div v-if="!Object.keys($slots).length" class="text-list">
				<ul>
					<li @click="clickItem(item)" v-for="(item, index) in items" :key="index">
						<span class="text">{{ item }}</span>
					</li>
				</ul>
			</div>
			<slot name="body" />
		</div>
	</transition>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
	// props: ['coordinates', 'items', 'blur'],
	props: {
		coordinates: {
			type: Object,
			default: null,
		},
		items: {
			type: Array,
			default: null,
		},
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	data() {
		return {
			opened: false,
		};
	},
	methods: {
		clickItem(item) {
			this.$emit('clickItem', item);
		},
		openPopup() {
			this.opened = true;
		},
		closePopup() {
			this.opened = false;
		},
	},
};
</script>
