import Vue from 'vue';

import config from '@/config';
import store from '@/store';

// import router from '@/v3/router';
import router from '@/router';

const mode = process.env.VUE_APP_MODE || process.env.NODE_ENV;

// Load Modules
import '@/modules';

// Global SCSS
import '@/assets/scss/site.scss';

// Global Components
import BorderButton from '@/components/modules/Button/Border.vue';
import SolidButton from '@/components/modules/Button/Solid.vue';
import TextButton from '@/components/modules/Button/Text.vue';
import BorderRoundButton from '@/components/modules/LugButton/BorderRound.vue';
import BorderArrowButton from '@/components/modules/LugButton/BorderArrow.vue';

Vue.component('UiBorderButton', BorderButton);
Vue.component('UiSolidButton', SolidButton);
Vue.component('UiTextButton', TextButton);
Vue.component('UiBorderRoundButton', BorderRoundButton);
Vue.component('UiBorderArrowButton', BorderArrowButton);

// FUNCTION ELEMENT
import InterestToggle from '@/components/modules/Interest/Body.vue';
Vue.component('InterestToggle', InterestToggle);

// INCLUDES
import LoginForm from '@/components/includes/LoginForm';
import ShareForm from '@/components/includes/ShareForm';
import ReviewForm from '@/components/includes/ReviewForm';
import CloseForm from '@/components/includes/CloseForm';
import Promotion from '@/components/includes/Promotion';
import TermsOfUseForm from '@/components/includes/TermsOfUseForm';

Vue.component('LoginForm', LoginForm);
Vue.component('ShareForm', ShareForm);
Vue.component('ReviewForm', ReviewForm);
Vue.component('CloseForm', CloseForm);
Vue.component('Promotion', Promotion);
Vue.component('TermsOfUseForm', TermsOfUseForm);

import Modal from '@/components/modules/LugModal/Modal';
import GuideModal from '@/components/modules/LugModal/GuideModal';
import FullModal from '@/components/modules/LugModal/FullModal';

import Popup from '@/components/modules/LugPopupMenu/Popup.vue';
import UiPopup from '@/components/modules/Popup/Popup';
import CardForm from '@/components/includes/CardForm';

Vue.component('FullModal', FullModal);
Vue.component('GuideModal', GuideModal);
Vue.component('Modal', Modal);
Vue.component('Popup', Popup);
Vue.component('UiPopup', UiPopup);
Vue.component('CardForm', CardForm);

import DateTimePicker from '@/components/modules/DateTimePicker/Body';

Vue.component('DateTimePicker', DateTimePicker);

import './serviceWorker';

import App from './App';

import Debug from 'debug';
if (config.mode !== 'production') {
  Debug.enable('lugstay:web:*');
} else {
  Debug.enable('');
}

window.addEventListener('DOMContentLoaded', () => {
  if (location.host === 'lugstay.com') {
    location.href = location.href.replace(location.host, 'www.lugstay.com');
  }

  window.vueInstance = new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app');
});
