

const notice = {
  namespaced: true,
  state: {
    items: [
      // {
      //   message: "북마크에 저장되었습니다.",
      //   status: "success"
      // }
    ]
  },
  getters: {
    GET_ITEMS: ( state ) => {
      return state.items;
    }
  },
  mutations: {
    ADD_ITEM( state, payload ){
      state.items = [];
      setTimeout(() => { state.items.push( payload ); }, 0);
    },
    REMOVE_ITEM( state, payload ){
      state.items = state.items.filter( o => o != payload );
    }
  }
}

export default notice
