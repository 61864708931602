<template>
  <transition name="slide-down">
    <div class="lug-full-modal" v-if="opened" ref="background">
      <div class="container">
        <img
          @click.stop="close"
          src="/img/modal-close.png"
          srcset="/img/modal-close@2x.png 2x, /img/modal-close@3x.png 3x"
        />
        <div class="body"><slot name="body" /></div>
      </div>
    </div>
  </transition>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      opened: false,
    };
  },
  watch: {
    opened(opened) {
      if (opened) {
        this.$nextTick(() => {
          if (this.$refs.background) {
            this.$refs.background.addEventListener("mousedown", (e) => {
              if (this.$store.state.config.isTouchDevice || e.which == 1) {
                if (e.target == this.$el) {
                  this.close();
                }
              }
            });
          }

          $("html, body").css("overflow", "hidden");
        });
      } else {
        this.$nextTick(() => {
          $("html, body").removeAttr("style");
        });
      }
    },
  },

  destroyed() {
    $("html, body").removeAttr("style");
  },

  beforeDestroy(){
    this.$store.commit("popup/REMOVE_ITEM", { data: { id: this.$attrs.id } });
  },


  computed: {
    topLayerPopup() {
      return this.$store.getters["popup/GET_LAST_ITEM"]();
    },
  },

  methods: {
    async close() {
      const poppedPopup = this.topLayerPopup;

      if (poppedPopup && poppedPopup.id === this.$attrs.id) {
        this.opened = false;
        await this.$store.dispatch("popup/pop");
        this.$emit("modalClosed");
      }
    },

    clickClose: _.debounce(function () {
      window.history.go(-1);
    }, 10),

    open(ref = "") {
      this.opened = true;
      this.$emit("modalOpened");
      this.$store.commit("popup/PUSH_ITEM", { data: { id: this.$attrs.id, context: this } });
    },
  },
};
</script>
