const GoogleTagManager = {
  name: 'GoogleTagManager',

  init(app, options = {}, callback = function () {}) {
    this._appId = options.appId;

    if (this._appId) {
      const noscript = document.createElement('NOSCRIPT');
      noscript.innerHTML = `<iframe
      src="https://www.googletagmanager.com/ns.html?id=${this._appId}"
      height="0"
      width="0"
      style="display: none; visibility: hidden"
    ></iframe
  >`;
      document.body.appendChild(noscript);
    } else {
      callback();
    }
  },
};

export default GoogleTagManager;
