<template>
  <form ref="form" @submit.prevent="onSubmit" spellcheck="false">
    <slot />
  </form>
</template>

<script>
export default {
  props: ['formData', 'ignoreSubmit'],
  data() {
    return {
      existError: false
    };
  },
  methods: {
    onSubmit() {
      // this.$log.log("onSubmit()")
      if (this.ignoreSubmit === true) {
        return false;
      }

      this.submitForm();
      return true;
    },

    submit() {
      // this.$log.log("submit()")
      this.submitForm();
    },
    submitForm() {
      this.existError = false;
      Object.keys(this.formData).forEach((o) => {
        this.validateField(this.formData[o]);
      });

      if (!this.existError) {
        this.$emit('submit', this.serializeFields());
      } else {
        this.$log.error('this.existError');
        this.$emit('error');
      }
    },
    validateField(ob) {
      if (ob.value && typeof ob.value == 'string') ob.value = ob.value.trim();
      if (!ob.validation) return false;
      // this.$log.log(JSON.stringify(ob));

      if (!Array.isArray(ob.validation)) {
        ob.validation = [{ ...ob.validation }];
      }

      ob.validation.forEach((o) => {
        // this.$log.log(JSON.stringify(o));
        var result = null;
        if (o.type.split(':').length > 1) {
          var vn = o.type.split(':')[0];
          var vv = o.type.split(':')[1];
          switch (vn) {
            case 'min':
              if (ob.value.length < vv) {
                result = o.failMessage ? o.failMessage : this.$__t('__VALIDATION_ERROR_MIN_PRE') + vv + this.$__t('__VALIDATION_ERROR_MIN_SUF');
              }
              break;
            case 'max':
              if (ob.value.length > vv) {
                result = o.failMessage ? o.failMessage : this.$__t('__VALIDATION_ERROR_MAX_PRE') + vv + this.$__t('__VALIDATION_ERROR_MAX_SUF');
              }
              break;
            case 'matchField':
              if (ob.value != this.formData[vv].value) {
                result = o.failMessage ? o.failMessage : this.$__t('Password are not matched.');
              }
              break;
            case 'matchString':
              if (ob.value != vv) {
                result = o.failMessage
                  ? o.failMessage
                  : this.$__t('__VALIDATION_ERROR_MATCH_STRING_PRE') + vv + this.$__t('__VALIDATION_ERROR_MATCH_STRING_SUF');
              }
              break;
          }
        } else {
          var vn = o.type;
          // this.$log.log("type:", vn, ob);
          switch (vn) {
            case 'password':
              var regExp = /^[0-9]{4}$/;
              if (typeof ob.value != 'string' || !regExp.test(ob.value)) {
                result = o.failMessage ? o.failMessage : this.$__t('Password must be numbers of 4.');
              }
              break;
            case 'email':
              var regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
              if (!regExp.test(ob.value)) {
                result = o.failMessage ? o.failMessage : this.$__t('__VALIDATION_ERROR_EMAIL');
              }
              break;
            case 'required':
              if (typeof ob.value != 'number' && !ob.value) {
                result = o.failMessage ? o.failMessage : this.$__t('__VALIDATION_ERROR_REQUIRED');
              }
              break;
            case 'number':
              var regExp = /^[0-9]+$/;
              if (!regExp.test(ob.value)) {
                result = o.failMessage ? o.failMessage : this.$__t('__VALIDATION_ERROR_NUMBER');
              }
              break;
            case 'english_first':
              var regExp = /^[A-Za-z ]*$/;
              if (!ob.value || !regExp.test(ob.value)) {
                result = o.failMessage ? o.failMessage : this.$__t('English first name on passport.');
              }
              break;
            case 'english_last':
              var regExp = /^[A-Za-z ]*$/;
              if (!ob.value || !regExp.test(ob.value)) {
                result = o.failMessage ? o.failMessage : this.$__t('English last name on passport.');
              }
              break;
            case 'koreanOrEnglish':
              var regExp = /^[가-힣|A-Za-z]*$/;
              if (!ob.value || !regExp.test(ob.value)) {
                result = o.failMessage ? o.failMessage : this.$__t('Please insert Korean or English');
              }
              break;
            case 'passwordLength':
              var regExp = /^(?=.{8,20}$).*$/;
              if (!ob.value || !regExp.test(ob.value)) {
                result = o.failMessage ? o.failMessage : this.$__t('Your password must be between 8 and 20 characters.');
              }
              break;
            case 'length':
              var regExp = /^(?=.{4}$).*$/;
              if (!regExp.test(ob.value)) {
                if (o.length == 4) {
                  result = o.failMessage ? o.failMessage : this.$__t('The password is four digits.');
                }
              }
              break;
          }
        }

        if (result) {
          if (ob.error != this.$__t('__VALIDATION_ERROR_REQUIRED')) {
            ob.error = result;
          }
          this.existError = true;
        } else {
          ob.error = '';
        }
      });
    },
    validateShareField(ob) {
      Object.keys(ob).forEach((o) => {
        let nameObj = ob[o];
        Object.keys(nameObj).forEach((oc) => {
          let nameValue = nameObj[oc];
          nameValue.validation.forEach((v) => {
            var result = null;
            var vt = v.type;
            switch (vt) {
              case 'required':
                if (typeof nameValue.value != 'number' && !nameValue.value) {
                  result = this.$__t('__VALIDATION_ERROR_REQUIRED');
                }
                break;
              case 'english':
                var regExp = /^[A-Za-z ]*$/;
                // this.$log.log( typeof nameValue.value != "string" && !regExp.test(nameValue.value));
                if (!nameValue.value || !regExp.test(nameValue.value)) {
                  if (oc == 'user_first_name') {
                    result = this.$__t('English first name of passport.');
                  } else {
                    result = this.$__t('English last name of passport.');
                  }
                }
                break;
              case 'number':
                var regExp = /^[0-9]+$/;
                if (!regExp.test(nameValue.value)) {
                  result = this.$__t('__VALIDATION_ERROR_NUMBER');
                }
                break;
            }

            if (result) {
              if (nameValue.error != this.$__t('__VALIDATION_ERROR_REQUIRED')) {
                nameValue.error = result;
              }
              this.existError = true;
            } else {
              nameValue.error = '';
            }
          });
        });
      });
    },
    serializeFields() {
      var values = {};
      Object.keys(this.formData).forEach((o) => {
        if (typeof this.formData[o].value == 'undefined') {
          if (o == 'shareUser') {
            let sharerObj = {};
            let sharers = this.formData[o];
            Object.keys(sharers).forEach((p) => {
              //p는 sharers의 키 => 0, 1, 2 ---
              let sharer = sharers[p];
              let shareUserValues = {};
              Object.keys(sharer).forEach((c) => {
                //c는 sharer의 키 => user_last_name, user_first_name, ----
                shareUserValues[c] = sharer[c].value;
                let shareUserName = 'shareUser_' + p;
                sharerObj[shareUserName] = shareUserValues;
              });
            });
            values[o] = sharerObj;
          } else {
            values[o] = this.formData[o].value;
          }
        } else if (typeof this.formData[o].value == 'array' || typeof this.formData[o].value == 'object') {
          values[o] = JSON.stringify(this.formData[o].value);
        } else {
          if (typeof this.formData[o].value == 'boolean') {
            values[o] = this.formData[o].value;
          } else {
            values[o] = this.formData[o].value.toString();
          }
        }
      });
      return values;
    },
    resetForm() {
      Object.keys(this.formData).forEach((o) => {
        this.formData[o].value = '';
      });
    }
  }
};
</script>
