<template>
  <div id="login-form" class="includes form">
    <div class="form-close" v-if="$store.state.config.isTouchDevice && !noRegister">
      <i class="fa fa-times" @click="$router.back()"></i>
    </div>
    <!--로그인 or 전화번호 인증 header-->
    <div class="form-header">
      <div class="title" v-if="noRegister">{{ $__t('전화번호 인증') }}</div>
      <!-- <div class="title" v-else>
        {{ this.$route.params.headerText || $__t('로그인') }}
      </div> -->
    </div>
    <!--로그인 버튼까지-->
    <div class="form-body">
      <ui-form ref="form" :formData="loginFormData" @error="scrollToError" @submit="submitForm">
        <ui-form-list>
          <!--휴대폰입력 & 인증번호 -->
          <div class="phone-login-body">
            <div class="phoneNum">
              <div class="title">{{ $__t('휴대폰 번호') }}</div>
              <div class="phoneNum-requestCert">
                <lug-select
                  :placeholder="loginFormData.user_dial_code.placeholder"
                  :type="loginFormData.user_dial_code.type"
                  :items="loginFormData.user_dial_code.items"
                  v-model="loginFormData.user_dial_code.value"
                  :disabled="false"
                />
                <lug-text-input
                  :readonly="AUTH_DATA?.phone_valid"
                  :placeholder="loginFormData.user_phone.placeholder"
                  :type="loginFormData.user_phone.type"
                  :inputmode="loginFormData.user_phone.input_mode"
                  v-model="loginFormData.user_phone.value"
                  :mask="loginFormData.user_phone.mask"
                  :alert="noDataError"
                ></lug-text-input>
                <button
                  class="btn-certify"
                  :class="{ inactivated: alreadyRequestNum || !certnumRequestActivated }"
                  v-ripple="'rgba( 162, 190, 218, 0.35 )'"
                  @click.prevent="onClickRequestCertNum()"
                >
                  {{ $__t('인증요청') }}
                </button>
              </div>
              <div class="noDataAlert" v-if="noDataError" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                {{ $__t('회원정보가 없습니다. 회원가입해주세요.') }}
              </div>
              <div class="noDataAlert" v-else-if="noDataLoginError" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                {{ $__t('휴대폰 번호 입력 및 인증번호 요청을 해주세요.') }}
              </div>
            </div>
            <div class="certNum">
              <div class="title">{{ $__t('인증번호') }}</div>
              <!--인증번호입력란 & 타이머 & 재전송버튼-->
              <div class="certNum-timer-resend">
                <lug-text-input
                  :placeholder="loginFormData.user_cert_num.placeholder"
                  :type="loginFormData.user_cert_num.type"
                  :inputmode="loginFormData.user_cert_num.input_mode"
                  v-model="loginFormData.user_cert_num.value"
                  :required="false"
                  :alert="invalidCodeError || expiredCodeError"
                  :mask="loginFormData.user_cert_num.mask"
                  :readonly="!resendAndTimerActivated"
                ></lug-text-input>
                <!--타이머 & 재전송버튼-->
                <div class="timerandresend" :class="{ activated: resendAndTimerActivated }">
                  <span class="timer">
                    <certnum-timer :start="rerender" />
                  </span>
                  <span class="divider">|</span>
                  <button
                    class="btn-recertify"
                    :disabled="!resendAndTimerActivated"
                    @click.prevent="onClickRequestCertNum()"
                    v-ripple="'rgba( 162, 190, 218, 0.35 )'"
                  >
                    {{ $__t('재전송') }}
                  </button>
                </div>
              </div>
              <div v-if="invalidCodeError" class="certNumAlert" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                {{ $__t('인증번호를 확인해주세요') }}
              </div>
              <div v-else-if="expiredCodeError" class="certNumAlert" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                {{ $__t('인증번호 입력시간을 초과하였습니다') }}
              </div>
              <div v-else-if="noCodeInputError" class="certNumAlert" style="color: rgb(230, 20, 20); font-size: 0.875rem">
                {{ $__t('인증번호를 입력해주세요') }}
              </div>
            </div>
          </div>

          <!--로그인 or 확인 버튼-->
          <ui-form-list-item>
            <ui-solid-button
              v-ga="$analytics.tracking.bind(this, 'login', 'click', 'login_email_button')"
              display="block"
              color="primary"
              v-ripple="'rgba( 206, 212, 218, 0.35 )'"
            >
              <button type="submit">
                {{ this.$props.noRegister ? $__t('확인') : $__t('로그인') }}
              </button>
            </ui-solid-button>
          </ui-form-list-item>
        </ui-form-list>
      </ui-form>
    </div>
    <!--회원가입-->
    <ui-solid-button
      display="block"
      color="white"
      v-ripple="'rgba( 162, 190, 218, 0.35 )'"
      @click="onClickRegister"
      v-if="!noRegister"
      class="form-extras"
    >
      <button>
        {{ $__t('회원가입') }}
      </button>
    </ui-solid-button>
    <div class="noticeForHost">
      <div v-html="$__t('If you are an existing host, please login right away without proceeding with the membership registration!')"></div>
      📌
      {{ $__t('로그인 후 호스트 페이지 접근이 불가할 경우 고객센터로 연락 주시면 빠르게 해결해 드릴게요') }}
      🙂
    </div>

    <!-- <div class="form-extras" v-if="!noRegister" @click="onClickRegister">
      <span @click="shiftMode('register')">{{ $__t('회원가입') }}</span>
      <span v-ripple="'rgba( 162, 190, 218, 0.35 )'">{{ $__t('회원가입') }}</span>
    </div> -->

    <modal id="request-certnum-modal" ref="request-certnum-modal" :transparent="true" :hide-footer="true">
      <template v-slot:body>
        <request-certnum id="request-certnum-modal" @close-modal="closeModal('request-certnum-modal')" />
      </template>
    </modal>
  </div>
</template>

<script>
import utils from '@/utils';
import CertnumTimer from '@/components/resources/service-product/CertnumTimer.vue';
import RequestCertnum from '@/components/resources/auth/RequestCertnum.vue';
import countryCodes from '@/assets/json/country-codes.json';

export default {
  components: {
    CertnumTimer,
    RequestCertnum
  },
  props: {
    mode: {
      type: String
    },
    redirect: {
      type: String
    },
    queryString: {
      type: String,
      default: ''
    },
    callback: {
      type: Function,
      default: () => {}
    },
    noRegister: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const AUTH_DATA = this.$store.state.auth;
    let loginFormData = {
      user_dial_code: {
        // value: AUTH_DATA.user_dial_code || '+82',
        // value: '+82',
        value: '+82/KR',
        error: '',
        validation: [{ type: 'required' }],
        items: this.parseCountryCodes(countryCodes),
        label: this.$__t('국가 번호'),
        placeholder: '국가번호'
      },
      user_phone: {
        type: 'text',
        label: this.$__t('휴대폰 번호'),
        placeholder: this.$__t("'-'제외하고 번호만 입력해주세요"),
        validation: [{ type: 'required' }, { type: 'number' }],
        input_mode: 'numeric',
        error: '',
        value: AUTH_DATA.phone || AUTH_DATA.user_phone,
        mask: '###-####-####',
        cert_number: '',
        verifiing: false,
        need_to_retry: false,
        auth_key: null
      },
      user_cert_num: {
        type: 'text',
        label: this.$__t('인증번호'),
        placeholder: this.$__t('인증번호 6자리를 입력해주세요'),
        // validation: [{ type: 'number' }],
        error: '',
        input_mode: 'numeric',
        value: '',
        mask: '######'
      }
    };

    let formMode = 'login';

    return {
      loginFormData,
      formMode,
      loading: false,
      AUTH_DATA,
      invalidCodeError: false,
      expiredCodeError: false,
      noDataError: false,
      noDataLoginError: false,
      noCodeInputError: false,
      timerStart: false,
      rerender: 1,
      resendAndTimerActivated: false,
      alreadyRequestNum: false
    };
  },
  created() {
    // console.log('loginFormData.user_dial_code.items', this.loginFormData.user_dial_code.items);
    // console.log('AUTH_DATA', this.AUTH_DATA);
    // console.log('loginFormData.user_dial_code.value', this.loginFormData.user_dial_code.value);

    if (this.mode && this.mode === 'register') {
      this.formMode = 'register';
    }
    this.$analytics.logEvent('login page view');
    window.addEventListener('popstate', this.onHistoryBack, false);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.onHistoryBack);
  },
  computed: {
    certnumRequestActivated() {
      // console.log('this.loginFormData.user_dial_code.value', this.loginFormData.user_dial_code.value);
      if (this.loginFormData.user_dial_code.value === '+82/KR') {
        if (this.loginFormData?.user_phone?.value?.length === 11) {
          return true;
        }
        return false;
      } else {
        if (this.loginFormData?.user_phone?.value?.length > 0) {
          return true;
        }
        return false;
      }
    },
    formData() {
      if (this.formMode === 'login') {
        return this.loginFormData;
      } else if (this.formMode === 'register') {
        return Object.assign({}, this.loginFormData, this.registerFormData);
      }
    },
    isAndroidApp() {
      return !!(navigator.userAgent.toLowerCase().indexOf('android') > -1 && this.wni.isNative);
    },
    isViewTypeIez() {
      return (
        this.$store.getters['userData/GET_ITEM']({
          name: '__LUGSTAY__VIEW__TYPE__',
          noDefault: true
        }) === 'iez'
      );
    },
    campaignCode() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'campaignCode' });
    },

    promotion() {
      return this.$store.getters['userData/GET_ITEM']({ key: 'promotion' });
    }

    // isForIosAppInspection(){
    // 	if(this.wni.isNative){
    // 		const deviceInfo = utils.parseJSON(this.$store.state.config.userHeaders["x-device-info"]);

    // 		return (deviceInfo.os_type === 'ios') && (deviceInfo.app_version ===  "1.2.33")
    // 	}

    // 	return false;
    // }
  },

  watch: {
    loginFormData: {
      deep: true,

      handler() {
        // console.log('ddd', this.formData.user_dial_code.value === '+82', this.formData.user_phone.mask);
        if (this.loginFormData.user_dial_code.value === '+82/KR') {
          return (this.loginFormData.user_phone.mask = '###-####-####');
        } else {
          return (this.loginFormData.user_phone.mask = null);
        }
      }
    }
  },
  methods: {
    parseCountryCodes(countryCodes) {
      let _dial_code =
        countryCodes.map((o) => ({
          label: `${o.dial_code} (${o.code})`,
          // value: o.dial_code
          // value: o
          value: o.dial_code + '/' + o.code
        })) || [];
      _dial_code.sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      _dial_code.forEach((o) => {
        //
        if (o.name === 'Korea, Republic of') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.unshift(o);
        }
        //
        if (o.name === 'Others') {
          _dial_code.splice(_dial_code.indexOf(o), 1);
          _dial_code.push(o);
        }
      });

      return _dial_code;
    },

    openModal(ref) {
      this.$refs[ref].open();
    },

    closeModal(ref) {
      this.$refs[ref].close();
    },
    async onClickRequestCertNum(e) {
      this.invalidCodeError = false;
      this.expiredCodeError = false;
      this.noDataError = false;
      this.noCodeInputError = false;
      this.noDataLoginError = false;
      this.loginFormData.user_cert_num.value = '';
      this.alreadyRequestNum = true;
      // this.timerStart = true;
      let res;

      try {
        this.loginFormData.user_phone.verifiing = true;
        res = await this.$store.dispatch('auth/authNumRequest', {
          //로그인할때는 user_name은 undefined. user_name이 필요없다.
          user_name: this.AUTH_DATA.user_name,
          phone: this.loginFormData.user_phone.value,
          user_dial_code: this.loginFormData.user_dial_code.value.split('/')[0],
          user_iso_code: this.loginFormData.user_dial_code.value.split('/')[1]
        });

        if (this.$store.state.config.mode === 'debug') {
          const { auth_code } = res;
          setTimeout(() => {
            this.formData.user_cert_num.value = auth_code;
          }, 100);
        }

        this.loginFormData.user_phone.auth_key = res.auth_key;
        this.loginFormData.user_phone.need_to_retry = true;
        this.rerender = this.rerender + 1;
        this.resendAndTimerActivated = true;
        // this.openModal('request-certnum-modal');
      } catch (e) {
        this.noDataError = true;
        this.alreadyRequestNum = false;
        console.error(e);
      } finally {
        this.loginFormData.user_phone.verifiing = false;
      }

      return;
    },

    onHistoryBack() {
      this.$analytics.tracking.call(this, 'login', 'click', 'login_back');
    },

    async login() {
      this.invalidCodeError = false;
      this.expiredCodeError = false;
      this.noDataLoginError = false;
      this.noCodeInputError = false;
      let payload = {
        auth_key: this.loginFormData.user_phone.auth_key,
        auth_code: this.loginFormData.user_cert_num.value
      };
      return await this.$store.dispatch('auth/login', payload);
    },
    async phoneCertify() {
      this.invalidCodeError = false;
      this.expiredCodeError = false;
      this.noDataLoginError = false;
      this.noCodeInputError = false;
      let payload = {
        auth_key: this.loginFormData.user_phone.auth_key,
        auth_code: this.loginFormData.user_cert_num.value
      };
      // console.log('payload', payload);
      return await this.$store.dispatch('auth/phoneCertifyRequest', payload);
    },

    async submitForm(values) {
      //로그인되어있는 상태에서 '핸드폰인증'할때
      if (this.$props.noRegister) {
        this.phoneCertify().then((res) => {
          if (res.message === 'invalid_code') {
            this.invalidCodeError = true;
            return;
          } else if (res.message === 'expired_key') {
            this.expiredCodeError = true;
            return;
          } else if (res.gid > 0) {
            console.log('###1');

            this.$store.dispatch('auth/redirect', {
              redirect: this.redirect,
              queryString: this.queryString,
              query: this.$route.query,
              callback: this.callback
            });

            // this.$router.push({ name: "Home" });
          }
        });
        //로그인 할때
      } else {
        try {
          this.login().then((res) => {
            // console.log('res', res);
            if (res.data.data.message === 'invalid_code') {
              this.invalidCodeError = true;
              return;
            } else if (res.data.data.message === 'expired_key') {
              this.expiredCodeError = true;
              return;
            } else if (res.data.error === 'NO_EXISTS_USER') {
              this.noDataError = true;
              return;
            } else if (res.data.error === 'INSUFFICIENT_PARAMS') {
              this.noCodeInputError = true;
              return;
            } else if (res.data.data.profile) {
              console.log('###2');

              this.$store.dispatch('auth/redirect', {
                redirect: this.redirect,
                queryString: this.queryString,
                query: this.$route.query,
                callback: this.callback
              });
            }
          });
        } catch (e) {
          console.error(e);
        }
      }
    },

    scrollToError() {
      this.noDataError = false;
      this.noDataLoginError = true;
      this.loginFormData.user_cert_num.value = '';
      setTimeout(() => {
        if ($('.ui-form-list-item .error').length >= 0) {
          if ($('.modal-item').length > 0) {
            $('.modal-item').scrollTop($($('.ui-form-list-item .error')[0]).offset().top);
          } else {
            $('html, body').scrollTop($($('.ui-form-list-item .error')[0]).offset().top - $('#site-header').innerHeight());
          }
        }
      }, 0);
    },

    socialAccess(provider) {
      this.$analytics.tracking.call(this, 'login', 'click', 'login_' + provider);
      this.$store
        .dispatch('auth/socialAccess', { provider })
        .then(async (res) => {
          this.loading = true;
          this.$analytics.tracking.call(this, 'login_session', 'login', 'login_session_' + provider);
          try {
            await this.createInviteCouponForIezUser();
          } catch (e) {}

          this.$analytics.logEvent('login complete', {
            provider: provider.charAt(0).toUpperCase() + provider.slice(1)
          });
          await this.$store.dispatch('auth/redirect', {
            callback: this.callback,
            redirect: this.redirect,
            queryString: this.queryString + this.queryString ? '&' : '?' + 'provider=' + provider.charAt(0).toUpperCase() + provider.slice(1)
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit('alert/ADD_ITEM', {
            message: err,
            status: 'error'
          });
        });
    },

    // shiftMode(mode) {
    //   if (mode === 'register') {
    //     console.log('회원가입');
    //     this.$analytics.tracking.call(this, 'login', 'click', 'login_sign_up');

    //     if (this.$store.state.config.isTouchDevice) {
    //       return this.$router.push({ name: 'AuthRegisterTerms' });
    //     }
    //   }
    //   if (mode === 'login') this.$analytics.tracking.call(this, 'login', 'click', 'login_sign_up_login');
    //   this.formMode = mode;
    // },
    onClickRegister() {
      this.$analytics.tracking.call(this, 'login', 'click', 'login_sign_up');
      return this.$router.push({ name: 'AuthRegisterTerms', query: this.$route.query });
    }

    // notify() {
    //   alert(this.$__t('Service is being prepared.'));
    // },

    // async createInviteCouponForIezUser() {
    //   if (!this.isViewTypeIez) return;

    //   if (this.promotion && this.campaignCode) {
    //     await this.axios.post(
    //       this.$store.state.config.apiURL + '/v2/campaigns/invite/' + this.campaignCode,
    //       {},
    //       { headers: this.$store.state.config.userHeaders }
    //     );
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
#login-form {
  background-color: #fff;
}
.form-extra {
  font-size: unit(12);
  line-height: unit(16);
  vertical-align: bottom;

  img {
    display: inline-block;
    width: unit(12);
    height: unit(12);
    line-height: unit(12);
  }
}
.phone-login-body {
  margin-bottom: unit(30);
  .title {
    color: #101010;
    font-size: unit(18);
    font-weight: 600;
  }
  .phoneNum {
    margin-bottom: unit(20);
    .phoneNum-requestCert {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: unit(20);
      .btn-certify {
        color: #2c6790;

        font-size: unit(15);
        width: unit(60);
        &:active {
          opacity: 0.5 !important;
        }
        &.inactivated {
          pointer-events: none;
          color: #c8c8c8;
        }
      }
    }
  }
  .certNum {
    .certNum-timer-resend {
      display: flex;
      gap: unit(20);
      .timerandresend {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: unit(100);
        gap: unit(4);
        color: #c8c8c8;
        font-size: unit(15);
        &.activated {
          color: #2c6790;
        }
        .divider {
          color: #e0e0e0;
          font-weight: 600;
          margin: 0 unit(5);
        }
        .btn-recertify {
          &:active {
            opacity: 0.5 !important;
          }
        }
      }
    }
  }
}

:deep() {
  .noticeForHost {
    margin-top: unit(10);
    padding: unit(10);
    font-size: unit(15);
    line-height: unit(23);
    border: 1px solid #c8c8c8;

    .focus {
      font-weight: 700;
    }
  }
}
</style>
