<template>
  <div class="resource notice-popup">
    <div class="notice-popup__head">
      <h1>{{ notice.title }}</h1>
    </div>
    <div class="notice-popup__body">
      <div class="notice-content" v-html="notice.html"></div>
    </div>

    <div class="notice-popup__actions">
      <button type="button" class="btn-detail" @click="onClickDetail"><span>{{ $__t('자세히 보러가기') }}</span></button>
      <button type="button" class="btn-skip" @click="onClickSkip"><span>{{ $__t('다시 보지 않기') }}</span></button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    notice() {
      return this.$store.getters["custom/popupNotice"];
    },
  },
  methods: {
    onClickDetail(e) {
      // this.$store.dispatch("custom/showNoticePopup", { id: this.notice.id});
      this.$emit("close");

      const router = this.$router;

      setTimeout(() => {
        router.push({
          name: "CsNoticesSingle",
          params: {
            id: this.notice.id
          }
        });
      }, 500);
    },

    onClickSkip(e) {
      this.$store.dispatch("custom/showNoticePopup", { id: this.notice.id });
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>

.notice-popup {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: unit(16) unit(8);
  border-radius: unit(16);

  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      text-align: center;
      color: #101010;
      font-size: unit(20);
      font-weight: 600;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :deep() {
      .notice-content {
        padding: unit(16) 0;

        h3 {
          text-align: center;
          color: #101010;
          font-size: unit(16);
          font-weight: 600;
          &:not(:first-child) {
            margin-top: unit(16);
          }
        }

        p {
          text-align: center;
          color: #101010;
          font-size: unit(16);
          font-weight: 400;
          line-height: 1.2;
        }
      }
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 unit(8);
    margin-top: unit(8);

    button {
      width: 100%;
      height: unit(48);
      border-radius: unit(12);
      cursor: pointer;

      span {
        font-size: unit(16);
        font-weight: 600;
      }

      &.btn-detail {
        background-color: #00c6f1;

        span {
          color: #fff;
        }
      }

      &.btn-skip {
        background: none;

        span {
          color: #a0a0a0;
          font-weight: 400;
        }
      }
    }
  }
}
</style>