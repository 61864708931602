import network from '@/modules/network';
import { WNInterface } from 'vue-wni';
import { translate } from '@/modules/language';

const qrCodeScanner = {
  namespaced: true,
  state: {
    opened: false,
    callback: null
  },
  getters: {
    IS_OPENED: (state) => {
      return state.opened;
    }
  },
  mutations: {
    OPEN_SCANNER(state, payload) {
      const { callback } = payload;
      state.callback = callback;
      state.opened = true;
    },

    CLOSE_SCANNER(state) {
      state.callback = null;
      state.opened = false;
    }
  },
  actions: {
    open({ state, commit }, payload) {
      // console.log( "#qrCodeScanner open", payload );

      commit('OPEN_SCANNER', payload);

      if (WNInterface.isNative) {
        WNInterface.execute('wnPermissionVideoAccess', {
          callback: WNInterface.cb((result) => {
            let { status } = result;

            if (status.toLowerCase() !== 'granted') {
              const { callback } = state;
              if (callback) {
                callback({
                  status: 'ERROR',
                  error: new Error('카메라 권한이 없습니다.')
                });
              }
              return;
            }

            WNInterface.execute('wnQRCodeScan', {
              text: translate('여기에 QR코드를 스캔하세요.'),
              callback: WNInterface.cb(async (result) => {
                // @TODO: Permission

                if (result) {
                  const { callback } = state;

                  try {
                    if (!callback) {
                      throw new Error('UNDEFINED_CALLBACK');
                    }

                    if (!result.code) {
                      // throw new Error('INVALID_CODE');
                      return;
                    }

                    const url = new URL(decodeURIComponent(result.code));

                    if (!(url.protocol === 'http:' || url.protocol === 'https:')) {
                      throw new Error('INVALID_CODE');
                    }

                    // if (url.host.indexOf('lugstay.com') === -1) {
                    //   throw new Error('INVALID_CODE');
                    // }

                    // @TODO: 티켓 코드를 스캔하는 경우도 고려 필요
                    const matches = url.href.match(/\/r\/S[0-9]{1,}/g);
                    if (!matches || matches.length === 0) {
                      throw new Error('INVALID_CODE');
                    }

                    const storeId = parseInt(matches[0].replace('/r/S', ''));
                    const output = {
                      code: `S${storeId}`,
                      store_id: storeId
                    };

                    await callback({
                      status: 'SUCCESS',
                      ...output
                    });

                    commit('CLOSE_SCANNER', {});

                    // WNInterface.execute('wnScannerClose', {});
                  } catch (e) {
                    console.error(state, e);

                    if (callback) {
                      await callback({
                        status: 'ERROR',
                        error: e
                      });
                    }
                  }
                }
              })
            });
          })
        });
      }

      // if (state.opened) {
      //   throw new Error('ALREADY_OPENNED_QR_SCANNER');
      // }

      // state.callback({
      //   status: 'OPENED'
      // });
    },

    error({ state, commit }, payload) {
      // console.log( "#qrCodeScanner error", payload );

      const { callback } = state;
      const { error } = payload;

      if (callback) {
        callback({
          status: 'ERROR',
          error: error
        });
      }
    },

    scan({ state, commit }, payload) {
      // console.log( "#qrCodeScanner scan", payload );

      return new Promise(async (resolve, reject) => {
        const { callback } = state;
        const { query } = payload;

        try {
          if (!callback) {
            throw new Error('UNDEFINED_CALLBACK');
          }

          if (!query) {
            throw new Error('INVALID_CODE');
          }

          const url = new URL(decodeURIComponent(query));

          if (!(url.protocol === 'http:' || url.protocol === 'https:')) {
            throw new Error('INVALID_CODE');
          }

          if (url.host.indexOf('lugstay.com') === -1) {
            throw new Error('INVALID_CODE');
          }

          // @TODO: 티켓 코드를 스캔하는 경우도 고려 필요
          const matches = url.href.match(/\/r\/S[0-9]{1,}/g);
          if (!matches || matches.length === 0) {
            throw new Error('INVALID_CODE');
          }

          const storeId = parseInt(matches[0].replace('/r/S', ''));
          const output = {
            code: `S${storeId}`,
            store_id: storeId
          };

          await callback({
            status: 'SUCCESS',
            ...output
          });

          resolve(output);
        } catch (e) {
          console.error(state, e);

          if (callback) {
            await callback({
              status: 'ERROR',
              error: e
            });
          }

          reject(e);
        }
      });
    },

    close({ state, commit }, payload) {
      // console.log( "#qrCodeScanner close", payload );

      const { callback } = state;

      if (callback) {
        callback({
          status: 'BEFORE_CLOSE'
        });
      }

      commit('CLOSE_SCANNER');

      if (callback) {
        callback({
          status: 'CLOSED'
        });
      }
    },

    findTicket({ state, rootState, commit }, payload) {
      // console.log( "#qrCodeScanner findTicket", payload );

      return new Promise(async (resolve, reject) => {
        try {
          const { store_id, ticket_code } = payload;
          const url = `${rootState.config.apiURL}/v2/luggage/tickets/status/available`;
          const data = await network.get(url, {
            language: rootState.lang || 'ko',
            filter: 'available',
            page: 1,
            size: 10 // @MEMO: 동시 예약건이 10개가 넘을 수도 있긴 하다...
          });

          const { items } = data;

          let isExistsTicket = false;
          let targetItem = null;

          items.forEach((item) => {
            if (item.store_id === store_id && item.ticket_code === ticket_code) {
              isExistsTicket = true;
              targetItem = item;
            }
          });

          if (!isExistsTicket) {
            throw new Error('NOT_FOUND_TICKET');
          }

          resolve(targetItem);
        } catch (e) {
          reject(e);
        }
      });
    }
  }
};

export default qrCodeScanner;
