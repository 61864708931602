<template>
  <div id='consent-terms-of-use'>
    <div class="container">
      <div class="title">
        {{ $__t("{terms-of-use-form}.title") }}
      </div>
      <div class="content">
        <ul>
          <li><i class="fas fa-circle"></i>{{ $__t("{terms-of-use-form}.content.list.1") }}</li>
          <li><i class="fas fa-circle"></i>{{ $__t("{terms-of-use-form}.content.list.2") }}</li>
        </ul>
      </div>
      <div class="content">
        <form>
          <ui-form-list>
            <ui-form-list-item>
              <input type="checkbox" id="all_terms" :true-value="true" :false-value="false" v-model="formData.all_terms.value" @change="toggleAll">
              <label for="all_terms">{{ formData.all_terms.label }}</label>
            </ui-form-list-item>
            <ui-form-list-item>
              <input type="checkbox" id="terms_of_use" :true-value="true" :false-value="false" v-model="formData.terms_of_use.value" @change="onSelect">
              <label for="terms_of_use"><strong>[{{ $__t("{terms-of-use-form}.necessary") }}]</strong> {{ $__t( formData.terms_of_use.label )}}</label>
              <button type="button" class="link-button" @click="openModal('termsOfUseModal')"><img src="/img/arrow-right@3x.png"></button>
            </ui-form-list-item>
            <ui-form-list-item>
              <input type="checkbox" id="privacy_policy" :true-value="true" :false-value="false" v-model="formData.privacy_policy.value" @change="onSelect">
              <label for="privacy_policy"><strong>[{{ $__t("{terms-of-use-form}.necessary") }}]</strong> {{ formData.privacy_policy.label }}</label>
              <button type="button" class="link-button" @click="openModal('privacyPolicyModal')"><img src="/img/arrow-right@3x.png"></button>
            </ui-form-list-item>
          </ui-form-list>
        </form>
      </div>
    </div>
    <div class="submit-button">
      <button type="button" @click.prevent="validated ? submitForm() : ''" :class="{activated: validated}">
        {{ $__t("{terms-of-use-form}.agree") }}
      </button>
    </div>

    <guide-modal ref="termsOfUseModal" id="termsOfUseModal" name="termsOfUseModal" :headerTitle="$__t('이용약관')">
      <template v-slot:body>
        <div class="content">
          <div class="button-area">
            
            <label for="radio-guest" :class="{activated: termsOfUseMode === 'guest'}">
              <input type="radio" id="radio-guest" value="guest" v-model="termsOfUseMode" :class="{activated: termsOfUseMode === 'guest'}">
              <div class="text">{{ $__t('고객') }}</div>
            </label>
            <label for="radio-service" :class="{activated: termsOfUseMode === 'service'}">
              <input type="radio" id="radio-service" value="service" v-model="termsOfUseMode" :class="{activated: termsOfUseMode === 'service'}">
              <div class="text">{{ $__t('서비스') }}</div>
            </label>
          </div>
          <template v-if="termsOfUseMode === 'guest'">
            <template v-for="( doc, i ) in formData.terms_of_use.items">
              <div v-if="i.indexOf( 'toug' ) >= 0" :key="'terms_of_use_' + i" :class="'depth-'+(i.split( '-' ).length-1)">{{ doc[ $data.lang ] }}</div>
            </template>
          </template>
          <template v-else-if="termsOfUseMode === 'service'">
            <template v-for="( doc, i ) in formData.terms_of_use.items">
              <div v-if="i.indexOf( 'tous' ) >= 0" :key="'terms_of_use_' + i" :class="'depth-'+(i.split( '-' ).length-1)">{{ doc[ $data.lang ] }}</div>
            </template>
          </template>
        </div>
      </template>
    </guide-modal>

    <guide-modal ref="privacyPolicyModal" id="privacyPolicyModal" name="privacyPolicyModal" :headerTitle="$__t('개인정보 처리방침')">
      <template v-slot:body>
        <div class="content">
          <div v-for="( doc, i ) in formData.privacy_policy.items" :key="'privacy_policy_' + i" :class="'depth-'+(i.split( '-' ).length-1)">{{ doc[ $data.lang ] }}</div>
        </div>
      </template>
    </guide-modal>
  </div>  
</template>

<script>
import TermsOfUse from "@/assets/json/terms-of-use.json";
import PrivacyPolicy from "@/assets/json/privacy-policy.json";

export default {
  data(){
    return{
      termsOfUseMode: 'guest',
      lang: this.$store.state.lang === 'ko' ? 'ko' : 'en',
      formData: {
        all_terms:{
          value: false,
          label: this.$__t('{terms-of-use-form}.agree_all')
        },
        terms_of_use:{
          value: false,
          label: this.$__t("{terms-of-use-form}.terms_of_use.label"),
          items: TermsOfUse
        },
        privacy_policy:{
          value: false,
          label: this.$__t("개인정보 처리방침"),
          items: PrivacyPolicy
        }
      }
    }
  },

  computed:{
    validated(){
      if(this.formData.terms_of_use.value === true && this.formData.privacy_policy.value === true) return true;

      return false;
    }
  },

  methods: {
    toggleAll(e){
      this.formData.terms_of_use.value = this.formData.all_terms.value ? true : false;
      this.formData.privacy_policy.value = this.formData.all_terms.value ? true : false;
    },

    onSelect(e){
      if(this.formData.terms_of_use.value === true && this.formData.privacy_policy.value === true){
        this.formData.all_terms.value = true;
      }

      if(!(this.formData.terms_of_use.value === true && this.formData.privacy_policy.value === true)){
        this.formData.all_terms.value = false;
      }
    },

    openModal(ref){
      this.$refs[ref].open(ref);
    },

    closeModal(ref){
      this.$refs[ref].close();
    },

    async submitForm(){
      try{
        await this.$store.dispatch('auth/agreeTerms');
        await this.$store.dispatch('auth/refresh');

        this.$analytics.logEvent('sign up complete', { 'provider' : this.$route.query.provider });
        this.$analytics.logTrack('track', 'CompleteRegistration', {content_name: 'UserRegisteration', value: 1, currency: 'KRW'});
        this.$emit('submit')
      }catch(e){}

    }
  }
}
</script>