<template>
  <div v-if="!disabled" v-button class="ui-button solid-button" :class="'button-color-'+color+' button-size-'+size+' button-display-'+display" @click="$emit( 'click' )">
    <slot />
  </div>
  <div v-else v-button class="ui-button solid-button" :class="'button-color-gray button-size-'+size+' button-display-'+display" style="cursor: not-allowed;">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "medium"
    },
    color: {
      type: String,
      default: "primary"
    },
    display: {
      type: String,
      default: "inline"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
