import { setDomain } from '@/helpers';
import axios from 'axios';

const mode = process.env.VUE_APP_MODE || process.env.NODE_ENV;
const content = require('./conf.d/config.' + mode + '.json');

const getUserLocale = require('get-user-locale').default;

let userLocale = getUserLocale();
let userHeaders = {};
// console.log('window.navigator.userAgent',window.navigator.userAgent);
// console.log('window.navigator.language',window.navigator.language);

if (
  window.navigator.userAgent.indexOf('Blueno') !== -1 ||
  window.navigator.userAgent.indexOf('Yeti') !== -1 ||
  window.navigator.userAgent.indexOf('Naver') !== -1
) {
  userLocale = 'ko';
}

userHeaders['Accept-Language'] = userLocale;

const userEnviornment = window.navigator.userAgent;

if (userEnviornment.indexOf('MSIE') > -1 || userEnviornment.indexOf('Trident') > -1) {
  userHeaders['Cache-Control'] = 'no-cache';
  userHeaders['Pragma'] = 'no-cache';
  userHeaders['Expires'] = -1;
}
// ie에서 response 캐싱 방지

function iOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

var _wr = function (type) {
  var orig = history[type];
  return function () {
    var rv = orig.apply(this, arguments);
    var e = new Event(type);
    e.arguments = arguments;
    window.dispatchEvent(e);
    return rv;
  };
};

(history.pushState = _wr('pushState')), (history.replaceState = _wr('replaceState'));
setDomain(content.domain);

const common = {
  mode: mode,
  ios: iOS(),
  android: /(android)/i.test(navigator.userAgent),
  isTouchDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WNEmulator|WNInterface/i.test(navigator.userAgent),
  userHeaders: userHeaders,
  userLocale: userLocale,
  trackingID: 'UA-129898837-1'
};

const info = require('./info.json');

const config = {
  ...info,
  ...content,
  ...common,
  clearAccessToken: () => {
    delete axios.defaults.headers.common['Authorization'];
    delete config.userHeaders['x-access-token'];
    localStorage.removeItem(config.accessTokenKey);
  },
  getAccessToken: () => {
    return localStorage.getItem(content.accessTokenKey);
  },
  setAccessToken: (token) => {
    localStorage.setItem(config.accessTokenKey, token);
    config.userHeaders['x-access-token'] = token;
    axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
  },
  get: (name) => {
    return content[name];
  }
};

const userAccessToken = config.getAccessToken();
if (userAccessToken) {
  config.setAccessToken(userAccessToken);
}

export default config;
