

const alert = {
  namespaced: true,
  state: {
    items: [
      // {
      //   message: "로그인 실패했습니다",
      //   status: "error"
      // }
    ]
  },
  getters: {
    GET_ITEMS: ( state ) => {
      return state.items;
    }
  },
  mutations: {
    ADD_ITEM( state, payload ){
      state.items = [];
      setTimeout(() => { state.items.push( payload ); }, 0);
    },
    REMOVE_ITEM( state, payload ){
      state.items = state.items.filter( o => o != payload );
    }
  }
}

export default alert
