<template>
  <div class="notion-renderer">
    <div ref="container" class="container" :style="style"></div>
  </div>
</template>

<script>
export default {
  name: "NotionRenderer",
  props: ["blockId"],
  data() {
    return {
      height: '100%',
    }
  },
  computed: {
    style() {
      return {
        height: this.height + 'px',
      };
    }
  },
  mounted() {
    this.$sdk.loadContainer(this.$refs.container);
    this.$sdk.on("ready", () => {
      const sdk = window['LugStay'];
      sdk.showNotion({
        block_id: this.$props.blockId,
        replace: true
      });
    });

    this.$sdk.on("change-route", (data) => {
      this.$data.height = "100%";
    });

    this.$sdk.on("resize", (data) => {
      this.$data.height = data.height;
    });
  }
}
</script>

<style lang="scss">

.notion-renderer {
    width: 100%; 
    flex: 1;
    display: flex;
    padding: 0; margin: 0;

    > .container {
      padding: 0; margin: 0;
      flex: 1;
      width: 100%; 
    }
  }
</style>