<template>
  <div
    class="v2-ui-checkbox"
    :class="{
      hover: state.hover,
      checked: model === true,
      error: error,
      disabled: disabled,
      readonly: readonly,
    }"
  >
    <label ref="hover">
      <div class="v2-ui-checkbox__body">
        <input
          ref="input"
          type="checkbox"
          v-model="model"
          :readonly="readonly"
          :disabled="disabled"
          :true-value="true"
          :false-value="false"
        />
      </div>
      <div class="v2-ui-checkbox__label">{{ label }}</div>
      <div v-if="routerLink" class="v2-ui-checkbox__icon">
        <router-link :to="routerLink.to">
          {{ routerLink.text }}
        </router-link>
      </div>
    </label>
    <div class="v2-ui-checkbox__error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: "Check",

  props: {
    value: Boolean,
    label: String,
    error: String,
    disabled: Boolean,
    readonly: Boolean,
    routerLink: {
      type: Object,
      default: {
        to: "",
        text: "",
      },
    },
  },

  data() {
    const state = { hover: false, error: false };
    return { state };
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {},

  mounted() {
    if (!this.$store.state.config.isTouchDevice) {
      this.$refs.hover.addEventListener("mouseover", (e) => (this.state.hover = true));
      this.$refs.hover.addEventListener("mouseout", (e) => (this.state.hover = false));
    }
  },
};
</script>
