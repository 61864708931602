import Vue from "vue";

Vue.filter( "distance", value => {
  if( value ){
    if( value > 1000 ){
      return ( value/1000 ).toFixed( 2 ).toString() + "km";
    }else{
      return Math.round( value ).toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").split( "." )[0]+"m";
    };
  }else{
    return 0+"m";
  }
});

Vue.filter( "number", value => {
  if( value ){
    let splitValue = value.toString().split( "." );
    let pointBefore = splitValue[0].replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if(splitValue.length == 1) return pointBefore;
    else return pointBefore+"."+splitValue[1];
  }else{
    return 0;
  }
});

Vue.filter( "indexByZero", value => {
  if( value < 10 ){
    return "0" + value;
  }else{
    return value;
  }
});

Vue.filter( "toUpperCase", value => {
  if( value ){
    return value.toUpperCase()
  }else{
    return null;
  }
});

Vue.filter( "excerpt", value => {
  if( value.length > 20 ){
    return value.slice( 0, 20 )+"...";
  }else{
    return value;
  };
});

Vue.filter( "getDaysFromDuration", value => {
  if( value.length == 2 ){
    return Math.ceil( ( value[1] - value[0] ) / 86400000 );
  }else{
    return 0;
  }
});

Vue.filter( "capitalizeFirstLetter", value => {
  if(value){
    let splitStr = value.split(' ');
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }else{
    return ""
  }
})

Vue.filter( "firstLetterToUpperCase", value => {
  if( value){
    return value.charAt(0).toUpperCase() + value.slice(1);
  }else{
    return 0;
  }
});

//
// Vue.filter( "constantParse", value => {
//   return this.$store.state.config.constant[ value ];
// });

export default {};
