import { setCookie } from '@/helpers';
import config from '@/config';

function getSource(path) {
  const baseURL = config.get('siteV2URL');
  let url = new URL(decodeURIComponent(path), baseURL);

  return url.href;
}

export default {
  data() {
    return {
      accessToken: null,
    };
  },

  mounted() {
    setTimeout(() => {
      // @MEMO: 사용하지 않는 것으로 추정하여 제거, 22.08.26 (john)
      // this.accessToken = config.getAccessToken();
      // if (this.accessToken) {
      //   setCookie('__LUGSTAY_ACCESS_TOKEN__', this.accessToken);
      // }
    }, 0);
  },

  methods: {
    openV2(src) {
      const url = getSource(src);
      window.open(url, '_self');
    },
  },
};
