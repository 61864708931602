import Vue from "vue";

const rest = {
  namespaced: true,
  state: {
    isRouterChanged: true
  },
  mutations: {
    SET_TRUE( state, payload ){
      state.isRouterChanged = true
    },
    SET_FALSE( state, payload){
      state.isRouterChanged = false
    }
  }
};

export default rest;
