

const dialog = {
  namespaced: true,
  state: {
    items: [
      // {
      //   message: "안녕하세요",
      //   true: {
      //     label: "넵",
      //     callback: () => { console.log( "YES!" ); }
      //   },
      //   false: {
      //     label: "아니오",
      //     callback: () => { console.log( "NO~" ); }
      //   }
      // }
    ]
  },
  getters: {
    GET_ITEMS: ( state ) => {
      return state.items;
    }
  },
  mutations: {
    ADD_ITEM( state, payload ){
      state.items.push( payload );
    },
    REMOVE_ITEM( state, payload ){
      state.items = state.items.filter( o => o != payload );
    },
  }
}

export default dialog
