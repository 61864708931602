<template>
  <div
    class="ui-textbox-v2"
    :class="{
      hover: state.hover,
      focus: state.focus,
      fill: state.fill,
      error: error,
      disabled: disabled,
      readonly: readonly,
    }"
  >
    <label>
        {{ label }}
        <span v-if="required" style="color:red"> * </span>
    </label>

    <div class="body">
      <template v-if="mask">
        <the-mask
          ref="input"
          type="text"
          :placeholder="placeholder"
          v-model="model"
          :readonly="readonly"
          :disabled="disabled"
          @focus="onFocus"
          @blur="onBlur"
          autocapitalize="off"
          autocomplete="off"
          :maxlength="maxlength"
          :inputmode="inputmode"
          :mask="mask"
        />
      </template>
      <template v-else>
      <input
        ref="input"
        type="text"
        :placeholder="placeholder"
        v-model="model"
        :readonly="readonly"
        :disabled="disabled"
        @focus="onFocus"
        @blur="onBlur"
        autocapitalize="off"
        autocomplete="off"
        :maxlength="maxlength"
        :inputmode="inputmode"
      />
      </template>
    </div>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'

export default {
  props: ["value", "type", "placeholder", "label", "error", "disabled", "maxlength", "readonly", "inputmode", "required", "mask"],
  data() {
    var state = { hover: false, focus: false, fill: true, error: false };
    return { state };
  },
  computed: {
    model: {
      get() {
        if (typeof this.value == "number" || this.value) {
          this.state.fill = true;
        } else {
          this.state.fill = false;
        }
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onFocus(e) {
      if (this.readonly == true) return;
      this.state.focus = true;
      this.$emit("focus", e);
    },
    onBlur(e) {
      if (this.readonly == true) return;
      this.state.focus = false;
      this.$emit("blur", e);
    },
  },

  components: {
    TheMask
  }
};
</script>
