<template>
  <div class="ui-checkbox-group" v-if="items && items.length">
    <div class="label" v-if="label">{{ label }}</div>
    <div class="body">
      <div
        class="ui-checkbox"
        :class="{
          hover: state['input-' + item.value].hover,
          checked: state['input-' + item.value].checked,
        }"
        v-for="item in items"
      >
        <label :ref="'input-' + item.value">
          <input type="checkbox" :value="item.value" v-model="model" />{{
            item.label
          }}
        </label>
      </div>
    </div>
  </div>
  <div
    class="ui-checkbox"
    :class="{ hover: state.hover, checked: state.checked, error: error }"
    v-else
  >
    <div class="body">
      <label ref="input">
        <input type="checkbox" v-model="model" />{{ label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "items", "label", "error"],
  data() {
    if (this.items && this.items.length) {
      var state = {};
      this.items.forEach((o) => {
        state["input-" + o.value] = { hover: false, checked: false };
      });
    } else {
      var state = { hover: false, checked: false };
    }
    return { state };
  },
  computed: {
    model: {
      get() {
        if (this.items && this.items.length) {
          this.items.forEach((o) => {
            this.state["input-" + o.value].checked =
              this.value.indexOf(o.value) >= 0 ? true : false;
          });
        } else {
          if (this.value == "false" || !this.value) {
            this.state.checked = false;
            if (this.value == "false") {
              this.$emit("input", false);
            }
          } else {
            this.state.checked = true;
          }
        }
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    if (this.items && this.items.length) {
      if (typeof this.value != "object" || this.value == "undefined") {
        this.$emit("input", []);
      }
      if (!this.$store.state.config.isTouchDevice) {
        this.items.forEach((o) => {
          this.$refs["input-" + o.value][0].addEventListener(
            "mouseover",
            (e) => (this.state["input-" + o.value].hover = true)
          );
          this.$refs["input-" + o.value][0].addEventListener(
            "mouseout",
            (e) => (this.state["input-" + o.value].hover = false)
          );
        });
      }
    } else {
      if (!this.$store.state.config.isTouchDevice) {
        this.$refs.input.addEventListener(
          "mouseover",
          (e) => (this.state.hover = true)
        );
        this.$refs.input.addEventListener(
          "mouseout",
          (e) => (this.state.hover = false)
        );
      }
    }
  },
};
</script>
