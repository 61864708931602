import loadScript from 'load-script';
import ConnectRenderer from './lib/ConnectRenderer';
import NotionRenderer from './lib/NotionRenderer';
import config from '@/config';

const plugin = {
  install: (app, options = {}) => {
    app.component('ConnectRenderer', ConnectRenderer);
    app.component('NotionRenderer', NotionRenderer);

    let sdkVersion = '0.3.3';

    let scriptUrl = `//connect.lugstay.com/js/lugstay.sdk.${sdkVersion}.js`;
    let sdkOptions = {
      app_key: '1f754c5a35554667a5000e2b992c1ecf',
      hide_brand: true,
    };

    if (config.mode === 'debug') {
      scriptUrl = `http://localhost:8301/js/lugstay.sdk.${sdkVersion}.js`;
      sdkOptions.service_url = 'http://localhost:8301';
    }
    else if (config.mode === 'staging') {
      scriptUrl = `https://staging.connect.lugstay.com/js/lugstay.sdk.${sdkVersion}.js`;
      sdkOptions.service_url = 'https://staging.connect.lugstay.com';
    }

    const proxySDK = {
      _container: null,
      _events: {},
      loadContainer(element) {
        this._container = element;
      },
      on(event, handler) {
        this._events[event] = handler;
      }
    };

    Object.defineProperties(app.prototype, {
      $sdk: {
        get() {
          if (!app.$sdk) {
            return proxySDK;
          }

          return app.$sdk;
        }
      }
    });

    loadScript(scriptUrl, (error, script) => {
      const SDK = window.LugStay;
      SDK.init(sdkOptions);

      if (proxySDK._container) {
        SDK.loadContainer(proxySDK._container);

        for (var event in proxySDK._events) {
          let handler = proxySDK._events[event];
          SDK.on(event, handler);
        }
      }

      app.$sdk = SDK;
    });
  }
};

export default plugin;
