import Vue from 'vue';
import utils from '@/utils';
import network from '@/modules/network';

const rest = {
  namespaced: true,
  state: {
    lists: {},
    items: {}
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data == 'object' && payload.data.id) {
        if (state.items[payload.data.id]) {
          Vue.set(state.items, payload.data.id, $.extend({}, state.items[payload.data.id], payload.data));
        } else {
          Vue.set(state.items, payload.data.id, payload.data);
        }
      }
    },
    SET_ITEMS(state, payload) {
      if (typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((item) => {
          if (state.items[item.id]) {
            Vue.set(state.items, item.id, $.extend({}, state.items[item.id], item));
          } else {
            Vue.set(state.items, item.id, item);
          }
        });
      }
    },
    UNSET_ITEM(state, payload) {
      if (payload.data.id) {
        Object.keys(state.lists).forEach((o) => {
          state.lists[o] = state.lists[o].filter((o) => o != payload.data.id);
        });
        Vue.delete(state.items, payload.data.id);
      }
    },
    SET_LIST(state, payload) {
      if (payload.list) {
        Vue.set(state.lists, payload.list, []);
      }
    },
    SET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.id) {
        if (state.lists[payload.list].indexOf(payload.data.id) < 0) {
          state.lists[payload.list].unshift(payload.data.id);
        }
      }
    },
    UNSET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.id) {
        state.lists[payload.list] = state.lists[payload.list].filter((o) => o != payload.data.id);
      }
    },
    UPDATE_ITEM(state, payload) {
      if (typeof payload.data == 'object' && payload.data.id) {
        if (state.items[payload.data.id]) {
          Object.keys(payload.data.data).forEach((o) => {
            state.items[payload.data.id][o] = payload.data.data[o];
          });
        }
      }
    },
    SET_LIST_ITEMS(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((o) => {
          if (state.lists[payload.list].indexOf(o.id) < 0) {
            state.lists[payload.list].push(o.id);
          }
        });
      }
    },
    CLEAR_LIST(state, payload) {
      if (payload.list && state.lists[payload.list]) {
        state.lists[payload.list] = [];
      }
    },
    CLEAR_ITEMS(state) {
      state.items = {};
    }
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItem = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItem = state.items[o];
          }
        });
      }
      return filteredItem;
    },
    GET_ITEMS: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS_BY_LIST: (state, getters) => (payload) => {
      // payload : { list }
      var filteredItems = [];
      if (payload && payload.list && state.lists[payload.list]) {
        filteredItems = state.lists[payload.list].map((o) => state.items[o]);
      }
      return filteredItems;
    }
  },
  actions: {
    get({ state, rootState, commit, dispatch }, payload) {
      // payload: { id, query, list }
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(
            rootState.config.apiURL + '/v2/stores/' + payload.id + '?language=' + rootState.lang,
            { params: payload.query },
            { headers: rootState.config.userHeaders }
          )
          .then((res) => {
            let { data, error } = res.data;

            if (error) {
              reject(new Error(error));
              return;
            }

            commit('SET_ITEM', { data: data });

            if (payload.list) {
              if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
              commit('SET_LIST_ITEM', { list: payload.list, data: data });
            }

            resolve(res);
          })
          .catch(reject);
      });
    },
    getAround({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        var queryString = '?';

        queryString += 'language=' + rootState.lang;
        network
          .get(rootState.config.apiURL + '/v2/stores/search/around'+ queryString, payload.query, payload.options)
          .then((data) => {
            // if(payload.clear){
            //   commit("CLEAR_LIST", { list: payload.list });
            // }
            commit('SET_ITEMS', { data: data.items });

            if (payload.list) {
              if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
              commit(payload.id ? 'SET_LIST_ITEM' : 'SET_LIST_ITEMS', { list: payload.list, data: data.items });
            }
            resolve(data);
          })
          .catch(reject);
      });
    },
    async getInterest({ state, rootState, commit, dispatch }, payload) {
      try {
        let res = await Vue.axios.get(rootState.config.apiURL + `/v2/stores/${payload.id}/interest`, {
          headers: rootState.config.userHeaders
        });

        let { error, data } = res.data;

        if (error) {
          return error;
        }

        if (data.interested === true) {
          if (payload.list) {
            if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
            commit('SET_LIST_ITEM', { list: payload.list, data: { id: payload.id } });
          }
        }

        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    async addInterest({ state, rootState, commit, dispatch }, payload) {
      try {
        let res = await Vue.axios.post(rootState.config.apiURL + `/v2/stores/${payload.id}/interest`, payload, {
          headers: rootState.config.userHeaders
        });

        let { error, data } = res.data;

        if (error) {
          return error;
        }

        if (payload.list) {
          if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
          commit('SET_LIST_ITEM', { list: payload.list, data: { id: payload.id } });
        }

        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    async deleteInterest({ state, rootState, commit, dispatch }, payload) {
      try {
        let res = await Vue.axios.delete(rootState.config.apiURL + `/v2/stores/${payload.id}/interest`, {
          headers: rootState.config.userHeaders
        });

        let { error, data } = res.data;

        if (error) {
          return error;
        }

        if (payload.list) {
          if (state.lists[payload.list]) commit('UNSET_LIST_ITEM', { list: payload.list, data: { id: payload.id } });
        }

        return data;
      } catch (e) {
        throw new Error(e);
      }
    },
    getInterestList({ state, rootState, commit, dispatch }, payload) {
      // payload: { id, query, list }
      return new Promise((resolve, reject) => {
        var queryString = '?';
        if (payload.query) {
          Object.keys(payload.query).forEach((o) => {
            if (payload.query[o] || payload.query[o] === 0) queryString += o + '=' + payload.query[o] + '&';
          });
        }
        Vue.axios
          .get(rootState.config.apiURL + '/v2/stores/interest/list' + queryString, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEMS', { data: res.data.data.items });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEMS', { list: payload.list, data: res.data.data.items });
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    getListForHost({ state, rootState, commit, dispatch }, payload) {
      // payload: { id, query, list }

      return new Promise((resolve, reject) => {
        let queryString = '?page=1&size=20&language=' + rootState.lang + '&';
        if (payload.query) {
          Object.keys(payload.query).forEach((o) => {
            if (payload.query[o] || payload.query[o] === 0) queryString += o + '=' + payload.query[o] + '&';
          });
        }
        Vue.axios
          .get(rootState.config.apiURL + '/v2/manager/stores' + queryString, { headers: rootState.config.userHeaders })
          .then((res) => {
            // console.log('getListForHost res', res);
            if (res.data.status == 'success') {
              commit('SET_ITEMS', { data: res.data.data.items });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEMS', { list: payload.list, data: res.data.data.items });
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    post({ state, rootState, commit, dispatch }, payload) {
      // payload: { data }
      return new Promise((resolve, reject) => {
        dispatch('formAdditionalAction', payload.data).then((payloadData) => {
          Vue.axios
            .post(rootState.config.apiURL + '/v2/manager/stores', payloadData, {
              headers: rootState.config.userHeaders
            })
            .then((res) => {
              if (res.data.status == 'success') {
                commit('SET_ITEM', { data: res.data.data });
                if (payload.list) {
                  commit('SET_LIST_ITEM', { list: payload.list, data: res.data.data });
                }
                resolve(res);
              } else {
                reject(res);
              }
            })
            .catch(reject);
        });
      });
    },
    put({ state, rootState, commit, dispatch }, payload) {
      // payload: { id, data }
      return new Promise((resolve, reject) => {
        dispatch('formAdditionalAction', payload.data).then((payloadData) => {
          Vue.axios
            .put(rootState.config.apiURL + '/v2/manager/stores/' + payload.id + '?language=' + rootState.lang, payloadData, {
              headers: rootState.config.userHeaders
            })
            .then((res) => {
              if (res.data.status == 'success') {
                commit('SET_ITEM', { data: res.data.data });
                resolve(res);
              } else {
                reject(res);
              }
            })
            .catch(reject);
        });
      });
    },
    recommend({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + '/v2/stores/suggest', payload, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    formAdditionalAction({ state }, payload) {
      return new Promise((resolve, reject) => {
        var time_table_value = utils.parseJSON(payload.time_table);
        Object.keys(time_table_value).forEach((o) => {
          if (parseInt(time_table_value[o][0]) > parseInt(time_table_value[o][1])) {
            time_table_value[o][1] = (parseInt(time_table_value[o][1]) + 24 < 10 ? '0' : '') + (parseInt(time_table_value[o][1]) + 24) + ':00';
          }
        });

        if (!payload.time_24hour) {
          payload.time_table = JSON.stringify(time_table_value);
        } else {
          payload.time_table = JSON.stringify({
            SA_FRI: ['00:00', '24:00'],
            SA_HOL: ['00:00', '24:00'],
            SA_MON: ['00:00', '24:00'],
            SA_SAT: ['00:00', '24:00'],
            SA_SUN: ['00:00', '24:00'],
            SA_THU: ['00:00', '24:00'],
            SA_TUE: ['00:00', '24:00'],
            SA_WED: ['00:00', '24:00']
          });
        }

        // disabled_days

        var extra_value = {};
        // extra_value.store_managers = payload.store_managers.split( "," ).map( o => o.trim() ).filter( o => o );
        extra_value.store_directions = payload.store_directions;
        extra_value.district_main = payload.district_main;
        extra_value.district_sub = payload.district_sub;
        extra_value.time_24hour = payload.time_24hour;
        // extra_value.store_disabled_days = utils.parseJSON( payload.store_disabled_days );
        payload.extra = JSON.stringify(extra_value);
        // Vue.delete( payload, "store_managers" );
        Vue.delete(payload, 'store_directions');
        Vue.delete(payload, 'district_main');
        Vue.delete(payload, 'district_sub');
        Vue.delete(payload, 'time_24hour');
        // Vue.delete( payload, "store_disabled_days" );

        // resource
        payload.resources = {};
        payload.resources.thumbnail_image = utils.parseJSON(payload.thumbnail_image);
        payload.resources.detail_images = utils.parseJSON(payload.detail_images);
        payload.resources.directions_images = utils.parseJSON(payload.directions_images);
        payload.resources = JSON.stringify(payload.resources);
        Vue.delete(payload, 'thumbnail_image');
        Vue.delete(payload, 'detail_images');

        resolve(payload);
      });
    },
    predictPrice({ state, rootState, commit, dispatch }, payload) {
      const type = payload.type ? payload.type.toLowerCase() : 'stores';
      let queryString = '';
      if (payload.query) {
        queryString = '?';
        Object.keys(payload.query).forEach((o) => {
          if (payload.query[o] || payload.query[o] === 0) queryString += o + '=' + payload.query[o] + '&';
        });
      }

      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + `/v2/${type}/${payload.id}/predictPrice${queryString}`, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          });
      });
    },
    clear({ commit }, payload) {
      commit('CLEAR_LIST', { list: payload.list });
      commit('CLEAR_ITEMS');
    }
  }
};

export default rest;
