<template>
  <div class="includes form" id="close-form">
    <div class="form-header">
      <div class="title">{{ $__t("예약 불가 날짜 신청") }}</div>
      <div classs="description">{{ $__t("긴급한 일이 생기셨나요?") }}</div>
    </div>
    <div class="form-body">
      <ui-form @submit="submitForm" :formData="formData" ref="form">
        <ui-form-list>
          <!--달력-->
          <ui-form-list-item>
            <ui-duration-picker
              v-model="formData.duration.value"
              :min="formData.duration.min()"
            />
          </ui-form-list-item>
          <!--예약불가날짜 신청하기-->
          <ui-form-list-item>
            <ui-solid-button display="block"
              ><button type="submit">
                <i class="fa fa-pencil-alt"></i
                >{{ $__t("예약 불가 날짜 신청하기") }}
              </button></ui-solid-button
            >
          </ui-form-list-item>
        </ui-form-list>
      </ui-form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["payload"],
  data() {
    var formData = {
      id: {
        value: this.payload.id,
      },
      duration: {
        value: [
          this.$moment().startOf("day"),
          this.$moment().startOf("day").add(1, "days"),
        ],
        min: () => {
          return this.$moment().startOf("day");
        },
      },
    };
    return {
      formData,
    };
  },
  methods: {
    submitForm(values) {
      var data = {
        id: values.id,
        start_date: this.formData.duration.value[0].format("YYYY-MM-DD"),
        end_date: this.formData.duration.value[1].format("YYYY-MM-DD"),
        list: "closeDates",
      };
      this.$store
        .dispatch("resourceCloseDates/post", data)
        .then(() => {
          this.$store.commit("modal/REMOVE_ITEM", { component: "CloseForm" });
          this.$store.commit("alert/ADD_ITEM", {
            message: "예약 불가 날짜 설정이 완료되었습니다.",
            status: "success",
          });
        })
        .catch((err) => {
          this.$store.commit("alert/ADD_ITEM", {
            message: err,
            status: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
#close-form {
  background-color: #fff;
}
</style>