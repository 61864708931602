import Vue from 'vue';
import network from '@/modules/network';
import { query } from 'vue-analytics';

const rest = {
  namespaced: true,
  state: {
    lists: {},
    items: {}
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data == 'object' && (payload.data?.order_id || payload.data.reserve_id)) {
        if (state.items[payload.data?.order_id || payload.data.reserve_id]) {
          Vue.set(
            state.items,
            payload.data?.order_id || payload.data.reserve_id,
            $.extend({}, state.items[payload.data?.order_id || payload.data.reserve_id], payload.data)
          );
        } else {
          Vue.set(state.items, payload.data?.order_id || payload.data.reserve_id, payload.data);
        }
      }
    },
    SET_ITEMS(state, payload) {
      if (typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((item) => {
          if (state.items[item.order_id]) {
            Vue.set(state.items, item.order_id, $.extend({}, state.items[item.order_id], item));
          } else {
            Vue.set(state.items, item.order_id, item);
          }
        });
      }
    },
    UNSET_ITEM(state, payload) {
      if (payload.data.order_id) {
        Object.keys(state.lists).forEach((o) => {
          state.lists[o] = state.lists[o].filter((o) => o != payload.data.order_id);
        });
        Vue.delete(state.items, payload.data.order_id);
      }
    },
    SET_LIST(state, payload) {
      if (payload.list) {
        Vue.set(state.lists, payload.list, []);
      }
    },
    SET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.order_id) {
        if (state.lists[payload.list].indexOf(payload.data.order_id) < 0) {
          state.lists[payload.list].unshift(payload.data.order_id);
        }
      }
    },
    SET_LIST_ITEMS(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((o) => {
          if (state.lists[payload.list].indexOf(o.order_id) < 0) {
            state.lists[payload.list].push(o.order_id);
          }
        });
      }
    },
    CLEAR_LIST(state, payload) {
      if (payload.list && state.lists[payload.list]) {
        state.lists[payload.list] = [];
      }
    }
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems = state.items[o];
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS_BY_LIST: (state, getters) => (payload) => {
      // payload : { list }
      var filteredItems = [];
      if (payload && payload.list && state.lists[payload.list]) {
        filteredItems = state.lists[payload.list].map((o) => state.items[o]);
      }
      return filteredItems;
    }
  },
  actions: {
    getCoupon({ state, commit, dispatch, rootState }, payload) {
      var queryString = '?';
      if (payload.query) {
        Object.keys(payload.query).forEach((o) => {
          if (payload.query[o] || payload.query[o] === 0) queryString += o + '=' + payload.query[o] + '&';
        });
      }
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/promotion/' + payload.couponCode + queryString, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    get({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v1/luggage/order/' + payload.id, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEM', { data: res.data.data });
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    new({ state, commit, dispatch, rootState }, payload) {
      const type = payload.type ? payload.type.toLowerCase() : 'luggage';
      const path = `/v2/${type}/reserve/new`;
      const queryString = '?language=' + rootState.lang;

      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + path + queryString, payload.data, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    reserve({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/reserve/' + payload.id, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit('SET_ITEM', { data: data });
            resolve(data);
          })
          .catch(reject);
      });
    },
    share({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + '/v2/luggage/receipts/' + payload.receipt_id + '/shares', payload, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    getShare({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/receipts/' + payload + '/shares?page=1&size=20', { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    purchase({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + '/v2/luggage/reserve/' + payload.id + '/purchase', payload.data, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    payment({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v1/luggage/reserve/' + payload.id, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    impRequest({ state, commit, dispatch, rootState }, payload) {},
    complete({ state, commit, dispatch, rootState }, payload) {
      const type = payload.type ? payload.type.toLowerCase() : 'luggage';

      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + `/v2/${type}/reserve/` + payload.id + '/complete?language=' + rootState.lang, payload.data, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            resolve(data);
          })
          .catch(reject);
      });
    },
    order({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/orders/' + payload.id, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit('SET_ITEM', { data: data });
            resolve(data);
          })
          .catch(reject);
      });
    },
    getTickets({ state, commit, dispatch, rootState }, payload) {
      var queryString = '';
      if (payload.query) {
        queryString = '?';
        Object.keys(payload.query).forEach((o) => {
          if (payload.query[o] || payload.query[o] === 0) queryString += o + '=' + payload.query[o] + '&';
        });
      }
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/orders/' + payload.id + '/tickets' + queryString, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            resolve(data);
          })
          .catch(reject);
      });
    },
    getLatestOrder({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/orders/latest', { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            resolve(data);
          })
          .catch(reject);
      });
    },
    cancel({ state, commit, dispatch, rootState }, payload) {
      // payload: { id, query }
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + '/v2/luggage/orders/' + payload.id + '/cancel?language=' + rootState.lang, payload, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              Vue.axios
                .get(rootState.config.apiURL + '/v2/luggage/orders/' + payload.id + '/tickets?page=1&size=10', {
                  headers: rootState.config.userHeaders
                })
                .then((res) => {
                  if (res.data.status == 'success') {
                    resolve(res);
                  } else {
                    reject(res);
                  }
                })
                .catch(reject);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    check({ state, commit, dispatch, rootState }, payload) {
      var queryString = '';
      if (payload.query) {
        queryString = '?';
        Object.keys(payload.query).forEach((o) => {
          if (payload.query[o] || payload.query[o] === 0) queryString += o + '=' + payload.query[o] + '&'});
      }
      queryString = queryString + 'language='+rootState.lang;

      // console.log('check payload', payload);
      // console.log('queryString', queryString);
      const type = payload.type ? payload.type.toLowerCase() : 'luggage';

      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + `/v2/${type}/reserve/check` + queryString, { headers: rootState.config.userHeaders })
          .then((res) => {
            // console.log('res', res);
            let { data, error } = res.data;
            if (error) {
              reject(error);
              return;
            }
            resolve(data);
          })
          .catch((error) => {
            reject(error.response.data.error);
          });
      });
    },
    update({ state, commit, dispatch, rootState }, payload) {
      const type = payload.type ? payload.type.toLowerCase() : 'luggage';

      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + `/v2/${type}/reserve/` + payload.id + '/update', payload, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit('SET_ITEM', { data: data });
            resolve(data);
          })
          .catch(reject);
      });
    },
    change({ state, commit, dispatch, rootState }, payload) {
      const type = payload.type ? payload.type.toLowerCase() : 'luggage';

      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + `/v2/${type}/reserve/` + payload.id + '/change', payload, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit('SET_ITEM', { data: data });
            resolve(data);
          })
          .catch(reject);
      });
    },
    after({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        network
          .post(rootState.config.apiURL + `/v2/luggage/reserve/${payload.id}/after/ticket`, payload.data, payload.options)
          .then(resolve)
          .catch(reject);
      });
    },

    purchaseNew({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .post(rootState.config.apiURL + '/v2/products/' + payload.id + '/purchase/new', payload)
          .then(resolve)
          .catch(reject);
      });
    },

    purchaseUpdate({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .put(rootState.config.apiURL + '/v2/products/purchase/' + payload.id + '/update', payload)
          .then(resolve)
          .catch(reject);
      });
    },

    purchaseComplete({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .post(rootState.config.apiURL + '/v2/products/purchase/' + payload.id + '/complete', payload)
          .then(resolve)
          .catch(reject);
      });
    }
  }
};

export default rest;
