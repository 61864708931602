const _ = require('lodash');
const { v4: uuid } = require('uuid');
const moment = require('moment');
class Utils {
  get isMobile() {
    return require('is-mobile')();
  }

  uuid() {
    return uuid().replace(/-/gi, '');
  }

  parseJSON(jsonString, defaultValue = {}) {
    if (jsonString === 'null' || jsonString === null || typeof jsonString === 'undefined') {
      return defaultValue;
    }

    var result = jsonString && _.isObject(jsonString) ? jsonString : defaultValue;
    if (_.isString(jsonString)) {
      try {
        result = JSON.parse(jsonString || '{}');
      } catch (e) {
        // console.error("parseJSON Error: ", e, jsonString);
        result = defaultValue;
      }
    }
    return result;
  }

  getLocale(str, language = 'base') {
    if (typeof str === 'string' && str) {
      let json = this.parseJSON(str, { base: str });
      let value = json[language] || json['base'];

      if (!value) {
        let keys = _.keys(json);
        if (keys.length > 0) {
          return json[keys[0]];
        }
      }

      return value;
    }

    return str;
  }

  numberFormat(num) {
    if (num) {
      var regexp = /\B(?=(\d{3})+(?!\d))/g;
      return num.toString().replace(regexp, ',');
    }
    return '0';
  }

  parsePopupOptions(data) {
    let popupOptions = [];
    for (var key in data) {
      let value = data[key];
      if (typeof value === 'boolean') {
        let flag = value === 'true' ? 'yes' : 'no';
        popupOptions.push(`${key}=${flag}`);
      } else {
        popupOptions.push(`${key}=${value}`);
      }
    }
    return popupOptions.join(',');
  }

  removeURLParameter(url, parameter) {
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
      var prefix = encodeURIComponent(parameter) + '=';
      var pars = urlparts[1].split(/[&;]/g);

      for (var i = pars.length; i-- > 0; ) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }

      return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  isDescendant(el, parentId) {
    let isChild = false;
    if (el.id === parentId) {
      isChild = true;
    }

    while ((el = el.parentNode)) {
      if (el.id == parentId) {
        isChild = true;
      }
    }

    return isChild;
  }

  getClosestValidDateTime(datetime = new Date()) {
    let _moment = moment(datetime);
    if (moment(datetime).isBefore(moment(new Date()))) {
      _moment = moment(new Date());
    }

    const momentLeast = _moment.subtract(_moment.minute() % 10, 'm');
    return momentLeast.format('YYYY-MM-DD HH:mm');
  }

  getDefaultBookingDateTime(datetime = new Date()) {
    let momentDateString = moment(datetime).add(10, 'm');

    if (momentDateString.isBefore(moment())) momentDateString = moment();

    const surplusMinutes = momentDateString.minute() % 10;
    const remainMinutes = 10 - surplusMinutes;

    let dateTimeString = momentDateString.format('YYYY-MM-DD HH:mm');
    dateTimeString = moment(momentDateString).add(remainMinutes, 'minutes').format('YYYY-MM-DD HH:mm');

    return dateTimeString;
  }

  sortTicketList(list = []) {
    // console.log('sortTicketList');
    // console.log('list!!', list);

    let result = [];
    let issue_checkin = [];
    let checkout = [];
    let cancelled = [];
    let expired = [];

    // console.log('sortTicketList list', list);

    list.forEach((i) => {
      // console.log('i', i);
      if (i?.status === 'ISSUED' || i?.status === 'CHECK_IN') {
        issue_checkin.push(i);
      }
    });
    list.forEach((i) => {
      if (i?.status === 'CHECK_OUT') {
        checkout.push(i);
      }
    });

    list.forEach((i) => {
      if (i?.status === 'CANCELLED') {
        cancelled.push(i);
      }
    });
    list.forEach((i) => {
      if (i?.status === 'EXPIRED') {
        expired.push(i);
      }
    });
    // return list;
    // console.log('issue_checkin', issue_checkin);
    result = [...issue_checkin, ...expired, ...checkout, ...cancelled];
    return result;

    //here
  }

  capitalizeEveryFirstLetter() {
    if (value) {
      let splitStr = value.split(' ');
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    } else {
      return '';
    }
  }

  generateQueryString(options = {}) {
    let queryString = '?';

    Object.keys(options).forEach((o) => {
      if (options[o] || options[o] === 0) queryString += o + '=' + options[o] + '&';
    });

    return queryString;
  }

  isBeforeThan(time1, time2 = new Date()) {
    if (
      (typeof time1.getMonth === 'function' || moment.isMoment(time1) || typeof time1 === 'string') &&
      (moment.isMoment(time2) || typeof time2 === 'string' || typeof time2.getMonth === 'function')
    ) {
      return moment(time1).isBefore(time2);
    }

    return false;
  }

  wait(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  isUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return !!url.protocol;
  }

  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }
}
const plugin = {
  install: (app, options = {}) => {
    app.$utils = new Utils();

    Object.defineProperties(app.prototype, {
      $utils: {
        get() {
          return app.$utils;
        }
      }
    });
  }
};

export { plugin };
export default new Utils();
