<template>
  <div class="include" id="share-form">
    <div class="social-area">
      <ul>
        <li @click="executeShare( 'naver' )">
          <div class="icon"><img src="/img/share-icon-naver-blog.png" /></div>
          <div class="label">NAVER BLOG</div>
        </li>
        <li @click="executeShare( 'line' )">
          <div class="icon"><img src="/img/share-icon-line.png" /></div>
          <div class="label">LINE</div>
        </li>
        <li @click="executeShare( 'kakao' )" style="display:none;">
          <div class="icon"><img src="/img/share-icon-kakaotalk.png" /></div>
          <div class="label">KAKAOTALK</div>
        </li>
        <li @click="executeShare( 'facebook' )">
          <div class="icon"><img src="/img/share-icon-facebook.png" /></div>
          <div class="label">FACEBOOK</div>
        </li>
      </ul>
    </div>
    <div class="copy-area" data-clipboard-target="#copy-box">
      <input type="text" id="copy-box" v-model="payload.url" readonly />
      <ui-solid-button color="primary" size="extra-small"><button type="button">{{ $__t( "복사" ) }}</button></ui-solid-button>
    </div>
  </div>
</template>
<script>
export default {
  props: [ "payload" ],
  data(){
    var clipboard = null;
    return {
      clipboard
    };
  },
  methods: {
    executeShare( method ){
      var url = encodeURI( encodeURIComponent( this.payload.url ) );
      var title = "TEST";
      // encodeURI(myform.title.value);
      switch( method ){
        case "naver" :
          var shareURL = "http://blog.naver.com/openapi/share?url=" + this.payload.url + "&title=" + title;
          break;
        case "line" :
          var shareURL = "http://line.me/R/msg/text?" + this.payload.url;
          break;
        case "kakao" :

          break;
        case "facebook" :
          var shareURL = "https://www.facebook.com/sharer/sharer.php?u=" + this.payload.url;
          break;
      };
      if(this.wni.isNative){
        this.wni.execute('wnOpenURL', {url : shareURL});
      }else{
        window.open( shareURL );
      }
    },
  }
}
</script>
