<template>
  <div id="card-form">
    <ui-form @submit="submitForm" :formData="formData" ref="registerCardform">
      <ui-form-list>
        <div class="creditCardForm">
          <div class="country-code">
            <div class="pay-by">pay by</div>
            <ui-select
              v-model="formData.user_iso_code.value"
              :items="formData.user_iso_code.items"
              :error="formData.user_iso_code.error"
              :placeholder="
                $__t(
                  'Please select the country where your payment method was issued.'
                )
              "
            />
          </div>
          <lug-text-input
            v-model="formData.card.value"
            :error="formData.card.error"
            :placeholder="'0000-0000-0000-0000'"
            :type="'card'"
            :label="$__t('Card Number')"
          />
          <div class="multipleItem">
            <lug-text-input
              v-model="formData.expDate.value"
              :error="formData.expDate.error"
              :placeholder="'MMYY'"
              :type="'expDate'"
              :label="$__t('Expiration Date')"
            />
            <lug-text-input
              v-model="formData.cvc.value"
              :error="formData.cvc.error"
              :placeholder="
                $__t('Final three digits on the reverse of the card')
              "
              :type="'cvc'"
              :label="'CVC'"
            />
          </div>
          <div
            class="multipleItem"
            v-if="formData.user_iso_code.value === 'KR'"
          >
            <lug-text-input
              v-model="formData.birthDate.value"
              :error="formData.birthDate.error"
              :placeholder="'YYMMDD'"
              :type="'birthDate'"
              :label="$__t('Day of Birth')"
            />
            <lug-text-input
              v-model="formData.cardPass.value"
              :error="formData.cardPass.error"
              :placeholder="$__t('Beginning two digits of your password')"
              :type="'cardPass'"
              :label="$__t('Card Password')"
            />
          </div>
        </div>
      </ui-form-list>
    </ui-form>
    <div v-if="formData.user_iso_code.value !== 'KR'" class="guide">
      <ul>
        <li class="body-title">{{ $__t("[Select Country Guide]") }}</li>
        <li class="dot">
          {{
            $__t(
              "When choosing other countries than Korea, 100 won will be charged for validity of the card but returned immediately."
            )
          }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import countryCodes from "@/assets/json/country-codes.json";
import config from "@/config";

export default {
  name: "CardForm",
  data() {
    let majorCountryCodes = [
      "CA",
      "US",
      "GB",
      "FR",
      "DE",
      "CN",
      "RU",
      "MN",
      "JP",
      "TW",
      "PH",
      "HK",
      "TH",
      "VN",
      "IN",
      "MY",
      "SG",
      "AU",
      "ID",
      "KR",
    ];
    var _code = countryCodes
      .map((o) => {
        return { name: o.name, code: o.code };
      })
      .filter((o) => {
        return majorCountryCodes.includes(o.code);
      });
    _code.sort((a, b) => {
      return a.code.toUpperCase() < b.code.toUpperCase()
        ? -1
        : a.code.toUpperCase() > b.code.toUpperCase()
        ? 1
        : 0;
    });
    _code.forEach((o) => {
      if (o.name === "Korea, Republic of") {
        _code.splice(_code.indexOf(o), 1);
        _code.unshift(o);
      }

      if (o.name === "Others") {
        _code.splice(_code.indexOf(o), 1);
        _code.push(o);
      }
    });

    _code.push({ name: "Others", code: "ETC" });

    return {
      formData: {
        card: {
          value: "",
          validation: [{ type: "required" }],
          label: "",
          error: "",
        },
        expDate: {
          value: "",
          validation: [{ type: "required" }],
          label: "",
          error: "",
        },
        cvc: {
          value: "",
          validation: [{ type: "required" }],
          label: "",
          error: "",
        },
        birthDate: {
          value: "",
          validation: [{ type: "required" }],
          label: "",
          error: "",
        },
        cardPass: {
          value: "",
          validation: [{ type: "required" }],
          label: "",
          error: "",
        },
        user_iso_code: {
          value: "",
          items: _code.map((o) => {
            return { label: o.code + " (" + o.name + ")", value: o.code };
          }),
          validation: [{ type: "required" }],
          label: "",
          error: "",
        },
      },
    };
  },
  created() {
    let auth = this.$store.state.auth;

    if (auth.user_iso_code && auth.user_dial_code) {
      this.formData.user_iso_code.value = auth.user_iso_code;
    } else if (config.userLocale.indexOf("ko") !== -1) {
      this.formData.user_iso_code.value = "KR";
    }
  },
  updated() {
    this.initializeEximbayFormdata();
  },
  methods: {
    submitForm(values) {
      this.$emit("serialized", values);
    },
    resetFormData() {
      Object.keys(this.formData).forEach((o) => {
        {
          this.formData[o].value = "";
          this.formData[o].error = "";
        }
      });
    },
    initializeEximbayFormdata() {
      if (this.formData.user_iso_code.value === "KR") {
        this.formData.birthDate.validation = [{ type: "required" }];
        this.formData.cardPass.validation = [{ type: "required" }];
      } else {
        this.formData.birthDate.validation = "";
        this.formData.cardPass.validation = "";
        this.formData.birthDate.error = "";
        this.formData.cardPass.error = "";
        this.formData.birthDate.value = "";
        this.formData.cardPass.value = "";
      }
    },
  },
};
</script>
