const modal = {
  namespaced: true,
  state: {
    items: [
      // {
      //   component: "AuthLogin",
      //   size: "small || big"
      // }
    ]
  },
  getters: {
    GET_ITEMS: (state) => {
      return state.items;
    },
    GET_LAST_ITEM: (state, getters) => () => {
      if (state.items.length < 1) return null;

      return state.items[state.items.length - 1];
    }
  },
  mutations: {
    ADD_ITEM(state, payload) {
      state.items.push(payload);
    },
    REMOVE_ITEM(state, payload) {
      state.items = state.items.filter((o) => o.component != payload.component);
    }
  }
};

export default modal;
