// FORM ELEMENT
import Form from '@/components/modules/RichForm/Body';
import FormList from '@/components/modules/RichForm/List';
import FormListItem from '@/components/modules/RichForm/Item';
import FormListMultipleItem from '@/components/modules/RichForm/MultipleItem';
import Textbox from '@/components/modules/RichForm/Textbox';
import Textarea from '@/components/modules/RichForm/Textarea';
import Checkbox from '@/components/modules/RichForm/Checkbox';
import Radio from '@/components/modules/RichForm/Radio';
import SearchSelect from '@/components/modules/RichForm/SearchSelect';
import Select from '@/components/modules/RichForm/Select';
import DayPicker from '@/components/modules/RichForm/DayPicker';
import DurationPicker from '@/components/modules/RichForm/DurationPicker';
import AutoComplete from '@/components/modules/RichForm/AutoComplete';
import Spinner from '@/components/modules/RichForm/Spinner';

// 커스텀
import QuantitySelect from '@/components/modules/RichForm/QuantitySelect';
import CouponApplier from '@/components/modules/RichForm/CouponApplier';

//LugForm ELEMENT
import LugTextInput from '@/components/modules/LugForm/TextInput';
import LugSelect from '@/components/modules/LugForm/Select';

//v2
import V2CouponSelect from '@/components/modules/RichForm/v2/CouponSelect.vue';
import V2Select from '@/components/modules/RichForm/v2/Select.vue';
import V2Textbox from '@/components/modules/RichForm/v2/Textbox.vue';
import V2Check from '@/components/modules/RichForm/v2/Check.vue';
import ToggleCheckbox from '@/components/modules/RichForm/v2/ToggleCheckbox';
const plugin = {
  install(Vue, { loadComponent = true } = {}) {
    Vue.component('UiForm', Form);
    Vue.component('UiFormList', FormList);
    Vue.component('UiFormListItem', FormListItem);
    Vue.component('UiFormListMultipleItem', FormListMultipleItem);
    Vue.component('UiTextbox', Textbox);
    Vue.component('UiTextarea', Textarea);
    Vue.component('UiCheckbox', Checkbox);
    Vue.component('UiRadio', Radio);
    Vue.component('UiSearchSelect', SearchSelect);
    Vue.component('UiSelect', Select);
    Vue.component('UiDayPicker', DayPicker);
    Vue.component('UiDurationPicker', DurationPicker);
    Vue.component('UiAutoComplete', AutoComplete);
    Vue.component('LugSpinner', Spinner);

    Vue.component('UiQuantitySelect', QuantitySelect);
    Vue.component('UiCouponApplier', CouponApplier);

    Vue.component('LugTextInput', LugTextInput);
    Vue.component('LugSelect', LugSelect);

    Vue.component('V2CouponSelect', V2CouponSelect);
    Vue.component('V2Select', V2Select);
    Vue.component('V2Textbox', V2Textbox);
    Vue.component('V2Check', V2Check);
    Vue.component('UiToggleCheckbox', ToggleCheckbox);
  },
};

typeof window.Vue === 'function' && window.Vue.use(plugin);

export default plugin;
