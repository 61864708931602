<template>
  <div class="ui-select" :class="{ hover: state.hover, fill: state.fill, error: error, disabled: disabled }">
    <div class="label">{{ label }}</div>
    <div class="body">
      {{ displayValue ? value : displayLabel }}
      <i class="fa fa-angle-down"></i>
    </div>
    <select ref="input" v-model="model" :disabled="disabled">
      <option v-if="placeholder" value="">{{ placeholder }}</option>
      <option v-for="item in items" :value="item.value">{{ item.label }}</option>
    </select>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: [ "value", "items", "placeholder", "label", "error", "disabled", "displayValue" ],
  data(){
    var state = { hover: false, fill: true, error: false };
    return { state };
  },
  computed: {
    model: {
      get(){
        if( typeof this.value == "number" || this.value ){
          this.state.fill = true;
        }else{
          this.state.fill = false;
        };
        return this.value;
      },
      set( value ){
        this.$emit( "input", value );
        this.$emit( "select", value );
      }
    },
    displayLabel(){
      if( typeof this.value == "number" || this.value ){
        if( this.items.filter( o => o.value == this.value )[0] && this.items.filter( o => o.value == this.value )[0].label ){
          return this.items.filter( o => o.value == this.value )[0].label;
        }else{
          if(this.label){
            this.$emit( "input", "" );
            return "";
          } else {
            this.$emit( "input", this.placeholder );
            return this.placeholder;
          }
        };
      }else{
        if(this.label){
          return "";
        } else {
          return this.placeholder;
        }
      };
    }
  },
  mounted(){
    if( !this.$store.state.config.isTouchDevice ){
      this.$refs.input.addEventListener( "mouseover", e => this.state.hover = true );
      this.$refs.input.addEventListener( "mouseout", e => this.state.hover = false );
    };
  }
}
</script>
