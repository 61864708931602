<template>
  <div class="ui-coupon-applier">
    <div class="body">
      <ui-textbox
        v-model="couponCode"
        :label="label"
        :disabled="model.length > 0"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.native.enter.prevent="applyCoupon( $event )" />
    </div>
    <template v-if="model.length > 0">
      <ui-solid-button @click="cancelCoupon" size="extra-small" color="red"><button type="button">{{ $__t( "취소하기" )}}</button></ui-solid-button>
    </template>
    <template v-else>
      <ui-solid-button @click="applyCoupon" size="extra-small" color="primary"><button type="button">{{ $__t( "적용하기" ) }}</button></ui-solid-button>
    </template>
    <slot/>
  </div>
</template>

<script>
export default {
  props: [ "value", "store", "presetCode" ],
  data(){
    return {
      label: 'Discount Code',
      couponCode: this.value || "",
      couponData: false,
    };
  },
  computed: {
    model: {
      get(){
        return this.value;
      },
      set( value ){
        this.$emit( "input", value );
      },
    },
    clickedFrom(){
      if( (this.$route.name === "StoreSingle") || (this.$route.name === 'StoreReserveFormStep1')){
        return true;
      }
    }
  },
  created(){
    if( this.presetCode ){
      this.presetCoupon();
    };
  },
  methods: {
    onFocus() {
      if(this.clickedFrom) this.$analytics.tracking.call(this, 'booking_1', 'focus', 'booking_1_dicount_code_input');
      this.$data.label = 'Discount Code'
    },
    onBlur() {
      this.$data.label = 'Discount Code'
    },
    focusToInput(){
      setTimeout(() => {
        $( "input[type=text]", this.$el ).focus();
      }, 0);
    },
    applyCoupon( e ){
      if(this.clickedFrom) this.$analytics.tracking.call(this, 'booking_1', 'focus', 'booking_1_dicount_code_apply');

      if( e && e.type && e.type == "keydown" ){
        e.target.blur();
      };

      if( !this.couponCode ){
        this.$store.commit( "alert/ADD_ITEM", { message: this.$__t('Enter Your Discount Code'), status: "error" });
        return;
      }
      this.$store.dispatch( "orders/getCoupon", { couponCode: this.couponCode.toUpperCase(), query: { store_id: this.store.id} }).then( res => {
        if(res.data.data.status == "EXPIRED") {
          this.$store.commit( "alert/ADD_ITEM", { message: this.$__t("This coupon has expired."), status: "error" });
          return;
        }

        this.$store.commit( "dialog/ADD_ITEM", {
          message: this.$__t( "할인 쿠폰을 적용하시겠습니까?" ),
          true: {
            label: this.$__t( "예" ),
            callback: () => {
              this.$emit( "couponApplied", res.data.data );
            }
          },
          false: {
            label: this.$__t( "아니오" ),
            callback: () => { /* DO NOTHING.. */ }
          },
        })
      }).catch( err => {
        this.$store.commit( "alert/ADD_ITEM", { message: err, status: "error" });
      });
    },
    cancelCoupon(){
      this.couponCode = "";
      this.couponData = null;
      this.$emit( "couponApplied", false );
    },
    presetCoupon(){
      this.$store.dispatch( "orders/getCoupon", { couponCode: this.presetCode }).then( res => {
        this.couponCode = this.presetCode;
        this.$emit( "couponApplied", res.data.data );
      }).catch( err => { /* NONE */ });
    }
  }
}
</script>
