import Vue from "vue";

const rest = {
  namespaced: true,
  state: {
    maxPage: '',
    currentPage: ''
  },
  mutations: {
    SET_ITEM( state, payload ){
      if( typeof payload == "object" && payload.key && payload.value){
        if( state[payload.key] ){
          state[payload.key] = Number(payload.value)
        }else{
          Vue.set( state, payload.key, Number(payload.value) );
        }
      };
    },
    INCREMENT( state, payload ){
      if( state.currentPage < state.maxPage ){
        state.currentPage += state.currentPage
      }
    },
    DECREMENT( state, payload ){
      if( state.currentPage > 1 ){
        state.currentPage -= 1
      }
    }
  },
  getters: {
    GET_ITEM: ( state, getters ) => payload => {
      var page = null;
      if( payload && payload.key ){
       page = state[payload.key]
      };
      return page;
    }
  },
  actions: {

  }
};

export default rest;
