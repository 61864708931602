import Vue from 'vue';
import utils from '@/utils';
import network from '@/modules/network';

const STATUS = ['all', 'issued', 'cancelled', 'available', 'checkin', 'checkout', 'expired'];

const rest = {
  namespaced: true,
  state: {
    lists: {},
    items: {}
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data == 'object' && payload.data.ticket_id) {
        if (state.items[payload.data.ticket_id]) {
          Vue.set(state.items, payload.data.ticket_id, $.extend({}, state.items[payload.data.ticket_id], payload.data));
        } else {
          Vue.set(state.items, payload.data.ticket_id, payload.data);
        }
      }
    },
    SET_ITEMS(state, payload) {
      if (typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((item) => {
          if (state.items[item.ticket_id]) {
            Vue.set(state.items, item.ticket_id, $.extend({}, state.items[item.ticket_id], item));
          } else {
            Vue.set(state.items, item.ticket_id, item);
          }
        });
      }
    },
    SET_LIST(state, payload) {
      if (payload.list) {
        Vue.set(state.lists, payload.list, []);
      }
    },
    SET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.ticket_id) {
        if (state.lists[payload.list].indexOf(payload.data.ticket_id) < 0) {
          state.lists[payload.list].unshift(payload.data.ticket_id);
        }
      }
    },
    UNSET_ITEM(state, payload) {
      if (payload.data.ticket_id) {
        Object.keys(state.lists).forEach((o) => {
          state.lists[o] = state.lists[o].filter((o) => o != payload.data.ticket_id);
        });
        Vue.delete(state.items, payload.data.ticket_id);
      }
    },
    UNSET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.ticket_id) {
        state.lists[payload.list] = state.lists[payload.list].filter((o) => o != payload.data.id);
      }
    },
    SET_LIST_ITEMS(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((o) => {
          if (state.lists[payload.list].indexOf(o.ticket_id) < 0) {
            state.lists[payload.list].push(o.ticket_id);
          }
        });
      }
    },
    CLEAR_LIST(state, payload) {
      if (payload.list && state.lists[payload.list]) {
        state.lists[payload.list] = [];
      }
    },
    CLEAR_ALL_LIST(state) {
      state.lists = {};
    },
    CLEAR_ITEMS(state) {
      state.items = {};
    }
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems = state.items[o];
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }

      if (payload.sort) {
        filteredItems = utils.sortTicketList(filteredItems);
      }

      return filteredItems;
    },
    GET_ITEMS_BY_LIST: (state, getters) => (payload) => {
      // payload : { list }
      var filteredItems = [];
      if (payload && payload.list && state.lists[payload.list]) {
        filteredItems = state.lists[payload.list].map((o) => state.items[o]);
      }
      if (payload.sort) {
        filteredItems = utils.sortTicketList(filteredItems);
      }

      return filteredItems;
    }
  },
  actions: {
    get({ state, rootState, commit, dispatch }, payload) {
      let queryString = '?language=' + rootState.lang + '&';
      // payload: { id, query, list }
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/tickets/' + payload.id + queryString, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEM', { data: res.data.data });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEM', { list: payload.list, data: res.data.data });
              }
              resolve(res.data.data);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    getByCode({ state, rootState, commit, dispatch }, payload) {
      let queryString = '?language=' + rootState.lang + '&';
      // payload: { id, query, list }
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/manager/tickets/' + payload.code + queryString, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            let { data, error } = res.data;
            if (res.data.status == 'success') {
              commit('SET_ITEM', { data: res.data.data });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEM', { list: payload.list, data: res.data.data });
              }
              resolve(res);
            } else {
              reject(error);
            }
          })
          .catch(reject);
      });
    },
    getList({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.clear) {
          commit('CLEAR_LIST', { list: payload.list });
          commit('CLEAR_ITEMS');
        }

        let queryString = '?language=' + rootState.lang + '&';
        if (payload.query) {
          Object.keys(payload.query).forEach((o) => {
            if (payload.query[o] || payload.query[o] === 0) queryString += o + '=' + payload.query[o] + '&';
          });
        }
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/tickets/status/' + (payload.filter || 'all') + queryString, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEMS', { data: res.data.data.items });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEMS', {
                  list: payload.list,
                  data: payload.sort ? utils.sortTicketList(res.data.data.items) : res.data.data.items
                });
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    getListForHost({ state, rootState, commit, dispatch }, payload) {
      // payload: { id, query, list }
      return new Promise((resolve, reject) => {
        if (payload.clear) {
          commit('CLEAR_LIST', { list: payload.list });
          commit('CLEAR_ITEMS');
        }

        var queryString = '?';
        if (payload.query) {
          Object.keys(payload.query).forEach((o) => {
            if (payload.query[o] || payload.query[o] === 0) queryString += o + '=' + payload.query[o] + '&';
          });
        }
        Vue.axios
          .get(rootState.config.apiURL + '/v2/manager/tickets/status/' + (payload.filter || 'all') + queryString, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEMS', { data: res.data.data.items });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEMS', { list: payload.list, data: res.data.data.items });
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    check({ state, rootState, commit, dispatch }, payload) {},
    managerCheckIn({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + `/v2/manager/tickets/` + payload.code + '/checkin', {}, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    luggageCheckIn({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + `/v2/luggage/tickets/` + payload.code + '/checkin', {}, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    lockerCancel({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + `/v2/locker/tickets/` + payload.code + '/cancel', payload, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    //here
    lockerCheckIn({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + `/v2/locker/tickets/` + payload.code + '/checkin', {}, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    checkOut({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + '/v2/manager/tickets/' + payload.code + '/checkout', {}, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    checkoutAfter({ state, rootState, commit, dispatch }, payload) {
      const type = payload.type ? payload.type.toLowerCase() : 'luggage';

      return new Promise((resolve, reject) => {
        network
          .put(
            rootState.config.apiURL +
              `/v2/${type}` +
              (type === 'luggage' ? `/reserve/after/ticket/${payload.code}/checkout` : `/tickets/${payload.code}/checkout`),
            payload
          )
          .then(resolve)
          .catch(reject);
      });
    },
    clear({ commit }, payload) {
      commit('CLEAR_LIST', payload);
      commit('CLEAR_ITEMS');
    },
    share({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + '/v2/luggage/tickets/' + payload.code + '/shares', payload.data, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            let { data, error } = res.data;

            if (!error) {
              resolve(data);
            }

            reject(error);
          })
          .catch(reject);
      });
    },
    getShare({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/tickets/' + payload.code + '/shares?page=1&size=20', {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    shareAccess({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + '/v2/luggage/tickets/' + payload.code + '/shares/access', payload, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEM', { data: res.data.data });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEM', { list: payload.list, data: res.data.data });
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    getSharebyManager({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/manager/tickets/' + payload.code + '/shares?page=1&size=20', {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    deleteShare({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete(rootState.config.apiURL + `/v2/luggage/tickets/${payload.code}/shares/${payload.id}`, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status === 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    checkoutRequest({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(
            rootState.config.apiURL + `/v2/luggage/reserve/after/ticket/${payload.code}/checkout/request`,
            {},
            { headers: rootState.config.userHeaders }
          )
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    checkoutbyShare({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + `/v2/luggage/tickets/${payload.code}/shares/checkout`, payload.data, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEM', { data: res.data.data });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEM', { list: payload.list, data: res.data.data });
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    openLocker({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(
            rootState.config.apiURL + `/v2/locker/tickets/${payload.code}/open`,
            {},
            {
              headers: rootState.config.userHeaders
            }
          )
          .then((res) => {
            let { data, error } = res.data;

            if (error) {
              reject(error);
            }

            resolve(data);
          })
          .catch(reject);
      });
    },
    getByShare({ state, rootState, commit, dispatch }, payload) {
      let queryString = '?language=' + rootState.lang + '&';
      // payload: { id, query, list }
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/tickets/' + payload.code + '/shares/open' + queryString, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit('SET_ITEM', { data: data });
            if (payload.list) {
              if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
              commit('SET_LIST_ITEM', { list: payload.list, data: data });
            }
            resolve(data);
          })
          .catch(reject);
      });
    }
  }
};

export default rest;
