<template>
  <div id="app" class="server-down">
    <div class="page-header">
      <div class="logo" @click="onClickLogo">
        <img src="/img/icon/lugstay.svg" alt="lugstay" />
      </div>
    </div>

    <div class="page-body">
      <div class="emotion">
        <img :src="selectedCase.icon" alt="Wow!!" />
      </div>

      <div class="message">
        <p class="primary">{{ selectedCase.message }}</p>
        <p>{{ selectedCase.detail }}</p>
      </div>

      <div class="actions">
        <button type="button" class="button-retry" @click="onClickRetry">
          <span>다시 시도하기</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cases: [
        {
          icon: '/img/icon/server-down-1.svg',
          message: '앗! 서버를 깨우고 있어요.',
          detail: '다시 시작해주시면 정상적으로 접속 됩니다 :)'
        },
        {
          icon: '/img/icon/server-down-2.svg',
          message: '앗! 서버를 배송중이에요',
          detail: '다시 시작해주시면 정상적으로 접속 됩니다 :)'
        },
        {
          icon: '/img/icon/server-down-3.svg',
          message: '앗! 서버가 신호에 걸렸어요',
          detail: '다시 시작해주시면 정상적으로 접속 됩니다 :)'
        },
        {
          icon: '/img/icon/server-down-4.svg',
          message: '앗! 서버가 더 많은 공간을 찾고 있어요',
          detail: '다시 시작해주시면 정상적으로 접속 됩니다 :)'
        },
        {
          icon: '/img/icon/server-down-5.svg',
          message: '앗! 서버를 조리중이에요',
          detail: '다시 시작해주시면 정상적으로 접속 됩니다 :)'
        },
        {
          icon: '/img/icon/server-down-6.svg',
          message: '앗! 서버를 수확중이에요',
          detail: '다시 시작해주시면 정상적으로 접속 됩니다 :)'
        },
        {
          icon: '/img/icon/server-down-7.svg',
          message: '앗! 서버가 목욕중이에요',
          detail: '다시 시작해주시면 정상적으로 접속 됩니다 :)'
        },
        {
          icon: '/img/icon/server-down-8.svg',
          message: '앗! 서버가 휴가중이에요',
          detail: '다시 시작해주시면 정상적으로 접속 됩니다 :)'
        }
      ],
      selectedIndex: _.random(0, 7)
    };
  },
  computed: {
    selectedCase() {
      return this.$data.cases[this.$data.selectedIndex];
    }
  },
  methods: {
    onClickLogo(e) {
      location.href = "/";
    },

    onClickRetry(e) {
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
#app.server-down {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .page-header {
    padding: 0;
    width: 100%;
    height: unit(60);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .logo {
      margin-left: unit(20);
      cursor: pointer;

      img {
        width: unit(120);
      }
    }
  }

  .page-body {
    flex: 1;
    padding: unit(32) 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .emotion {
      margin-top: auto;

      img {
        width: unit(120);
        height: unit(120);
      }
    }

    .message {
      margin-top: unit(32);
      margin-bottom: unit(32);

      p {
        text-align: center;
        line-height: 2;

        font-weight: 400;
        font-size: unit(16);
        color: #787878;

        &.primary {
          font-size: unit(20);
          font-weight: 600;
          color: #101010;
        }
      }
    }

    .actions {
      margin-bottom: auto;
      width: 100%;
      button {
        cursor: pointer;
        width: unit(162);
        height: unit(50);
        margin: 0 auto;
        border-radius: unit(12);
        background-color: #26BBEF;
        font-weight: 600;
        font-size: unit(16);
        color: #fefefe;
      }
    }
  }
}
</style>
