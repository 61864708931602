<template>
  <div ref="button" v-if="!disabled" class="ui-button border-arrow-button" :class="classObject" @click="$emit( 'click' )">
    <slot />
    <img :src="`/img/arrow-${fontColor || color}-right.png`" class="arrow">
  </div>
  <div v-else class="ui-button border-arrow-button" :class="'button-color-disabled button-size-'+size+' button-display-'+display" style="cursor: not-allowed;">
    <slot />
    <img :src="`/img/arrow-gray-right.png`" class="arrow">
  </div>
</template>

<script>
export default {
  name: 'UiBorderArrowButton',
  data(){
    let state = { hover: false }
    return {
      state
    }
  },
  props: {
    size: {
      type: String,
      default: "medium"
    },
    color: {
      type: String,
      default: "blue"
    },
    display: {
      type: String,
      default: "inline"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fontColor: {
      type: String,
      default: ''      
    }
  },
  computed: {
    classObject(){
      return {
        [`button-color-${this.color}`] : true,
        [`button-size-${this.size}`] : true,
        [`button-display-${this.display}`] : true,
        [`button-font-color-${this.fontColor}`] : this.fontColor,
        hover : this.state.hover
      }
    }
  },
  updated(){
    this.$nextTick(() => {
      if( !this.$store.state.config.isTouchDevice ){      
        if(this.$refs.button){          
          this.$refs.button.addEventListener( "mouseover", e => this.state.hover = true );
          this.$refs.button.addEventListener( "mouseout", e => this.state.hover = false );
        }  
      };
    })
  }
}
</script>
