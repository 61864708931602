var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-textbox",class:{
    hover: _vm.state.hover,
    focus: _vm.state.focus,
    fill: _vm.state.fill,
    error: _vm.error,
    disabled: _vm.disabled,
    readonly: _vm.readonly,
  }},[_c('label',{ref:"hover"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"body"},[((_vm.type || 'text')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",attrs:{"placeholder":_vm.state.focus ? _vm.placeholder : '',"readonly":_vm.readonly,"disabled":_vm.disabled,"autocapitalize":"off","autocomplete":"off","maxlength":_vm.maxLength,"inputmode":_vm.inputmode,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}}):((_vm.type || 'text')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",attrs:{"placeholder":_vm.state.focus ? _vm.placeholder : '',"readonly":_vm.readonly,"disabled":_vm.disabled,"autocapitalize":"off","autocomplete":"off","maxlength":_vm.maxLength,"inputmode":_vm.inputmode,"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"change":function($event){_vm.model=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",attrs:{"placeholder":_vm.state.focus ? _vm.placeholder : '',"readonly":_vm.readonly,"disabled":_vm.disabled,"autocapitalize":"off","autocomplete":"off","maxlength":_vm.maxLength,"inputmode":_vm.inputmode,"type":_vm.type || 'text'},domProps:{"value":(_vm.model)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.model=$event.target.value}}})])]),(_vm.error)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }