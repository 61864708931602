import store from '@/store';

function onTouchStart(el) {
  if (el.currentTarget.disabled) {
    return;
  }

  if (el.currentTarget.classList.contains('disabled')) {
    return;
  }

  el.currentTarget.style.opacity = 0.6;
}

function onTouchEnd(el) {
  if (el.currentTarget.disabled) {
    return;
  }

  if (el.currentTarget.classList.contains('disabled')) {
    return;
  }

  el.currentTarget.style.opacity = 1;
}

const plugin = {
  install: (app, options = {}) => {
    app.directive('button', {
      inserted: function (el) {
        el.addEventListener(store.state.config.isTouchDevice ? 'touchstart' : 'mousedown', onTouchStart);
        el.addEventListener(store.state.config.isTouchDevice ? 'touchend' : 'mouseup', onTouchEnd);
      },
    });
  },
};

export default plugin;
