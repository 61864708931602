import Vue from 'vue';
import network from '@/modules/network';

const hostSettlements = {
  namespaced: true,
  state: {
    items: {},
    lists: {}
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      // payload : { key, value }
      // console.log('state', state);
      var filteredItems = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems = state.items[o];
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }

      return filteredItems;
    },
    GET_ITEMS_BY_LIST: (state, getters) => (payload) => {
      // payload : { list }
      var filteredItems = [];
      if (payload && payload.list && state.lists[payload.list]) {
        filteredItems = state.lists[payload.list].map((o) => state.items[o]);
      }

      if (payload.sort) {
        filteredItems = utils.sortTicketList(filteredItems);
      }

      return filteredItems;
    }
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data == 'object' && payload.data.uid) {
        if (state.items[payload.data.uid]) {
          Vue.set(state.items, payload.data.uid, $.extend({}, state.items[payload.data.uid], payload.data));
        } else {
          Vue.set(state.items, payload.data.uid, payload.data);
        }
      }
    },

    SET_ITEMS(state, payload) {
      if (typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((item) => {
          if (state.items[item.uid]) {
            Vue.set(state.items, item.uid, $.extend({}, state.items[item.uid], item));
          } else {
            Vue.set(state.items, item.uid, item);
          }
        });
      }
    },

    SET_LIST(state, payload) {
      if (payload.list) {
        Vue.set(state.lists, payload.list, []);
      }
    },

    SET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.uid) {
        if (state.lists[payload.list].indexOf(payload.data.uid) < 0) {
          state.lists[payload.list].unshift(payload.data.uid);
        }
      }
    },

    UNSET_ITEM(state, payload) {
      if (payload.data.uid) {
        Object.keys(state.lists).forEach((o) => {
          state.lists[o] = state.lists[o].filter((o) => o != payload.data.uid);
        });
        Vue.delete(state.items, payload.data.uid);
      }
    },

    UNSET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.uid) {
        state.lists[payload.list] = state.lists[payload.list].filter((o) => o != payload.data.uid);
      }
    },

    SET_LIST_ITEMS(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((o) => {
          if (state.lists[payload.list].indexOf(o.uid) < 0) {
            state.lists[payload.list].push(o.uid);
          }
        });
      }
    },

    CLEAR_LIST(state, payload) {
      if (payload.list && state.lists[payload.list]) {
        state.lists[payload.list] = [];
      }
    },

    CLEAR_ALL_LIST(state) {
      state.lists = {};
    },

    CLEAR_ITEMS(state) {
      state.items = {};
    }
  },

  actions: {
    dashboard({ state, rootState, commit, dispatch }, payload) {
      // console.log('dashboard', payload);
      return new Promise((resolve, reject) => {
        network
          .get(rootState.config.apiURL + `/v2/settlement/stores/profits/info`, payload)
          .then(resolve)
          .catch(reject);
      });
    },

    fetchList({ state, rootState, commit, dispatch }, payload) {
      // console.log('fetchList payload', payload);
      return new Promise((resolve, reject) => {
        network
          .get(rootState.config.apiURL + `/v2/settlement/stores/profits`, payload)
          .then((data) => {
            // console.log('fetchList res', data);
            commit('SET_ITEMS', { data: data.items });
            // console.log('state!!', state);
            if (payload.list) {
              if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
              commit('SET_LIST_ITEMS', {
                list: payload.list,
                data: data.items
              });
            }
            resolve(data);
          })
          .catch(reject);
      });
    },

    fetchOne({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .get(rootState.config.apiURL + `/v2/settlement/stores/profits/${payload.id}`, payload)
          .then((data) => {
            commit('SET_ITEM', { data: data });
            if (payload.list) {
              if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
              commit('SET_LIST_ITEM', { list: payload.list, data: data });
            }
            resolve(data);
          })
          .catch(reject);
      });
    },

    fetchListByStore({ state, rootState, commit, dispatch }, payload) {
      //storeId가 있을 경우
      return new Promise((resolve, reject) => {
        network
          .get(rootState.config.apiURL + `/v2/settlement/stores/${payload.id}/profits`, payload)
          .then((data) => {
            // console.log('data', data);
            commit('SET_ITEM', { data: data });
            if (payload.list) {
              if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
              commit('SET_LIST_ITEMS', {
                list: payload.list,
                data: data.items
              });
            }
            resolve(data);
          })
          .catch(reject);
      });
    },

    fetchReceiptBySettlement({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .get(rootState.config.apiURL + `/v2/settlement/stores/profits/${payload.id}/receipts`, payload)
          .then((data) => {
            commit('SET_ITEM', { data: data });
            if (payload.list) {
              if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
              commit('SET_LIST_ITEM', { list: payload.list, data: data });
            }
            resolve(data);
          })
          .catch(reject);
      });
    },

    fetchReceiptBySettlement({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .post(rootState.config.apiURL + `/v2/settlement/stores/${payload.id}/profits/request`, payload)
          .then(resolve)
          .catch(reject);
      });
    },

    request({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          // .put(rootState.config.apiURL + `/v2/settlement/stores/${payload.id}/profits/request`, payload)
          .put(rootState.config.apiURL + `/v2/settlement/stores/batch/profits/request`, payload)
          .then((data) => {
            commit('SET_ITEM', { data: data });
            if (payload.list) {
              if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
              commit('SET_LIST_ITEM', { list: payload.list, data: data });
            }
            resolve(data);
          })
          .catch(reject);
      });
    }
  }
};

export default hostSettlements;
