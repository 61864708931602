import Vue from 'vue';

const rest = {
  namespaced: true,
  state: {
    lists: {},
    items: {}
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data == 'object' && payload.data.uid) {
        if (state.items[payload.data.uid]) {
          Vue.set(state.items, payload.data.uid, $.extend({}, state.items[payload.data.uid], payload.data));
        } else {
          Vue.set(state.items, payload.data.uid, payload.data);
        }
      }
    },
    SET_ITEMS(state, payload) {
      if (typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((item) => {
          if (state.items[item.uid]) {
            Vue.set(state.items, item.uid, $.extend({}, state.items[item.uid], item));
          } else {
            Vue.set(state.items, item.uid, item);
          }
        });
      }
    },
    UNSET_ITEM(state, payload) {
      if (payload.data.uid) {
        Object.keys(state.lists).forEach((o) => {
          state.lists[o] = state.lists[o].filter((o) => o != payload.data.uid);
        });
        Vue.delete(state.items, payload.data.uid);
      }
    },
    SET_LIST(state, payload) {
      if (payload.list) {
        Vue.set(state.lists, payload.list, []);
      }
    },
    SET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.uid) {
        if (state.lists[payload.list].indexOf(payload.data.uid) < 0) {
          state.lists[payload.list].unshift(payload.data.uid);
        }
      }
    },
    SET_LIST_ITEMS(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((o) => {
          if (state.lists[payload.list].indexOf(o.uid) < 0) {
            state.lists[payload.list].push(o.uid);
          }
        });
      }
    },
    SET_VIEWED_LIST_ITEM(state, payload) {
      if (payload.list && typeof payload.data == 'object' && payload.data.uid) {
        if (!state.lists[payload.list]) {
          state.lists[payload.list] = [];
        }
        if (state.lists[payload.list].indexOf(payload.data.uid) < 0) {
          state.lists[payload.list].unshift(payload.data.uid);
        }

        localStorage.setItem(payload.list, state.lists[payload.list]);
      }
    },
    SET_VIEWED_LIST_ITEMS(state, payload) {
      if (payload.list && typeof payload.data == 'object' && payload.data.length) {
        if (!state.lists[payload.list]) {
          state.lists[payload.list] = [];
        }
        payload.data.forEach((o) => {
          if (state.lists[payload.list].indexOf(o.uid) < 0) {
            state.lists[payload.list].push(o.uid);
          }
        });

        localStorage.setItem(payload.list, state.lists[payload.list]);
      }
    },
    CLEAR_LIST(state, payload) {
      if (payload.list && state.lists[payload.list]) {
        state.lists[payload.list] = [];
      }
    },
    CLEAR_ITEMS(state) {
      state.items = {};
    }
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems = state.items[o];
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS: (state, getters) => (payload) => {
      // payload : { key, value }
      var filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }
      return filteredItems;
    },
    GET_ITEMS_BY_LIST: (state, getters) => (payload) => {
      // payload : { list }
      var filteredItems = [];
      if (payload && payload.list && state.lists[payload.list]) {
        filteredItems = state.lists[payload.list].map((o) => state.items[o]);
      }
      return filteredItems;
    }
  },
  actions: {
    get({ state, rootState, commit, dispatch }, payload) {
      // payload: { id, query, list }
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v1/luggage/coupon/' + payload.id, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEM', { data: res.data.data });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEM', { list: payload.list, data: res.data.data });
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },

    getList({ state, rootState, commit, dispatch }, payload) {
      // payload: { id, query, list }
      return new Promise((resolve, reject) => {
        if (payload.clear) {
          dispatch('clear', payload);
        }

        var queryString = '?language=' + rootState.lang + '&';
        if (payload.query) {
          Object.keys(payload.query).forEach((o) => {
            if (payload.query[o]) queryString += o + '=' + payload.query[o] + '&';
          });
        }
        Vue.axios
          .get(rootState.config.apiURL + '/v2/coupons' + queryString, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEMS', { data: res.data.data.items });
              if (payload.list) {
                // if (!state.lists[payload.list]) {
                commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEMS', { list: payload.list, data: res.data.data.items });
                // }
              }
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    clear({ state, rootState, commit, dispatch }, payload) {
      commit('CLEAR_LIST', { list: payload.list });
      commit('CLEAR_ITEMS');
    }
  }
};

export default rest;
