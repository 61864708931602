import Vue from "vue";

const region = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    SET_ITEMS(state, payload) {
      let items = payload.items;
      Object.keys(items).forEach((o) => {
        let name = JSON.parse(items[o].name);
        items[o].ko = name.ko ? name.ko : "";
        items[o].en = name.en ? name.en : "";
        items[o].jp = name.jp ? name.jp : "";
        items[o].cn = name.cn ? name.cn : "";
        items[o].hk = name.hk ? name.hk : "";
        delete items[o].name;

        Object.keys(items).every((p) => {
          if (items[o].parent_id && items[o].parent_id == items[p].uid) {
            if (!items[p]._children) {
              items[p]._children = [];
            }
            items[p]._children.push(items[o]);
            delete items[o];
            return false;
          } else {
            return true;
          }
        });
      });

      Object.keys(items).forEach((o) => {
        if (items[o].depth > 1) delete items[o];
        if (items[o] && items[o]._children && items[o]._children.length > 1) {
          // items[o]._children = items[o]._children.reverse();
          items[o]._children = items[o]._children.sort((a, b) => {
            return a.seq - b.seq;
          });
        }
      });
      let filteredItems = items.filter((item) => item);
      filteredItems = filteredItems.sort((a, b) => {
        return a.seq - b.seq;
      });
      Object.keys(filteredItems).forEach((o) => {
        Vue.set(state.items, o, filteredItems[o]);
      });
    },
  },
  actions: {
    list({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + "/v2/manager/recommendRegions", { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit("SET_ITEMS", data);
            resolve({ data });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default region;
