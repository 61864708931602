import config from '@/config';

import GoogleAnalytics from './lib/google.analytics';
import GoogleOptimize from './lib/google.optimize';
import GoogleTagManager from './lib/google.tag.manager';
// import FacebookPixelCode from './lib/facebook.pixel.code';
// import NaverPremiumLog from './lib/naver.premium.log';
// import Amplitude from './lib/amplitude';
// import Hotjar from './lib/hotjar';
// import Allbigdat from './lib/allbigdat';
// import KakaoPixel from './lib/kakao.pixel';

const Analytics = {
  startSession(options = {}) {
    // Amplitude.startSession(options);
    GoogleAnalytics.startSession(options);
  },

  setVersion(version) {
    // Amplitude.setVersion(version);
    GoogleAnalytics.setVersion(version);
  },

  setUserId(userId) {
    // Amplitude.setUserId(userId);
    GoogleAnalytics.setUserId(userId);
  },

  setDeviceId(userId) {
    // Amplitude.setDeviceId(userId);
    GoogleAnalytics.setDeviceId(userId);
  },

  setLanguage(language) {
    // Amplitude.setLanguage(language);
    GoogleAnalytics.setLanguage(language);
  },

  logEvent(eventName, options) {
    // Amplitude.logEvent(eventName, options);
    GoogleAnalytics.logEvent(eventName, options);
  },

  pageView() {
    KakaoPixel.pageView();
  },

  participation() {
    KakaoPixel.participation();
  },

  signUp() {
    KakaoPixel.signUp();
  },

  cnv(eventType, option) {
    NaverPremiumLog.cnv(eventType, option);
  },

  // GA 특화
  tracking(category, action, label, options) {
    /*
    const component = this;
    const eventName = `${category}_${action}`;

    GoogleAnalytics.logEvent(eventName, {
      ...label,
      event_category: component.$route.name || "",
      event_label: component.$options.name || "",
    });
    */

    GoogleAnalytics.logEvent(action, {
      event_category: category,
      event_label: label,
      ...options,
    });
  },

  //페이스북 픽셀
  logTrack(title, event, options) {
    // FacebookPixelCode.logTrack(title, event, options);
  },
  purchase(options = {}) {
    const { payment_total } = options;

    // NaverPremiumLog.purchase({
    //   payment_total,
    // });
  },
};

const plugin = {
  install: (app, options = {}) => {
    // FacebookPixelCode.init(app, config.get('facebookPixelCode'));
    GoogleAnalytics.init(app, config.get('googleAnalytics'));
    GoogleOptimize.init(app, config.get('googleOptimize'));
    GoogleTagManager.init(app, config.get('googleTagManager'));
    // NaverPremiumLog.init(app, config.get('naverPremiumLog'));
    // Amplitude.init(app, config.get('amplitude'));
    // Allbigdat.init(app, config.get('allbigdat'));
    // Hotjar.init(app, config.get('hotjar'));
    // KakaoPixel.init(app, config.get('kakaoPixel'));

    app.$analytics = Analytics;

    Object.defineProperties(app.prototype, {
      $analytics: {
        get() {
          return app.$analytics;
        },
      },
    });
  },
};

export default plugin;
