import Vue from 'vue';

const billings = {
  namespaced: true,
  state: {
    items: {},
    lists: {},
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      var filteredItem = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems = state.items[o];
          }
        });
      }
      return filteredItem;
    },
    GET_ITEMS: (state, getters) => (payload) => {
      var filteredItems = [];
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o][payload.key] == payload.value) {
            filteredItems.push(state.items[o]);
          }

          if (Array.isArray(payload.value) && payload.value.includes(state.items[o][payload.key])) {
            filteredItems.push(state.items[o]);
          }
        });
      }

      return filteredItems;
    },
    GET_ITEMS_BY_LIST: (state, getters) => (payload) => {
      // payload : { list }
      var filteredItems = [];
      if (payload && payload.list && state.lists[payload.list]) {
        filteredItems = state.lists[payload.list].map((o) => state.items[o]);
      }
      return filteredItems;
    },

    PRIMARY_BILLING: (state) => {
      let billing = null;

      Object.keys(state.items).forEach((o) => {
        const item = state.items[o];
        if (item.is_primary > 0) {
          billing = item;
        }
      });

      return billing;
    }
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data == 'object' && payload.data.uid) {
        if (state.items[payload.data.uid]) {
          Vue.set(state.items, payload.data.uid, $.extend({}, state.items[payload.data.uid], payload.data));
        } else {
          Vue.set(state.items, payload.data.uid, payload.data);
        }
      }
    },
    SET_ITEMS(state, payload) {
      if (typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((item) => {
          if (state.items[item.uid]) {
            Vue.set(state.items, item.uid, $.extend({}, state.items[item.uid], item));
          } else {
            Vue.set(state.items, item.uid, item);
          }
        });
      }
    },

    SET_LIST(state, payload) {
      if (payload.list) {
        Vue.set(state.lists, payload.list, []);
      }
    },

    SET_LIST_ITEM(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.uid) {
        if (state.lists[payload.list].indexOf(payload.data.uid) < 0) {
          state.lists[payload.list].unshift(payload.data.uid);
        }
      }
    },

    SET_LIST_ITEMS(state, payload) {
      if (payload.list && state.lists[payload.list] && typeof payload.data == 'object' && payload.data.length) {
        payload.data.forEach((o) => {
          if (state.lists[payload.list].indexOf(o.uid) < 0) {
            state.lists[payload.list].push(o.uid);
          }
        });
      }
    },

    UNSET_ITEM(state, payload) {
      if (payload.data.uid) {
        Object.keys(state.lists).forEach((o) => {
          state.lists[o] = state.lists[o].filter((o) => o != payload.data.uid);
        });
        Vue.delete(state.items, payload.data.uid);
      }
    },
    CLEAR_ITEMS(state) {
      state.items = {};
    },
  },
  actions: {
    addCard({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.post(rootState.config.apiURL + '/v2/payment/billing', payload, { headers: rootState.config.userHeaders }).then((res) => {
          // console.log('addcard res', res);
          // if (res.data.status == 'success') {
          if (res.data.data.status == 'AUTHORIZED') {
            commit('SET_ITEM', { data: res.data.data });
            resolve(res);
          } else {
            reject(res);
          }
        });
      });
    },
    deleteCard({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .delete(rootState.config.apiURL + `/v2/payment/billing/${payload.uid}`, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          });
      });
    },
    updatePrimaryCard({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + `/v2/payment/billing/${payload.uid}/primary`, {}, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          });
      });
    },
    getCard({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios.get(rootState.config.apiURL + `/v2/payment/billing/${payload.id}`, { headers: rootState.config.userHeaders }).then((res) => {
          if (res.data.status == 'success') {
            resolve(res);
          } else {
            reject(res);
          }
        });
      });
    },
    getCardList({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        var queryString = '?';
        if (payload?.query) {
          Object.keys(payload.query).forEach((o) => {
            if (payload.query[o]) queryString += o + '=' + payload.query[o] + '&';
          });
        }
        Vue.axios
          .get(rootState.config.apiURL + '/v2/payment/billing' + queryString + '&status=AUTHORIZED', {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              // const items = res.data.data.items.filter((o) => o.status === 'AUTHORIZED');
              const items = res.data.data.items;
              commit('SET_ITEMS', { data: items });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEMS', { list: payload.list, data: items });
              }
              resolve(items);
            } else {
              reject(res);
            }
          });
      });
    },
    getTransaction({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/payment/transactions/' + payload.id, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            resolve(data);
          })
          .catch(reject);
      });
    },
    
    // For KakaoPay
    kakaopay_ready({ commit, rootState }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/payment/billing/kakaopay/ready`;
          const res = await Vue.axios.post(url, payload, { headers: rootState.config.userHeaders });
          const { data, error } = res.data;

          if (error) {
            throw new Error(error);
          }

          global.localStorage.setItem("KAKAOPAY_CURRENT_BILLING_ID", data.uid);

          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },

    kakaopay_approve({ state, rootState }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          payload.billing_id = global.localStorage.getItem("KAKAOPAY_CURRENT_BILLING_ID") ? parseInt(global.localStorage.getItem("KAKAOPAY_CURRENT_BILLING_ID")) : 0;

          const url = `${rootState.config.apiURL}/v2/payment/billing/kakaopay/approve`;
          const res = await Vue.axios.post(url, payload, { headers: rootState.config.userHeaders });
          const { data, error } = res.data;

          if (error) {
            throw new Error(error);
          }

          
          
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    }
  }
};
export default billings;
