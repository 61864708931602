<template>
  <div class="lug-select" :class="{ disabled: disabled, pointer: pointer }">
    <label>
      <div class="label">{{ label }}</div>
      <div class="body">
        <i class="fa fa-angle-down"></i>
        <select ref="input" v-model="model" :disabled="disabled">
          <option v-if="placeholder" value="" selected>{{ $__t(`${placeholder}`) }}</option>
          <option v-for="(item, index) in items" :value="item.value" :key="index">{{ item.label }}</option>
        </select>
      </div>
    </label>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: ['value', 'type', 'placeholder', 'label', 'error', 'disabled', 'maxlength', 'items', 'pointer'],

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.pointer {
  pointer-events: none;
}
</style>
