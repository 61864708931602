<template>
  <div ref="picker" class="lug-datetime-picker">
    <div class="container">
      <div class="item date" @mousedown="onMouseDown" @touchstart="onMouseDown">
        <div class="hr level-1" />
        <div class="hr level-2" />
        <ul id="date-list">
          <li
            v-for="(date, index) in dates"
            :data-value="date.value"
            :key="date.value"
            :class="{
              activated: activatedDatesIndex === index,
              disabled: disabledDates.some((o) => o === date.value)
            }"
          >
            {{ date.label }}
          </li>
        </ul>
      </div>
      <div class="item hour" @mousedown="onMouseDown" @touchstart="onMouseDown">
        <div class="hr level-1" />
        <div class="hr level-2" />
        <ul id="hour-list">
          <li
            v-for="(hour, index) in hours"
            :data-value="hour.value"
            :key="hour.value"
            :class="{
              activated: activatedHoursIndex === index,
              disabled: disabledHours.some((o) => o === hour.value)
            }"
          >
            {{ hour.label }}
          </li>
        </ul>
      </div>
      <div class="item min" @mousedown="onMouseDown" @touchstart="onMouseDown">
        <div class="hr level-1" />
        <div class="hr level-2" />
        <ul id="min-list">
          <li
            v-for="(min, index) in mins"
            :data-value="min.value"
            :key="min.value"
            :class="{
              activated: activatedMinsIndex === index,
              disabled: disabledMins.some((o) => o === min.value)
            }"
          >
            {{ min.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import utils from '@/utils';

export default {
  props: {
    minInterval: {
      type: Number,
      default: 10
    },
    disabledDates: {
      type: [Object, Array],
      default: () => []
    },
    disabledHours: {
      type: [Object, Array],
      default: () => []
    },
    disabledMins: {
      type: [Object, Array],
      default: () => []
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemHeight: this.calculateRem(),
      startY: {
        dates: 0,
        hours: 0,
        mins: 0
      },
      previousY: {
        dates: 0,
        hours: 0,
        mins: 0
      },
      offset: {
        dates: 0,
        hours: 0,
        mins: 0
      },
      position: {
        dates: 0,
        hours: 0,
        mins: 0
      },
      dates: [],
      hours: new Array(24)
        .fill(0)
        .map((v, i) =>
          String(v + i).length === 1 ? { label: '0' + String(v + i), value: '0' + String(v + i) } : { label: String(v + i), value: String(v + i) }
        ),
      mins: new Array(6).fill(0).map((v, i) =>
        String(v + i * 10).length === 1
          ? {
              label: '0' + String(v + i * 10),
              value: '0' + String(v + i * 10)
            }
          : { label: String(v + i * 10), value: String(v + i * 10) }
      ),
      weekDays: [
        this.$__t('SSA_SUN'),
        this.$__t('SSA_MON'),
        this.$__t('SSA_TUE'),
        this.$__t('SSA_WED'),
        this.$__t('SSA_THU'),
        this.$__t('SSA_FRI'),
        this.$__t('SSA_SAT')
      ],

      selectedDateTime: '',
      isMouseMoving: false
    };
  },

  created() {
    this.setDates();
  },

  async mounted() {
    await this.$nextTick();
    window.addEventListener('resize', (e) => {
      this.itemHeight = this.calculateRem(e);
      ['date-list', 'hour-list', 'min-list'].forEach((o) => {
        this.setPosition(0, o);
      });
    });
    ['date-list', 'hour-list', 'min-list'].forEach((o) => {
      this.setPosition(0, o);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.itemHeight = this.calculateRem(e);
      ['date-list', 'hour-list', 'min-list'].forEach((o) => {
        this.setPosition(0, o);
      });
    });
  },

  watch: {
    position: {
      handler: _.debounce(function (position) {
        const dateIndex = Math.abs(Math.round(position['dates'] / this.itemHeight));
        const hourIndex = Math.abs(Math.round(position['hours'] / this.itemHeight));
        const minIndex = Math.abs(Math.round(position['mins'] / this.itemHeight));

        this.selectedDateTime = this.$moment(
          this.dates[dateIndex].value + ' ' + this.hours[hourIndex].value + ':' + this.mins[minIndex].value
        ).format('YYYY-MM-DD HH:mm');
      }, 10),
      deep: true
    },
    // selectedDateTime: {
    //   handler(datetime){
    //     this.$emit('select', { label: this.getDateStringLabel(datetime), value: datetime})
    //   }
    // },
    value: {
      handler(value) {
        if (this.isMouseMoving === true) return;
        let selectedDate = {},
          selectedHour = {},
          selectedMin = {};

        if (this.dates.length < 1) {
          this.setDates();
        }

        let valueMoment = this.$moment(value);

        this.dates.forEach(function (date, index) {
          if (date.value === valueMoment.format('YYYY-MM-DD')) {
            selectedDate.value = date.value;
            return false;
          }
        });
        this.hours.forEach(function (hour, index) {
          if (hour.value === valueMoment.format('HH')) {
            selectedHour.value = hour.value;
            return false;
          }
        });
        this.mins.forEach(function (min, index) {
          if (min.value === valueMoment.format('mm')) {
            selectedMin.value = min.value;
            return false;
          }
        });

        this.selectedDateTime = selectedDate.value + ' ' + selectedHour.value + ':' + selectedMin.value;

        let momentSelectedDateTime = this.$moment(this.selectedDateTime);

        this.dates.forEach(function (date, index) {
          if (date.value === momentSelectedDateTime.format('YYYY-MM-DD')) {
            selectedDate.index = index;
            return false;
          }
        });

        this.hours.forEach(function (hour, index) {
          if (hour.value === momentSelectedDateTime.format('HH')) {
            selectedHour.index = index;
            return false;
          }
        });
        this.mins.forEach(function (min, index) {
          if (min.value === momentSelectedDateTime.format('mm')) {
            selectedMin.index = index;
            return false;
          }
        });

        this.$nextTick(() => {
          this.position.dates = -1 * this.itemHeight * selectedDate.index;
          this.position.hours = -1 * this.itemHeight * selectedHour.index;
          this.position.mins = -1 * this.itemHeight * selectedMin.index;

          ['date-list', 'hour-list', 'min-list'].forEach((o) => {
            this.setPosition(0, o);
          });
        });
      },
      deep: true,
      immediate: true
    },
    minDate: _.debounce(function (value) {
      this.setDates();

      let valueMoment = this.$moment(value);
      let hourValue = valueMoment.format('HH');
      let minValue = valueMoment.format('mm');

      let hourIndex = this.hours.indexOf(hourValue);
      let minIndex = this.mins.indexOf(minValue);

      this.hours.forEach(function (o, index) {
        if (index < hourIndex) {
          this.disabledHours.push(o.value);
          return false;
        }
      });
      this.mins.forEach(function (o, index) {
        if (index < minIndex) {
          this.disabledMins.push(o.value);
          return false;
        }
      });

      if (this.$moment(this.selectedDateTime).isBefore(this.$moment(value))) {
        let valueMoment = this.$moment(value);
        this.selectedDateTime = valueMoment.format('YYYY-MM-DD HH:mm');
        // this.$emit('select', { label: this.getDateStringLabel(this.selectedDateTime), value: this.selectedDateTime})
        let dateIndex = this.dates.findIndex((date) => date.value === valueMoment.format('YYYY-MM-DD'));
        let hourIndex = this.hours.findIndex((hour) => hour.value === valueMoment.format('HH'));
        let minIndex = this.mins.findIndex((min) => min.value === valueMoment.format('mm'));

        this.position.dates = -1 * this.itemHeight * dateIndex;
        this.position.hours = -1 * this.itemHeight * hourIndex;
        this.position.mins = -1 * this.itemHeight * minIndex;
      } else {
        let dateIndex = this.dates.findIndex((date) => date.value === this.$moment(this.selectedDateTime).format('YYYY-MM-DD'));
        this.position.dates = -1 * this.itemHeight * dateIndex;
      }

      this.$nextTick(() => {
        ['date-list', 'hour-list', 'min-list'].forEach((o) => {
          this.setPosition(0, o);
        });
      });
    }, 50),

    itemHeight() {
      let momentSelectedDateTime = this.$moment(this.selectedDateTime);

      let dateIndex = this.dates.findIndex((date) => date.value === momentSelectedDateTime.format('YYYY-MM-DD'));
      let hourIndex = this.hours.findIndex((hour) => hour.value === momentSelectedDateTime.format('HH'));
      let minIndex = this.mins.findIndex((min) => min.value === momentSelectedDateTime.format('mm'));

      this.position.dates = -1 * dateIndex * this.itemHeight;
      this.position.hours = -1 * hourIndex * this.itemHeight;
      this.position.mins = -1 * minIndex * this.itemHeight;

      ['date-list', 'hour-list', 'min-list'].forEach((o) => {
        this.setPosition(0, o);
      });
    }
  },

  computed: {
    activatedDatesIndex() {
      const value = Math.abs(Math.round(this.position.dates / this.itemHeight));

      return value;
    },

    activatedHoursIndex() {
      const value = Math.abs(Math.round(this.position.hours / this.itemHeight));

      return value;
    },

    activatedMinsIndex() {
      const value = Math.abs(Math.round(this.position.mins / this.itemHeight));

      return value;
    }
  },

  methods: {
    calculateRem(e) {
      const clientWidth = window.innerWidth;
      let rem = 12;

      if (clientWidth < 480) {
        rem = 12;
      } else if (480 <= clientWidth && clientWidth < 640) {
        rem = 13;
      } else if (640 <= clientWidth && clientWidth < 800) {
        rem = 14;
      } else {
        rem = 16;
      }

      return 3.25 * rem;
    },

    onMouseDown(e) {
      if (e.cancelable) e.preventDefault();
      this.isMouseMoving = true;
      const parentIDs = ['date-list', 'hour-list', 'min-list'];

      const parentId = parentIDs.find((id) => utils.isDescendant(e.target, id));

      if (!parentId) return;

      let category = '';

      switch (parentId) {
        case 'date-list':
          category = 'dates';
          break;

        case 'hour-list':
          category = 'hours';
          break;

        case 'min-list':
          category = 'mins';
          break;
      }

      this.previousY[category] = e.touches ? e.touches[0].clientY : e.clientY;
      this.startY[category] = e.touches ? e.touches[0].clientY : e.clientY;

      this.$el.addEventListener('mousemove', this.onMouseMove, {
        passive: false
      });
      this.$el.addEventListener('mouseup', this.onMouseUp, { passive: false });
      this.$el.addEventListener('touchmove', this.onMouseMove, {
        passive: false
      });
      this.$el.addEventListener('touchend', this.onMouseUp, { passive: false });
    },

    onMouseMove(e) {
      if (e.cancelable) e.preventDefault();

      const parentIDs = ['date-list', 'hour-list', 'min-list'];

      const parentId = parentIDs.find((id) => utils.isDescendant(e.target, id));

      if (!parentId) return;

      let category = '';

      switch (parentId) {
        case 'date-list':
          category = 'dates';
          break;

        case 'hour-list':
          category = 'hours';
          break;

        case 'min-list':
          category = 'mins';
          break;
      }

      let clientY = e.touches ? e.touches[0].clientY : e.clientY;
      this.offset[category] = clientY - this.previousY[category];

      let maxPosition = -(this[category].length - 1) * this.itemHeight;
      let _position = this.position[category] + this.offset[category] / 2;
      this.position[category] = Math.max(maxPosition, Math.min(this.itemHeight, _position));
      this.previousY[category] = e.touches ? e.touches[0].clientY : e.clientY;

      this.setPosition(0, parentId);
    },

    onMouseUp(e) {
      if (e.cancelable) e.preventDefault();

      const parentIDs = ['date-list', 'hour-list', 'min-list'];

      const parentId = parentIDs.find((id) => utils.isDescendant(e.target, id));

      if (!parentId) return;

      let category = '';

      switch (parentId) {
        case 'date-list':
          category = 'dates';
          break;

        case 'hour-list':
          category = 'hours';
          break;

        case 'min-list':
          category = 'mins';
          break;
      }

      let maxPosition = -(this[category].length - 1) * this.itemHeight;
      let rounderPosition = Math.round((this.position[category] + this.offset[category] * 5) / this.itemHeight) * this.itemHeight;
      let finalPosition = Math.max(maxPosition, Math.min(0, rounderPosition));

      this.position[category] = finalPosition;

      let onDisableCategory = null;
      let activatedIndex = null;

      if (category === 'dates') {
        onDisableCategory = this.isDisabledDates;
        activatedIndex = this.activatedDatesIndex;
      } else if (category === 'hours') {
        onDisableCategory = this.isDisabledHours;
        activatedIndex = this.activatedHoursIndex;
      } else if (category === 'mins') {
        onDisableCategory = this.isDisabledMins;
        activatedIndex = this.activatedMinsIndex;
      }

      let currentClientY = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;

      let movedY = currentClientY - this.startY[category];

      if (onDisableCategory(activatedIndex)) {
        let value;

        if (Math.abs(movedY) > this.itemHeight * 3) {
          let lowerValidIndexes = [],
            higherValidIndexes = [];

          for (let i = 0; i < this.$data[category].length - 1; i++) {
            if (!onDisableCategory(i)) {
              if (i < activatedIndex) {
                lowerValidIndexes.push(i);
              } else if (i > activatedIndex) {
                higherValidIndexes.push(i);
              }
            }
          }

          if (movedY > 0) {
            if (lowerValidIndexes.length > 0) value = lowerValidIndexes[lowerValidIndexes.length - 1];
            else if (higherValidIndexes.length > 0) value = higherValidIndexes[0];
            else value = 0;
          } else {
            if (higherValidIndexes.length > 0) value = higherValidIndexes[0];
            else if (lowerValidIndexes.length > 0) value = lowerValidIndexes[lowerValidIndexes.length - 1];
            else value = 0;
          }
        } else {
          let loop = true;
          let nth = 1;

          while (loop) {
            let lowerValue, higherValue;

            if (activatedIndex - nth >= 0) {
              lowerValue = !onDisableCategory(activatedIndex - nth);
            }

            if (activatedIndex + nth <= this.$data[category].length - 1) {
              higherValue = !onDisableCategory(activatedIndex + nth);
            }

            if (higherValue) {
              value = activatedIndex + nth;
              loop = false;
            } else if (lowerValue) {
              value = activatedIndex - nth;
              loop = false;
            } else {
              nth = nth - 1;
            }
          }
        }
        this.position[category] += -1 * (value - activatedIndex) * this.itemHeight;
      }

      const dateIndex = Math.abs(Math.round(this.position['dates'] / this.itemHeight));
      const hourIndex = Math.abs(Math.round(this.position['hours'] / this.itemHeight));
      const minIndex = Math.abs(Math.round(this.position['mins'] / this.itemHeight));

      let momentMinDate = this.$moment(this.minDate);

      if (this.$moment(this.dates[dateIndex].value + ' ' + this.hours[hourIndex].value + ':' + this.mins[minIndex].value).isBefore(momentMinDate)) {
        this.position.dates = -1 * this.dates.findIndex((date) => date.value === momentMinDate.format('YYYY-MM-DD')) * this.itemHeight;
        this.position.hours = -1 * this.hours.findIndex((hour) => hour.value === momentMinDate.format('HH')) * this.itemHeight;
        this.position.mins = -1 * this.mins.findIndex((min) => min.value === momentMinDate.format('mm')) * this.itemHeight;

        ['date-list', 'hour-list', 'min-list'].forEach((o) => {
          this.setPosition(Math.abs(this.offset[category]) / 100 + 0.1, o);
        });
      } else {
        this.setPosition(Math.abs(this.offset[category]) / 100 + 0.1, parentId);
      }

      setTimeout(() => {
        this.$emit('select', {
          label: this.getDateStringLabel(this.selectedDateTime),
          value: this.selectedDateTime
        });
      }, 50);

      this.$el.removeEventListener('mousemove', this.onMouseMove);
      this.$el.removeEventListener('mouseup', this.onMouseUp);
      this.$el.removeEventListener('touchmove', this.onMouseMove);
      this.$el.removeEventListener('touchend', this.onMouseUp);
    },

    setPosition(transformDuration = 0, parentId) {
      let category = '';

      switch (parentId) {
        case 'date-list':
          category = 'dates';
          break;

        case 'hour-list':
          category = 'hours';
          break;

        case 'min-list':
          category = 'mins';
          break;
      }

      let itemPosition = `
        transition-timing-function: ease-out;
        transition: transform ${transformDuration}s;
        transform: translateY(${this.position[category]}px);
      `;

      this.$el.querySelector('#' + parentId).style.cssText = itemPosition;
    },

    setDates() {
      let maxDate = this.maxDate;
      let minDate = this.minDate;
      let temp_date;

      if (minDate) {
        minDate = this.$moment(minDate);
        temp_date = this.$moment(minDate);
        maxDate = maxDate ? this.$moment(maxDate) : temp_date.add(1, 'M');
      } else {
        minDate = this.$moment();
        temp_date = this.$moment(minDate);
        maxDate = maxDate ? this.$moment(maxDate) : temp_date.add(1, 'M');
      }

      let dateList = [];
      let date = minDate;

      while (date < maxDate) {
        let formattedDate = date.format('MM/DD');
        let day = date.day();

        if (date.format('MM/DD') === this.$moment().format('MM/DD')) {
          formattedDate = this.$__t('{date-time-picker}.today');
        } else {
          formattedDate += ' (' + this.weekDays[day] + ')';
        }

        dateList.push({
          label: formattedDate,
          value: date.format('YYYY-MM-DD')
        });

        date.add(1, 'd');
      }

      this.$data.dates = dateList;
    },

    isDisabledDates(index) {
      return this.disabledDates.some((date) => this.$moment(date).isSame(this.$moment(this.dates[index].value)));
    },

    isDisabledHours(index) {
      return this.disabledHours.some((hour) => this.$moment(hour).isSame(this.$moment(this.hours[index].value)));
    },

    isDisabledMins(index) {
      return this.disabledMins.some((min) => this.$moment(min).isSame(this.$moment(this.mins[index].value)));
    },

    getDateStringLabel(datestring) {
      let date = this.$moment(datestring);

      let formattedDate = date.format('MM/DD');
      let day = date.day();

      if (date.format('MM/DD') === this.$moment().format('MM/DD')) {
        formattedDate = this.$__t('{date-time-picker}.today');
      } else {
        formattedDate += ' (' + this.weekDays[day] + ')';
      }

      return formattedDate;
    }
  }
};
</script>
