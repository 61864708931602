<template>
  <div class="lug-textinput" :class="{ disabled: disabled }" :style="{ width: computedWidth }">
    <label>
      <div class="label">{{ label }}<span v-if="required" style="color: red"> * </span></div>
      <div class="textarea">
        <the-mask
          v-if="mask"
          :type="type"
          ref="input"
          :placeholder="placeholder"
          v-model="model"
          :disabled="disabled"
          autocapitalize="off"
          autocomplete="off"
          :mask="mask"
          :maxlength="maxlength"
          :inputmode="inputmode"
          :readonly="readonly"
          :class="{ certnumError: alert === true, readonly: readonly }"
        ></the-mask>
        <input
          v-else
          ref="input"
          :type="type"
          v-model="model"
          :placeholder="placeholder"
          :disabled="disabled"
          autocapitalize="off"
          autocomplete="off"
          :maxlength="maxlength"
          @focus="$emit('focus', $event)"
          :inputmode="inputmode"
          :readonly="readonly"
          :class="{ certnumError: alert === true, readonly: readonly }"
        />
      </div>
    </label>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';

export default {
  props: {
    value: {},
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    maxlength: {
      type: Number
    },
    size: {
      type: String,
      default: 'block'
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    mask: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TheMask
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    computedWidth() {
      if (this.size == 'small') return '30%';
      else if (this.size == 'medium') return '65%';
      else return '100%';
    }
  }
};
</script>
