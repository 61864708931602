const GoogleOptimize = {
  name: "GoogleOptimize",

  init(app, options = {}, callback = function() {}) {
    Object.defineProperties(app.prototype, {
      $optimize: {
        get() {
          return this;
        },
      },
    });

    callback();
  },

  variant(name, callback) {
    gtag("event", "optimize.callback", {
      name: name,
      callback: callback,
    });
  },

  activate() {
    gtag("event", "optimize.activate");
  },
};

export default GoogleOptimize;
