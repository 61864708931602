var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-textbox-v2",class:{
    hover: _vm.state.hover,
    focus: _vm.state.focus,
    fill: _vm.state.fill,
    error: _vm.error,
    disabled: _vm.disabled,
    readonly: _vm.readonly,
  }},[_c('label',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" * ")]):_vm._e()]),_c('div',{staticClass:"body"},[(_vm.mask)?[_c('the-mask',{ref:"input",attrs:{"type":"text","placeholder":_vm.placeholder,"readonly":_vm.readonly,"disabled":_vm.disabled,"autocapitalize":"off","autocomplete":"off","maxlength":_vm.maxlength,"inputmode":_vm.inputmode,"mask":_vm.mask},on:{"focus":_vm.onFocus,"blur":_vm.onBlur},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]:[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",attrs:{"type":"text","placeholder":_vm.placeholder,"readonly":_vm.readonly,"disabled":_vm.disabled,"autocapitalize":"off","autocomplete":"off","maxlength":_vm.maxlength,"inputmode":_vm.inputmode},domProps:{"value":(_vm.model)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.model=$event.target.value}}})]],2),(_vm.error)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }