import Vue from "vue"
import VueCookies from "vue-cookies"

const kUserData = '_USER_DATA_'

const userData = {
  namespaced: true,
  state: {},
  mutations: {
    SET_DATA( state, payload ) {
      if(payload.name && payload.expires){
        VueCookies.set(payload.name, payload.value, payload.expires)
      }else{
        let invalidDates = Object.keys(payload).filter( o => payload[o] === 'Invalid date');
        invalidDates.forEach( o => delete payload[o])
        
        let value = JSON.stringify(payload)
        VueCookies.set(kUserData, value, 60*60*24)
      }
    },
    UNSET_DATA(state, payload) {
      if (payload && payload.noDefault && payload.name) {
        VueCookies.remove(payload.name)
      } else {
        VueCookies.remove(kUserData)
      }
    },
  },
  getters: {
    GET_ITEM: ( state, getters ) => payload => {

      if(payload.noDefault && payload.name){
        let data = VueCookies.get(payload.name) || ''

        return data;
      }

      let key = payload.key
      let data = VueCookies.get(kUserData) || {}
      let value = data[key]

      if (key === 'quantity_small') {
        return parseInt(value) || 0
      }
      else if (key === 'quantity_big') {
        return parseInt(value) || 0
      }

      if(value === 'Invalid date'){
        return ""
      }

      return value || ""
    }
  },
  actions: {
    get({ state, rootState, commit, dispatch } ){

      return new Promise( ( resolve, reject ) => {
        try {
          let data = VueCookies.get(kUserData) || {}
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    set({ state, rootState, commit, dispatch }, payload ){

      return new Promise( ( resolve, reject ) => {
        try {

          if(payload.name && payload.expires){

            commit("SET_DATA", payload)

          }else{
            let data = VueCookies.get(kUserData) || {}

            commit( "SET_DATA", {
              ...data,
              ...payload
            })
          }

          resolve()
        }
        catch(e) {
          reject(e)
        }
      })
    },
    clear({ state, rootState, commit, dispatch }){
      return new Promise( ( resolve, reject ) => {
        try {
          commit( "UNSET_DATA")
          resolve()
        }
        catch(e) {
          reject(e)
        }
      });
    }
  }
};

export default userData;
