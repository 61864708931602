import Vue from "vue";

const auth = {
  namespaced: true,
  state: {},
  mutations: {
    SET_HOST(state, payload) {
      Object.keys(payload).forEach((o) => {
        Vue.set(state, o, payload[o]);
      });
    },
    UNSET_HOST(state) {
      Object.keys(state).forEach((o) => {
        Vue.delete(state, o);
      });
    },
  },
  actions: {
    refresh({ state, rootState, commit, dispatch }, payload) {
      let accessToken = rootState.config.getAccessToken();

      if (!accessToken) {
        return Promise.resolve({});
      }

      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + "/v2/manager/hosts/me", { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit("SET_HOST", data);
            resolve({ data });
          })
          .catch((err) => {
            resolve(err);
          });
      });
    },
    create({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + "/v2/manager/hosts", payload, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            resolve({ data });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default auth;
