<template>
  <div id="ui-autocomplete">
    <auto-complete :search="search" base-class="ui-autocomplete" @submit="handleSubmit" :get-result-value="getResultValue">
      <template #default="{rootProps,inputProps,inputListeners,resultListProps,resultListListeners,results,resultProps}">
        <div v-bind="rootProps">
          <input ref="input" v-if="state.fill || state.focus" :placeholder="label" v-model="model" v-on="inputListeners" class="ui-autocomplete-input" @focus="onFocus" @blur="onBlur" />
          <input ref="input" v-if="!state.fill && !state.focus" :placeholder="label" v-model="model" v-on="inputListeners" class="ui-autocomplete-input-no" @focus="onFocus" @blur="onBlur" />
          <i class="fa fa-angle-down" style="position:absolute; right:1rem; top:1.5rem;"></i>
          <ul v-show="state.focus" v-bind="resultListProps" v-on="resultListListeners">
            <li v-for="(result, index) in results" :key="resultProps[index].id" v-bind="resultProps[index]">
              {{ result }}
            </li>
          </ul>
        </div>
      </template>
    </auto-complete>
    <div v-if="state.focus" style="position:absolute;  top:0.35rem; left: 1rem; font-size:0.75rem; color:#48D9EB;">
      {{ label }}
    </div>
    <div v-if="state.fill && !state.focus" style="position:absolute;  top:0.35rem; left: 1rem; font-size:0.75rem; color:rgb( 173, 181, 189 );">
      {{ label }}
    </div>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import AutoComplete from "@trevoreyre/autocomplete-vue";

export default {
  components: {
    AutoComplete,
  },
  props: ["value", "items", "placeholder", "label", "error", "type"],
  data() {
    let state = { hover: false, focus: false, fill: true, error: false };
    return {
      state,
    };
  },
  computed: {
    model: {
      get() {
        if (this.value) {
          this.state.fill = true;
        } else {
          this.state.fill = false;
        }

        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    search(input) {
      let items = this.items;
      let items_arr = [];

      items.forEach((item) => {
        if (item.label.toLowerCase().includes(input.toLowerCase())) {
          items_arr.push(item.label);
        }
      });
      return items_arr;
    },
    getResultValue(result) {
      return result.label;
    },
    handleSubmit(result) {
      let value = result
        .toLowerCase()
        .substring(result.indexOf("("), result.length)
        .trim();

      try {
        if (this.type == "dial") {
          if (value === "(others)" || value === "(etc)") {
            value = "";
          } else {
            value = result.substring(0, result.indexOf(" ("));
          }
        } else if (this.type == "iso") {
          if (value === "(others)" || value === "(etc)") {
            value = "";
          } else {
            value = result;
          }
        }
      } catch (e) {}

      $(this.$refs.input).blur();
      this.$emit("input", value);
    },
    onFocus(e) {
      this.state.focus = true;
      this.$emit("focus", e);
    },
    onBlur(e) {
      this.state.focus = false;
      this.$emit("blur", e);
    },
  },
  mounted() {
    if (!this.$store.state.config.isTouchDevice) {
      this.$refs.input.addEventListener("mouseover", (e) => (this.state.hover = true));
      this.$refs.input.addEventListener("mouseout", (e) => (this.state.hover = false));
    }

    window.addEventListener("scroll", (e) => {
      const rect = this.$el.getBoundingClientRect();
      const bodyHeight = document.body.clientHeight;
      const listElement = $(".ui-autocomplete-result-list", this.$el);

      if (rect.top + 300 > bodyHeight) {
        listElement.css("transform", "translateY(calc(-100% - 4rem))");
      }
    });

    this.$nextTick(() => {
      const rect = this.$el.getBoundingClientRect();
      const bodyHeight = document.body.clientHeight;
      const listElement = $(".ui-autocomplete-result-list", this.$el);

      if (rect.top + 300 > bodyHeight) {
        listElement.css("transform", "translateY(calc(-100% - 4rem))");
      }
    });
  },
};
</script>
