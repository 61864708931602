import VueRipple from 'vue-ripple-directive';
import Button from './ButtonAnimation';

const plugin = {
  install: (app, options = {}) => {
    app.use(Button);

    app.directive('ripple', VueRipple);
  },
};

export default plugin;
