const slider = {
  namespaced: true,
  state: {
    openSlider: true
  },
  getters: {
    GET_ITEM: (state) => {
      return state.openSlider;
    }
  },
  mutations: {
    OPEN_SLIDER(state) {
      state.openSlider = true;
    },
    CLOSE_SLIDER(state) {
      state.openSlider = false;
    }
  }
};

export default slider;
