const debug = require('debug')(`plugin.wninterface.simulator`);
// require('debug').enable('plugin.wninterface.simulator');
require('debug').disable('plugin.wninterface.simulator');

const sampleDevice = {
  appId: `com.lugstay.app.dev`,
  appName: `LugStay`,
  appVersion: `0.2.1`,
  brand: '',
  carrier: '',
  // deviceId: "f649bd5257a64f8a852046558ed1722d",
  deviceId: '229df161194d0f4b6385e61895022688d6',
  deviceName: `WNInterface Sample iPhone`,
  deviceLocale: 'ko-KR',
  deviceModel: 'iPhone XS',
  deviceType: 'mobile',
  deviceVersion: '',
  manufacturer: '',
  osType: 'ios',
  osVersion: '13.3.1',
  userAgent:
    'Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 WNEmulator WNInterface/v1',
  isTablet: false
};

debug('wni-simulator');

class VirtualInterface {
  async _call(funcName, info = {}) {
    try {
      let context = eval(funcName.substr(0, funcName.length - 8)) || window;
      let handler = eval(funcName);
      await handler.call(context, JSON.stringify(info));
    } catch (e) {
      console.error(e);
      debug(e);
    }
  }

  async _exception(data) {
    debug('exception', data);

    let { callback } = data;
    if (callback) {
      let info = { error: 'NOT_SUPPORTED' };
      this._call(callback, info);
    }
  }

  postMessage(message) {
    let json = JSON.parse(message);
    let { version, payload } = json;

    if (version === 'v1') {
      let { command, data } = payload;
      debug('WNInterface.onMessage', command, data);

      switch (command) {
        case 'wnLog':
          this.wnLog(data);
          break;

        case 'wnWebContentLoaded':
          this.wnWebContentLoaded(data);
          break;

        case 'wnAppInfo':
          this.wnAppInfo(data);
          break;

        case 'wnOpenURL':
          this.wnOpenURL(data);
          break;

        case 'wnPermissionVideoAccess':
          this.wnPermissionVideoAccess(data);
          break;

        case 'wnMediaPhotoUpload':
          this.wnMediaPhotoUpload(data);
          break;

        case 'wnScannerOpen':
          this.wnScannerOpen(data);
          break;

        case 'wnOpenAppSetting':
          this.wnOpenAppSetting(data);
        break;

        case 'wnCameraUpload':
          this.wnCameraUpload(data);
          break;

        case 'wnQRCodeScan':
          this.wnQRCodeScan(data);
          break;

        default:
          console.warn(data);
          this._exception(data);
          break;
      }
    }
  }

  async onReady(payload = {}) {
    debug('WNInterface.onReady');

    await window.WNInterface.responder.onInitPage(JSON.stringify(payload));
    // await this._call(`WNInterface && WNInterface.responder.onInitPage`, payload)
  }

  async onAppear(payload = {}) {
    debug('WNInterface.onAppear');

    await window.WNInterface.responder.onResumePage(JSON.stringify(payload));
    // await this._call(`WNInterface && WNInterface.responder.onResumePage`, payload)
  }

  async onDisappear(payload = {}) {
    debug('WNInterface.onDisappear');

    await window.WNInterface.responder.onPausePage(JSON.stringify(payload));
    // await this._call(`WNInterface && WNInterface.responder.onPausePage`, payload)
  }

  async wnWebContentLoaded(payload = {}) {
    debug('wnWebContentLoaded', payload);

    setTimeout(() => {
      this.onReady({
        interfaceVersion: 'v1',
        token: ''
      });
    }, 0);
  }

  async wnLog(payload) {
    debug('wnLog', payload);
  }

  async wnAppInfo(payload) {
    debug('wnAppInfo', payload);

    let { callback } = payload;
    let info = sampleDevice;

    await this._call(callback, info);
  }

  async wnOpenURL(payload) {
    debug('wnOpenURL', payload);

    let { url } = payload;
    window.open(url);
  }

  async wnPermissionVideoAccess(payload) {
    debug('wnPermissionVideoAccess', payload);

    const { callback } = payload;
    await this._call(callback, {
      status: 'granted'
      // status: 'denied'
    });
  }

  async wnScannerOpen(payload) {
    debug('wnScannerOpen', payload);

    const { callback } = payload;
    await this._call(callback, {
      // code: `http://localhost:8080/r/S53`,
      code: `https://dev.lugstay.com/r/S3358`
    });
  }

  async wnMediaPhotoUpload(payload) {
    debug('wnMediaPhotoUpload', payload);

    const { callback } = payload;

    const image1 = 'https://lugstay.s3.ap-northeast-2.amazonaws.com/production/uploaded/sample_luggage_1.jpg';
    const image2 = 'https://lugstay.s3.ap-northeast-2.amazonaws.com/production/uploaded/sample_luggage_2.jpg';

    await this._call(callback, {
      url: Math.random() > 0.5 ? image1 : image2
    });
  }

  async wnOpenAppSetting(payload) {
    debug('wnOpenAppSetting', payload);

    console.warn('네이티브에서는 설정 창이 열립니다.', payload);
  }

  async wnCameraUpload(payload) {
    debug('wnCameraUpload', payload);

    await this.wnMediaPhotoUpload(payload);
  }

  async wnQRCodeScan(payload) {
    debug('wnQRCodeScan', payload);

    await this.wnScannerOpen(payload);
  }
}

global.window.VirtualInterface = new VirtualInterface();
