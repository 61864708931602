var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v2-ui-checkbox",class:{
    hover: _vm.state.hover,
    checked: _vm.model === true,
    error: _vm.error,
    disabled: _vm.disabled,
    readonly: _vm.readonly,
  }},[_c('label',{ref:"hover"},[_c('div',{staticClass:"v2-ui-checkbox__body"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],ref:"input",attrs:{"type":"checkbox","readonly":_vm.readonly,"disabled":_vm.disabled,"true-value":true,"false-value":false},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"change":function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}}}})]),_c('div',{staticClass:"v2-ui-checkbox__label"},[_vm._v(_vm._s(_vm.label))]),(_vm.routerLink)?_c('div',{staticClass:"v2-ui-checkbox__icon"},[_c('router-link',{attrs:{"to":_vm.routerLink.to}},[_vm._v(" "+_vm._s(_vm.routerLink.text)+" ")])],1):_vm._e()]),(_vm.error)?_c('div',{staticClass:"v2-ui-checkbox__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }