import Vue from "vue";
import network from "@/modules/network";

const module = {
  namespaced: true,
  state: {
    myLocation: {
      lat: 37.5729503,
      lng: 126.97935789999997,
    },
    currentLocation: null,
  },
  getters: {
    GET_MY: (state) => {
      return state.myLocation;
    },
    GET_CURRENT: (state) => {
      return state.currentLocation;
    },
  },
  mutations: {
    UPDATE_MY(state, payload) {
      Vue.set(state, "myLocation", payload);
    },
    UPDATE_CURRENT(state, payload) {
      Vue.set(state, "currentLocation", payload);
    },
  },
  actions: {
    geocode({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .get(rootState.config.apiURL + "/v2/stores/geocode", payload.query)
          .then(resolve)
          .catch(reject);
      });
    },
    reverseGeocode({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        network
          .get(rootState.config.apiURL + "/v2/stores/geocode/reverse", payload.query)
          .then(resolve)
          .catch(reject);
      });
    },
  },
};
export default module;
