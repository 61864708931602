<template>
  <div class="ui-calendar" :class="calendarType">
    <div class="header">
      <!--전 달-->
      <button class="calendar-shifter previous" type="button" @click="previousMonth">
        <i class="fa fa-angle-left"></i>
      </button>
      <div class="current-calendar">
        {{ currentCalendar.format($__t('YYYY. MM.')) }}
      </div>
      <!--next month-->
      <button class="calendar-shifter next" type="button" @click="nextMonth">
        <i class="fa fa-angle-right"></i>
      </button>
    </div>
    <div class="body">
      <div class="days">
        <ul>
          <!--요일-->
          <li class="day-header" v-for="i in 7" :key="'A' + i">
            <span>{{
              $moment()
                .day(i - 1)
                .format('ddd')
            }}</span>
          </li>
          <!--지난달-->
          <li
            class="day-previous"
            :class="dayClasses($moment(previousCalendar).date($moment(previousCalendar).daysInMonth() - currentCalendar.startOf('month').day() + i))"
            @click="previousMonth"
            v-for="i in currentCalendar.startOf('month').day()"
            :key="'B' + i"
          >
            <span>{{ $moment(previousCalendar).daysInMonth() - currentCalendar.startOf('month').day() + i }}</span>
          </li>
          <li
            class="day-current"
            :class="dayClasses($moment(currentCalendar).date(i))"
            @click="selectDay($moment(currentCalendar).date(i))"
            v-for="i in currentCalendar.daysInMonth()"
            :key="i"
          >
            <span>{{ i }}</span>
          </li>
          <li
            class="day-next"
            :class="dayClasses($moment(nextCalendar).date(i))"
            @click="nextMonth"
            v-for="i in 6 - $moment(currentCalendar).endOf('month').day()"
            :key="'C' + i"
          >
            <span>{{ i }}</span>
          </li>
        </ul>
      </div>
      <div class="times">
        <ul>
          <li :class="timeClasses(i)" v-for="(n, i) in 31" :key="'D' + i" @click="selectTime(i)">
            <span>{{ i < 10 ? '0' + i : i }}:00</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    calendarType: {
      default: 'datetime'
    },
    dayClassesFunction: {},
    timeClassesFunction: {}
  },
  data() {
    var today = this.$moment().startOf('hour').add(1, 'hour');
    var currentCalendar = this.$moment().startOf('month');
    return {
      today,
      currentCalendar
    };
  },
  computed: {
    previousCalendar() {
      return this.$moment(this.currentCalendar).subtract(1, 'month');
    },
    nextCalendar() {
      return this.$moment(this.currentCalendar).add(1, 'month');
    }
  },
  methods: {
    setCurrentCalendar(calendar) {
      this.currentCalendar = this.$moment(calendar).startOf('month');
    },
    nextMonth() {
      this.currentCalendar = this.$moment(this.currentCalendar.add(1, 'months'));
      this.$emit('shiftNextMonth', this.currentCalendar);
    },
    previousMonth() {
      this.currentCalendar = this.$moment(this.currentCalendar.subtract(1, 'months'));
      this.$emit('shiftPreviousMonth', this.currentCalendar);
    },
    nextYear() {
      this.currentCalendar = this.$moment(this.currentCalendar.add(1, 'years'));
    },
    previousYear() {
      this.currentCalendar = this.$moment(this.currentCalendar.subtract(1, 'years'));
    },
    selectDay(day) {
      // console.log('this.dayClasses(day)', this.dayClasses(day));
      if (this.dayClasses(day).includes('day-disabled')) {
        return;
      }
      this.$emit('selectDay', this.$moment(day));
    },
    selectTime(time) {
      this.$emit('selectTime', time);
    },
    dayClasses(day) {
      if (this.dayClassesFunction) {
        return this.dayClassesFunction(day);
      } else {
        return false;
      }
    },
    timeClasses(time) {
      if (this.timeClassesFunction) {
        return this.timeClassesFunction(time);
      } else {
        return false;
      }
    }
  }
};
</script>
