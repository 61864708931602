import Vue from 'vue';
import { WNInterface } from 'vue-wni';
import config from '@/config';
import isMobile from 'is-mobile';
import network from '@/modules/network';
import $router from '@/router';

const auth = {
  namespaced: true,
  state: {
    profile: {}
  },
  getters: {
    PROFILE: (state) => {
      return state;
    }
  },
  mutations: {
    SET_PROFILE(state, payload) {
      Object.keys(payload).forEach((o) => {
        Vue.set(state, o, payload[o]);
      });
    },
    SET_AUTH(state, payload) {
      // console.log('SET_AUTH payload', payload);
      const { access_token, profile } = payload;

      Object.keys(profile).forEach((o) => {
        Vue.set(state, o, profile[o]);
      });

      const { gid } = profile;

      Vue.$analytics.setUserId(gid);

      if (access_token) {
        config.setAccessToken(access_token);
      }
    },
    UNSET_AUTH(state) {
      // console.trace();
      Object.keys(state).forEach((o) => {
        Vue.delete(state, o);
      });

      Vue.$analytics.setUserId(null);

      config.clearAccessToken();
    }
  },

  actions: {
    refresh({ state, rootState, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        let accessToken = rootState.config.getAccessToken();

        if (!accessToken) {
          resolve({});
          return;
        }

        rootState.config.setAccessToken(accessToken);

        Vue.axios
          .put(rootState.config.apiURL + '/v2/passport/auth/verify', {}, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_AUTH', res.data.data);
            } else {
              if (rootState.serverAvailable !== false) {
                commit('UNSET_AUTH');
              }
            }

            resolve(res);
          })
          .catch((res) => {
            resolve(res);
          });
      });
    },

    redirect({ state, rootState, commit, dispatch }, payload) {
      let { redirect, queryString, query, callback } = payload || {};
      if (typeof callback === 'function') {
        callback();
      }

      if (query.redirect_uri) {
        let accessToken = rootState.config.getAccessToken();

        let url = query.redirect_uri;
        if (url.indexOf("?") !== -1) {
          url = url + '&token=' + accessToken;
        }
        else {
          url = url + '?token=' + accessToken;
        }

        location.replace(url);
        return;
      }

      let url = '';

      if (redirect) {
        url = redirect + (queryString ? queryString : '');
        location.replace(url);
      } else {
        if (rootState.auth.host_id > 0) {
          url = '/host' + (queryString ? queryString : '');
          location.replace(url);
        } else {
          url = '/' + (queryString ?? '');
          location.replace(url); //웹이면 다 있는. vie
        }
      }
    },

    direct({ state, rootState, commit, dispatch }, payload) {
      const { token, queryString, redirect } = payload;

      config.setAccessToken(token);

      console.log( token, queryString, redirect );

      // location.replace(redirect ?? '/');
    },

    setAuth({ state, rootState, commit, dispatch }, payload) {
      commit('SET_AUTH', payload);
    },

    register({ state, rootState, commit, dispatch }, payload) {
      // console.log('register payload', payload);
      return new Promise(async (resolve, reject) => {
        try {
          const url = rootState.config.apiURL + '/v2/user/phone/register';
          const res = await Vue.axios.post(url, payload, { headers: rootState.config.userHeaders });
          const { status, error, data } = res.data;
          // console.log('data', data);
          if (data.profile) {
            // console.log('set_auth');
            commit('SET_AUTH', data);
          }
          // console.log('res', res);
          resolve(res);
        } catch (e) {
          reject(e);
        }
      });
    },

    login({ state, rootState, commit, dispatch }, payload) {
      // console.log('login payload', payload);
      return new Promise(async (resolve, reject) => {
        try {
          const url = rootState.config.apiURL + '/v2/passport/auth/phone';
          const res = await Vue.axios.post(url, payload, { headers: rootState.config.userHeaders });
          // console.log('login res.data', res.data);
          const { status, error, data } = res.data;
          if (error) {
            // throw new Error(error);
            resolve(res);
          }
          if (data.profile) {
            commit('SET_AUTH', data);
          }
          // commit('SET_AUTH', data);
          resolve(res);
        } catch (e) {
          reject(e);
        }
      });
    },

    logout({ state, rootState, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        if (WNInterface.isNative) {
          WNInterface.execute('wnAuthLogout', {});
        }

        commit('UNSET_AUTH');
        location.replace('/?app-intro-viewed=true');
        resolve();
      }).catch((err) => {
        reject(err);
      });
    },

    socialAccess({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.provider == 'facebook') {
          if (WNInterface.isNative) {
            WNInterface.execute('wnAuthLogin', {
              auth_type: 'facebook',
              callback: WNInterface.cb(function (result, error) {
                if (error) {
                  reject(new Error(error));
                  return;
                }

                Vue.axios
                  .post(
                    rootState.config.apiURL + '/v2/passport/auth/facebook',
                    { access_token: result.token },
                    { headers: rootState.config.userHeaders }
                  )
                  .then((res) => {
                    let { data, error } = res.data;
                    if (error) {
                      reject(new Error(error));
                      return;
                    }
                    commit('SET_AUTH', data);
                    resolve(res);
                  })
                  .catch(reject);
              })
            });
          } else {
            // location.replace( "http://www.facebook.com/dialog/oauth/?scope=email&client_id=" + rootState.config.facebookApiAppId + "&redirect_uri=" + rootState.config.siteURL + "/auth/facebook/callback" + "&response_type=token" );
            try {
              FB.getLoginStatus(async function (res) {
                const { status, authResponse } = res || {};
                let access_token = authResponse?.accessToken;

                if (!access_token || status !== 'connected') {
                  access_token = await new Promise((_resolve, _reject) => {
                    FB.login((res) => {
                      const token = res.authResponse?.accessToken;

                      if (token) {
                        _resolve(token);
                      } else {
                        _reject(null);
                      }
                    });
                    console.error(e);
                    _reject(e);
                  });
                }

                return Vue.axios
                  .post(rootState.config.apiURL + '/v2/passport/auth/facebook', { access_token }, { headers: rootState.config.userHeaders })
                  .then((res) => {
                    if (res.data.status == 'success') {
                      commit('SET_AUTH', res.data.data);
                      resolve(res);
                    } else {
                      reject(res);
                    }
                  })
                  .catch(reject);
              });
            } catch (e) {
              console.error(e);
            }
          }
        } else if (payload.provider == 'google') {
          if (WNInterface.isNative) {
            WNInterface.execute('wnAuthLogin', {
              auth_type: 'google',
              callback: WNInterface.cb(function (result, error) {
                if (error) {
                  reject(new Error(error));
                  return;
                }

                Vue.axios
                  .post(
                    rootState.config.apiURL + '/v2/passport/auth/google',
                    { access_token: result.token },
                    { headers: rootState.config.userHeaders }
                  )
                  .then((res) => {
                    let { data, error } = res.data;
                    if (error) {
                      reject(new Error(error));
                      return;
                    }
                    commit('SET_AUTH', data);
                    resolve(res);
                  })
                  .catch(reject);
              })
            });
          } else {
            gapi.load('client', () => {
              gapi.auth2
                .init({
                  clientId: rootState.config.googleApiClientId,
                  cookiepolicy: 'single_host_origin',
                  scope: 'profile email',
                  redirect_uri: rootState.config.siteURL + '/auth/google/callback'
                })
                .then((res) => {
                  res.signIn({ scope: 'profile email' }).then((GoogleUser) => {
                    Vue.axios
                      .post(
                        rootState.config.apiURL + '/v2/passport/auth/google',
                        { access_token: GoogleUser.getAuthResponse().access_token },
                        { headers: rootState.config.userHeaders }
                      )
                      .then((res) => {
                        if (res.data.status == 'success') {
                          commit('SET_AUTH', res.data.data);
                          resolve(res);
                        } else {
                          reject(res);
                        }
                      })
                      .catch(reject);
                  });
                })
                .catch(reject);
            });
          }
        } else if (payload.provider == 'naver') {
          if (WNInterface.isNative) {
            WNInterface.execute('wnAuthLogin', {
              auth_type: 'naver',
              callback: WNInterface.cb(function (result, error) {
                if (error) {
                  reject(new Error(error));
                  return;
                }

                Vue.axios
                  .post(
                    rootState.config.apiURL + '/v2/passport/auth/naver',
                    { access_token: result.token },
                    { headers: rootState.config.userHeaders }
                  )
                  .then((res) => {
                    let { data, error } = res.data;
                    if (error) {
                      reject(new Error(error));
                      return;
                    }
                    commit('SET_AUTH', data);
                    resolve(res);
                  })
                  .catch(reject);
              })
            });
          } else {
            let redirectUri = `${rootState.config.siteURL}/auth/naver/callback&state=${Date.now()}&redirec=${payload.redirect}`;
            let url = `https://nid.naver.com/oauth2.0/authorize?client_id=${config.naverClientId}&response_type=code&redirect_uri=${redirectUri}`;

            if (isMobile()) {
              window.open(url, 'naverLogin');
            } else {
              let width = 500;
              let height = 700;
              let left = screen.width / 2 - width / 2;
              let top = screen.height / 2 - height / 2;
              window.open(url, 'naverLogin', `width=${width},height=${height},left=${left},top=${top}`);
            }
          }
        } else if (payload.provider == 'kakao') {
          if (WNInterface.isNative) {
            WNInterface.execute('wnAuthLogin', {
              auth_type: 'kakao',
              callback: WNInterface.cb(function (result, error) {
                if (result.error) {
                  reject(new Error(JSON.stringify(result.error)));
                  return;
                }

                Vue.axios
                  .post(
                    rootState.config.apiURL + '/v2/passport/auth/kakao',
                    { access_token: result.token },
                    { headers: rootState.config.userHeaders }
                  )
                  .then((res) => {
                    let { data, error } = res.data;
                    if (error) {
                      reject(new Error(error));
                      return;
                    }
                    commit('SET_AUTH', data);
                    resolve(res);
                  })
                  .catch(reject);
              })
            });
          } else {
            Kakao.Auth.login({
              success: function (authObj) {
                Vue.axios
                  .post(
                    rootState.config.apiURL + '/v2/passport/auth/kakao',
                    { access_token: authObj.access_token },
                    { headers: rootState.config.userHeaders }
                  )
                  .then((res) => {
                    if (res.data.status == 'success') {
                      commit('SET_AUTH', res.data.data);
                      resolve(res);
                    } else {
                      reject(res);
                    }
                  })
                  .catch(reject);
              },
              fail: function (err) {
                // alert(JSON.stringify(err));
              }
            });
          }
        } else if (payload.provider == 'wechat') {
          if (WNInterface.isNative) {
            WNInterface.execute('wnAuthLogin', {
              auth_type: 'wechat',
              callback: WNInterface.cb(function (result, error) {
                if (error) {
                  reject(new Error(error));
                  return;
                }

                Vue.axios
                  .post(
                    rootState.config.apiURL + '/v2/passport/auth/wechat',
                    { access_token: result.token, openid: result.openid },
                    { headers: rootState.config.userHeaders }
                  )
                  .then((res) => {
                    let { data, error } = res.data;
                    if (error) {
                      reject(new Error(error));
                      return;
                    }
                    commit('SET_AUTH', data);
                    resolve(res);
                  })
                  .catch(reject);
              })
            });
          } else {
            let redirect_uri = encodeURI(`${rootState.config.siteURL}/auth/wechat/callback`);
            let url = `https://open.weixin.qq.com/connect/qrconnect?appid=${
              config.wechatAppId
            }&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_login&state=${Date.now()}#wechat_redirect`;
            if (isMobile()) {
              window.open(url, 'wechatLogin');
            } else {
              let width = 500;
              let height = 700;
              let left = screen.width / 2 - width / 2;
              let top = screen.height / 2 - height / 2;
              window.open(url, 'wechatLogin', `width=${width},height=${height},left=${left},top=${top}`);
            }
          }
        } else if (payload.provider == 'apple') {
          if (WNInterface.isNative) {
            WNInterface.execute('wnAuthLogin', {
              auth_type: 'apple',
              callback: WNInterface.cb(function (result, error) {
                if (error) {
                  reject(new Error(error));
                  return;
                }

                Vue.axios
                  .post(
                    rootState.config.apiURL + '/v2/passport/auth/apple',
                    { access_token: result.token },
                    { headers: rootState.config.userHeaders }
                  )
                  .then((res) => {
                    let { data, error } = res.data;
                    if (error) {
                      reject(new Error(error));
                      return;
                    }
                    commit('SET_AUTH', data);
                    resolve(res);
                  })
                  .catch(reject);
              })
            });
          } else {
            AppleID.auth.init({
              clientId: rootState.config.appleClientId,
              scope: 'name email',
              redirectURI: rootState.config.siteURL,
              state: Date.now() + '',
              usePopup: true
            });

            AppleID.auth.signIn().then((data) => {
              Vue.axios
                .post(
                  `${rootState.config.apiURL}/v2/passport/auth/apple/oauth/callback`,
                  { code: data.authorization.code, state: data.authorization.state },
                  { headers: rootState.config.userHeaders }
                )
                .then((res) => {
                  if (res.data.status == 'success') {
                    commit('SET_AUTH', res.data.data);
                    resolve(res);
                  } else {
                    reject(res);
                  }
                })
                .catch((res) => {
                  alert(JSON.stringify(res));
                });
            });
          }
        }
      });
    },
    sendReset({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(
            rootState.config.apiURL + '/v2/user/password/reset/send?language=' + rootState.lang,
            { account: payload.user_email },
            { headers: rootState.config.userHeaders }
          )
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    reset({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + '/v2/user/password/reset', payload, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    delete({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + '/v2/user/unregister', payload, { headers: rootState.config.userHeaders })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('UNSET_AUTH');
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    edit({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('formAdditionalAction', payload).then((payloadData) => {
          Vue.axios
            .put(rootState.config.apiURL + '/v2/passport/auth/user/profile', payloadData, {
              headers: rootState.config.userHeaders
            })
            .then((res) => {
              let { data, error } = res.data;
              if (res.data.status == 'success') {
                commit('SET_AUTH', res.data.data);
                resolve(res);
              } else {
                reject(error);
              }
            })
            .catch(reject);
        });
      });
    },
    password({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .put(rootState.config.apiURL + '/v2/passport/auth/user/password', payload, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_AUTH', res.data.data);
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    formAdditionalAction({ state }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.profile_photo) {
          payload.profile_image = JSON.parse(payload.profile_photo).resource_url;
          payload.resources = {};
          payload.resources.profile_photo = JSON.parse(payload.profile_photo);
          payload.resources = JSON.stringify(payload.resources);
          Vue.delete(payload, 'profile_photo');
          resolve(payload);
        } else {
          resolve(payload);
        }
      });
    },
    checkEmail({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/user/check/email?email=' + payload.email, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    agreeTerms({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .post(rootState.config.apiURL + '/v2/agreements/serviceAgree', {}, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;

            if (!error) {
              resolve(data);
            }

            reject(error);
          })
          .catch(reject);
      });
    },
    profile({ state, rootState, commit, dispatch }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/user/profile/me`;
          let data = await network.get(url, payload);
          commit('SET_PROFILE', data);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    //here
    authNumRequest({ state, rootState, commit, dispatch }, payload) {
      // console.log('authNumRequest', payload);
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/passport/auth/request`;
          let data = await network.post(url, payload);
          resolve(data);
        } catch (e) {
          // throw new Error(e);
          reject(e);
        }
      });
    },
    authPhoneCheck({ state, rootState, commit, dispatch }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/passport/auth/phone/check`;
          // let data = await network.get(url, {
          //   headers: rootState.config.userHeaders
          // });
          let data = await network.get(url);
          // console.log('data', data);
          resolve(data);
        } catch (e) {
          throw new Error(e);
        }
      });
    },

    //이건 로그인 되어있는 상태에서 전화번호 인증하는 것.
    async phoneCertifyRequest({ state, rootState, commit, dispatch }, payload) {
      try {
        let url = `${rootState.config.apiURL}/v2/passport/auth/phone/verify`;
        let res = await network.put(url, payload);
        // console.log('phonecert', res);
        return res;
      } catch (e) {
        throw new Error(e);
      }
    }
    //here
  }
};

export default auth;
