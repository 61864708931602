import Vue from "vue"
import VueCookies from "vue-cookies"

const share = {
  namespaced: true,
  state: {},
  mutations: {
    SET_DATA( state, payload ) {
      let value = JSON.stringify(payload)
      VueCookies.set(share, value)
    },
    UNSET_DATA( state ) {
      VueCookies.remove(share)
    },
  },
  getters: {
    GET_ITEM: ( state, getters ) => payload => {
      let key = payload.key
      let data = VueCookies.get(share) || {}
      let value = data[key]

      // if (key === 'quantity_small') {
      //   return parseInt(value) || 1
      // }
      // else if (key === 'quantity_big') {
      //   return parseInt(value) || 1
      // }

      return value || ""
    }
  },
  actions: {
    get({ state, rootState, commit, dispatch } ){
      return new Promise( ( resolve, reject ) => {
        try {
          let data = VueCookies.get(share) || {}
          resolve(data)
        }
        catch(e) {
          reject(e)
        }
      })
    },
    set({ state, rootState, commit, dispatch }, payload ){
      return new Promise( ( resolve, reject ) => {
        try {
          let data = VueCookies.get(share) || {}

          commit( "SET_DATA", {
            ...data,
            ...payload
          })
          resolve()
        }
        catch(e) {
          reject(e)
        }
      })
    },
    clear({ state, rootState, commit, dispatch }){
      return new Promise( ( resolve, reject ) => {
        try {
          commit( "UNSET_DATA")
          resolve()
        }
        catch(e) {
          reject(e)
        }
      });
    }
  }
};

export default share;
