import store from '@/store';
import router from '@/router';
import { translate } from '@/modules/language';
import { WNInterface } from 'vue-wni';

function openLoginModal(route) {
  if (route.query && route.query.token) {
    store.dispatch('auth/direct', { token: route.query.token, redirect: route.query.redirect });
  } else {
    store.commit('modal/ADD_ITEM', {
      component: 'LoginForm',
      size: 'small',
      class: 'for-modal',
      onClose: (target) => {
        target.$analytics.tracking.call(target, 'login', 'click', 'login_close');
      },
      redirect: route.query && route.query.redirect ? route.query.redirect : router.currentRoute.fullPath
    });
  }
}

class NavigationGuard {
  constructor() {
    this._permissionHandler = () => {};
  }

  #setPermissionHandler(handler) {
    this._permissionHandler = handler;
  }

  #clearPermissionHandler() {
    this._permissionHandler = () => {};
  }

  get permissionHandler() {
    return this._permissionHandler;
  }

  changeStatusBar(to, from) {
    const statusBar = to.meta.statusBar;

    WNInterface.execute(
      'wnAppChangeStatusBar',
      statusBar ?? {
        'status-bar-style': 'dark',
        'background-color': '#ffffff'
      }
    );
  }

  checkPermission(to, from) {
    this.#clearPermissionHandler();
    const isLoggedIn = !!store.state.auth.gid;
    const isHostUser = !!store.state.auth.host_id;

    const { level, guest, nohost, host, debug, allowPc } = to.meta || {};
    const { hash } = to || {};

    if (debug) {
      if (store.state.config.mode === 'production') {
        return this.#setPermissionHandler((next) => {
          next('/');
        });
      }
    }

    // if (!allowPc) {
    //   if (!store.state.config.isTouchDevice && hash.indexOf('preview') < 0) {
    //     return this.#setPermissionHandler((next) => {
    //       next({ name: from && from.name ? from.name : 'Home', replace: true });
    //     });
    //   }
    // }

    if (host) {
      if (!isLoggedIn) {
        return this.#setPermissionHandler((next) => {
          openLoginModal({ ...to, query: Object.assign(to.query, { redirect: to.fullPath }) });
          next({ name: from && from.name ? from.name : 'Home', replace: true });
        });
      }

      if (!isHostUser) {
        return this.#setPermissionHandler((next) => {
          setTimeout(() => {
            store.commit('alert/ADD_ITEM', { message: '호스트 회원만 접근할 수 있는 페이지입니다.', status: 'alert' });
          }, 0);
          next({ name: from && from.name ? from.name : 'Home', replace: true });
        });
      }
    }

    if (guest) {
      if (isLoggedIn) {
        return this.#setPermissionHandler((next) => {
          setTimeout(() => {
            store.commit('alert/ADD_ITEM', {
              message: translate('페이지에 접근할 권한이 없습니다.'),
              status: 'alert'
            });
          }, 0);
          next({ name: from && from.name ? from.name : 'Home', replace: true });
        });
      }
    }

    if (nohost) {
      if (isHostUser) {
        return this.#setPermissionHandler((next) => {
          setTimeout(() => {
            store.commit('alert/ADD_ITEM', {
              message: translate('페이지에 접근할 권한이 없습니다.'),
              status: 'alert'
            });
          }, 0);

          next({ name: from && from.name ? from.name : 'Home', replace: true });
        });
      }
    }

    if (level) {
      if (!isLoggedIn) {
        return this.#setPermissionHandler((next) => {
          console.log( "#1" );
          openLoginModal({ ...to, query: Object.assign(to.query, { redirect: to.fullPath }) });
          next({ name: from && from.name ? from.name : 'Home', replace: true });
        });
      }

      if (store.state.auth.role_level < level) {
        return this.#setPermissionHandler((next) => {
          setTimeout(() => {
            store.commit('alert/ADD_ITEM', {
              message: translate('페이지에 접근할 권한이 없습니다.'),
              status: 'alert'
            });
          }, 0);

          next({ name: from && from.name ? from.name : 'Home', replace: true });
        });
      }
    }

    return this.#setPermissionHandler((next) => next(true));
  }
}

export default new NavigationGuard();
