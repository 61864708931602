import Vue from 'vue';
import _ from 'lodash';
import utils from '@/utils';
import network from '@/modules/network';

const rest = {
  namespaced: true,
  state: {
    items: {},
    lists: {}
  },
  mutations: {
    SET_ITEM(state, payload) {
      if (typeof payload.data === 'object' && payload.data.uid) {
        if (state.items[payload.data.uid]) {
          Vue.set(state.items, payload.data.uid, _.extend({}, state.items[payload.data.uid], payload.data));
        } else {
          Vue.set(state.items, payload.data.uid, payload.data);
        }
      }
    },
    SET_ITEMS(state, payload) {
      if (typeof payload.data === 'object' && payload.data.length) {
        payload.data.forEach((item) => {
          if (state.items[item.uid]) {
            Vue.set(state.items, item.uid, _.extend({}, state.items[item.uid], item));
          } else {
            Vue.set(state.items, item.uid, item);
          }
        });
      }
    },
    UNSET_ITEM(state, payload) {
      if (payload.data.uid) {
        Vue.delete(state.items, payload.data.uid);
      }
    },
    CLEAR_ITEMS(state) {
      state.items = {};
    },
    SET_LIST(state, payload) {
      if (payload.list && typeof payload.data === 'object' && payload.data.length) {
        if (!state.lists[payload.list]) {
          Vue.set(state.lists, payload.list, []);
        }

        payload.data.forEach((item) => {
          if (state.lists[payload.list].indexOf(item.uid) < 0) {
            state.lists[payload.list].push(item.uid);
          }
        });
      }
    },
    CLEAR_LIST(state, payload) {
      if (payload.list && state.lists[payload.list]) {
        state.lists[payload.list] = [];
      }
    }
  },
  getters: {
    GET_ITEM: (state, getters) => (payload) => {
      if (payload.key === 'id') {
        return state.items[payload.value] || null;
      }

      var filteredItem = null;
      if (payload && payload.key && payload.value) {
        Object.keys(state.items).forEach((o) => {
          if (state.items[o] && state.items[o][payload.key] == payload.value) {
            filteredItem = state.items[o];
          }
        });
      }
      return filteredItem;
    },
    GET_LIST: (state, getters) => (payload) => {
      var filteredItems = [];
      if (payload && payload.list && state.lists[payload.list]) {
        filteredItems = state.lists[payload.list].map((o) => state.items[o]);
      }

      return filteredItems;
    }
  },
  actions: {
    list({ rootState, commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/services`;

          let data = await network.get(url, {
            language: rootState.lang,
            ...payload
          });

          commit('SET_ITEMS', { data: data.items });

          if (payload.clear) {
            commit('CLEAR_LIST', { list: payload.list });
          }

          if (payload.list) {
            commit('SET_LIST', { list: payload.list, data: data.items });
          }

          resolve(data);
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });
    },
    get({ rootState, commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/services/${payload.id}`;

          let data = await network.get(url, {
            language: rootState.lang,
            ...payload
          });

          commit('SET_ITEM', { data });
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    getProducts({ rootState, commit }, payload) {
      // console.log('payload', payload);
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/services/${payload.service_id}/products`;

          let data = await network.get(url, {
            language: rootState.lang,
            ...payload
          });

          commit('SET_ITEM', { data });
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    create({ rootState, commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/products/purchase/new`;
          let data = await network.post(url, payload);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    update({ state, commit, dispatch, rootState }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/products/purchase/${payload.id}/update`;
          let data = await network.put(url, payload);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    complete({ state, rootState, commit, dispatch }, payload) {
      // console.log('complete payload', payload);
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/products/purchase/${payload.receipt_id}/complete`;
          let data = await network.post(url, payload);
          // console.log('complete data', data);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    cancel({ rootState, commit }, payload) {
      // console.log('cancel payload', payload);
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/products/orders/${payload.id}/cancel`;
          let data = await network.put(url, payload);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    cancelTicket({ rootState, commit }, payload) {
      // console.log('cancel payload', payload);
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/products/tickets/${payload.id}/cancel`;
          let data = await network.put(url, payload);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    updateTicket({ rootState, commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/luggage/tickets/${payload.code}`;
          let data = await network.put(url, payload);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    checkin({ rootState, commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/luggage/tickets/${payload.code}/checkin`;
          let data = await network.put(url, payload);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    openLocker({ rootState, commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/products/ticket/${payload.ticket_id}/open`;
          let data = await network.post(url, payload);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    checkout({ rootState, commit }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const url = `${rootState.config.apiURL}/v2/luggage/reserve/after/ticket/${payload.code}/checkout`;
          let data = await network.put(url, payload);
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },

    //----- 정리중 ------//

    //here
    getDayInfo({ state, rootState, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(`${rootState.config.apiURL}/v2/products?service_id=${payload.id}&day=${payload.date}`, {
            headers: rootState.config.userHeaders
          })
          .then((res) => {
            if (res.data.status == 'success') {
              commit('SET_ITEM', { data: res.data.data });
              if (payload.list) {
                if (!state.lists[payload.list]) commit('SET_LIST', { list: payload.list });
                commit('SET_LIST_ITEM', { list: payload.list, data: res.data.data });
              }
              resolve(res.data.data);
            } else {
              reject(res);
            }
          })
          .catch(reject);
      });
    },
    //here
    order({ state, commit, dispatch, rootState }, payload) {
      return new Promise((resolve, reject) => {
        Vue.axios
          .get(rootState.config.apiURL + '/v2/luggage/orders/' + payload.id, { headers: rootState.config.userHeaders })
          .then((res) => {
            let { data, error } = res.data;
            if (error) {
              reject(new Error(error));
              return;
            }
            commit('SET_ITEM', { data: data });
            resolve(data);
          })
          .catch(reject);
      });
    },
    async authNumRequest({ state, rootState, commit, dispatch }, payload) {
      try {
        let url = `${rootState.config.apiURL}/v2/passport/auth/request`;
        let res = await network.post(url, payload);
        // console.log('authNum res', res);
        return res;
      } catch (e) {
        throw new Error(e);
      }
    },
    async certRequest({ state, rootState, commit, dispatch }, payload) {
      try {
        let url = `${rootState.config.apiURL}/v2/passport/auth/phone/verify`;
        let res = await network.put(url, payload);
        // console.log('certRequest', res);
        return res;
      } catch (e) {
        throw new Error(e);
      }
    }
  }
};

export default rest;
