import store from "@/store";

const Logger = {
  log(log) {
    store.commit("log/ADD_ITEM", { type: 'log', tag: log?.tag || "LOG", message: log?.message || log });
    console.log(log);
  },

  error(error) {
    store.commit("log/ADD_ITEM", { type: 'error', tag: error?.tag || "ERROR", message: error?.message || error });
    console.error(error);
  },

  trace(trace) {
    store.commit("log/ADD_ITEM", { type: 'trace', tag: trace?.tag || "TRACE", message: trace?.message || trace });
    console.trace(trace);
	},

  warn(warn) {
    store.commit("log/ADD_ITEM", { type: 'warn', tag: warn?.log || "WARN", message: warn?.message || warn });
    console.trace(trace);
	},

  info(info) {
    store.commit("log/ADD_ITEM", { type: 'info', tag: info?.log || "INFO", message: info?.message || info });
    console.trace(trace);
  },
};

const plugin = {
  install: (app, options = {}) => {
    app.$log = Logger;

    Object.defineProperties(app.prototype, {
      $log: {
        get() {
          return app.$log;
        },
      },
    });
  },
};

export default plugin;
