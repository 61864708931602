<template>
  <div class="ui-radio-group">
    <div class="label" v-if="label">{{ label }}</div>
    <div class="ui-radio" :class="{ hover: state['input-'+item.value].hover, checked: state['input-'+item.value].checked }" v-for="item in items">
      <label :ref="'input-'+item.value">
        <input type="radio" :value="item.value" v-model="model" />{{ item.label }}
      </label>
    </div>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: [ "value", "items", "label", "error" ],
  data(){
    var state = {};
    this.items.forEach( o => {
      state[ "input-"+o.value ] = { hover: false, checked: false };
    });
    return { state };
  },
  computed: {
    model: {
      get(){
        this.items.forEach( o => {
          this.state[ "input-"+o.value ].checked = this.value == o.value ? true : false;
        });
        return this.value;
      },
      set( value ){
        this.$emit( "input", value );
      }
    }
  },
  mounted(){
    if( !this.$store.state.config.isTouchDevice ){
      this.items.forEach( o => {
        this.$refs[ "input-"+o.value ][0].addEventListener( "mouseover", e => this.state[ "input-"+o.value ].hover = true );
        this.$refs[ "input-"+o.value ][0].addEventListener( "mouseout", e => this.state[ "input-"+o.value ].hover = false );
      });
    };
  }
}
</script>
