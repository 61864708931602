import Vue from 'vue';
import Vuex from 'vuex';
import { WNInterface } from 'vue-wni';
import config from '@/config';
import network from '@/modules/network';
import utils from '@/utils';
// import GoogleMap from "@/modules/map/index.js";

import app from './_/storeApp';
import host from './_/storeHost';
import location from './_/storeLocation';
import resourceForm from './_/storeResourceForm';
import resourceMap from './_/storeResourceMap';
import resourceTicketMessages from './_/storeResourceTicketMessages';
import resourceDelivery from './_/storeResourceDelivery';
import resourceCoupons from './_/storeResourceCoupons';
import resourceReviews from './_/storeResourceReviews';
import resourceNotices from './_/storeResourceNotices';
import resourceCloseDates from './_/storeResourceCloseDates';
import resourceProducts from './_/storeResourceProducts';
import userData from './_/storeUserData';
import alert from './_/storeAlert';
import layout from './_/storeLayout';
import toast from './_/storeToast';
import notice from './_/storeNotice';
import loading from './_/storeLoading';
import dialog from './_/storeDialog';
import shareUser from './_/storeShareUser';
import regions from './_/storeRegions';
import carousel from './_/storeCarousel';
import resourceHostNotices from './_/storeResourceHostNotices';
import path from './_/storePath';
import interval from './_/storeInterval';
import headerCovered from './_/storeHeaderCovered';
// import popup from './_/storePopup';
import log from './_/storeLog';
import iframe from './_/storeIframe';

// models
import auth from './models/Auth';
import stores from './models/Stores';
import orders from './models/Orders';
import billings from './models/Billings';
import tickets from './models/Tickets';
import serviceProduct from './models/ServiceProduct';
import custom from './models/Custom';

// utils
import modal from './utils/Modal';
import popup from './utils/Popup';

// modules
import qrCodeScanner from './modules/qr-code-scanner';
import hostSettlements from './modules/host-settlements';
import bankAccounts from './modules/bank-accounts';

const modules = {
  qrCodeScanner
};

Vue.use(Vuex);

let currentLanguage = localStorage.getItem('lugstay_language');

// console.log('currentLanguage', currentLanguage);
if (!currentLanguage) {
  // currentLanguage = config.userLocale.indexOf('ko') !== -1 ? 'ko' : 'en';
  // console.log('config.userLocale', config.userLocale);
  currentLanguage = config?.userLocale?.indexOf('ko') !== -1 ? 'ko' : 'base';
  // console.log('currentLanguage', currentLanguage);
  localStorage.setItem('lugstay_language', currentLanguage);
}

let developerOption = false;

// if (config.mode !== 'production' && WNInterface.isNative) {
//   developerOption = confirm(
//     '개발자 모드를 활성화하시겠습니까?\n더보기 화면 하단의 버전 숫자를 5초이상 누르면 해제할 수 있습니다.'
//   );
// }

const store = new Vuex.Store({
  modules: {
    alert,
    layout,
    toast,
    notice,
    dialog,
    loading,
    app,
    host,
    location,
    resourceForm,
    resourceMap,
    resourceTicketMessages,
    resourceDelivery,
    resourceCoupons,
    resourceReviews,
    resourceNotices,
    resourceHostNotices,
    resourceCloseDates,
    resourceProducts,
    userData,
    shareUser,
    regions,
    carousel,
    path,
    interval,
    headerCovered,
    popup,
    log,
    iframe,

    // models
    auth,
    stores,
    orders,
    billings,
    tickets,
    serviceProduct,
    custom,

    // utils
    modal,
    popup,

    // modules
    qrCodeScanner,
    hostSettlements,
    bankAccounts
  },
  state: {
    test_target: false,
    lang: currentLanguage,
    config: config,
    network: network,
    wni: WNInterface,
    appIntroViewed: !!utils.getParameterByName('app-intro-viewed'),
    sidebarOpened: false,
    developerOption: developerOption,
    mapVisible: false,
    isPreview: window.location.hash?.indexOf('preview') > -1,
    serverDownCounting: 0,
    serverAvailable: true,
    eventShown: false
  },
  mutations: {
    SET_LANGUAGE(state, payload) {
      if (payload?.label && payload?.value) {
        state.lang = payload.value;
        return;
      }

      state.lang = payload;
      localStorage.setItem('lugstay_language', payload);
    },
    SET_TRUE(state, payload) {
      let { key } = payload;

      state[key] = true;
    },
    SET_FALSE(state, payload) {
      let { key } = payload;

      state[key] = false;
    },
    TOGGLE_STATUS(state, payload) {
      let { key } = payload;

      state[key] = !state[key];
    },
    TURN_EVENT_SHOWN(state, payload) {
      const { mode } = payload;
      state.eventShown = !!mode;
    },
    SERVER_DOWN(state, payload) {
      const { axios, res, error } = payload;

      state.serverDownCounting = state.serverDownCounting + 1;
      if (state.serverDownCounting > 3) {
        state.serverAvailable = false;
      }
      else {
        // if (error && error.config) {
        //   setTimeout(() => {
        //     axios.request(error.config);
        //   }, 500);
        // }
      }
    },
    SERVER_AVAILABLE(state, payload) {
      state.serverDownCounting = 0;
    }
  },
  getters: {
    network: (state) => {
      return state.network;
    },
    ['SERVER_AVAILABLE']: (state) => {
      return state.serverAvailable;
    }
  },
  actions: {
    blockRefreshOnScroll({ state }, payload) {
      if (payload.parent && payload.child) {
        // BLOCK REFRESH ON SCROLL
        var clickEvent = config.isTouchDevice ? 'touchstart' : 'mousedown';
        payload.parent.scrollTop = 1;
        payload.parent.addEventListener('scroll', (e) => {
          if (payload.parent.scrollTop == 0) {
            payload.parent.scrollTop = 1;
          } else if (payload.parent.scrollTop == payload.child.offsetHeight - payload.parent.offsetHeight) {
            payload.parent.scrollTop = payload.child.offsetHeight - payload.parent.offsetHeight - 1;
          }
        });
        // BLOCK REFRESH ON SCROLL
      }
    }
  }
});

export default store;
