import _ from 'lodash';
import store from '@/store';
import NoticePopup from '@/components/resources/notice/NoticePopup.vue';

const initCachedData = () => {
  try {
    const cached = localStorage.getItem('__CUSTOM_CACHED__') || '{}';
    return JSON.parse(cached);
  } catch (e) {
    console.warn(e);
  }
  return {};
};

const custom = {
  namespaced: true,
  state: {
    cached: initCachedData(),
    notices: [
      {
        id: '23010113',
        title: '서버 점검 안내',
        html: `<h3>안내의 말씀</h3>
      <p>안녕하세요.</p>
      <p>실시간 공간공유 물품보관 서비스 럭스테이 입니다.</p>
      <p>더욱 안정적인 서비스 운영을 위해 <b>서비스 점검 및 서버 이전 작업</b>이 진행됩니다.</p>
      <p> </p>
      <p>아래의 내용 참고하셔서 서비스 이용에 참고 바랍니다. :)</p>

      <h3>상세 내용</h3>
      <p>점검기간 : 1/13 (금) ~ 1/15 (일)</p>
      <p>서버 이전으로 인한 서비스 중단</p>
      <p>(럭스테이 전체 서비스 이용 불가)</p>
      <p> </p>
      <p>재개일자 : 1/16 (월)</p>`,
        date: '2023. 01. 13 (금)',
        blockId: '5a97f8f67f9d4d758e829d15c9199f3f',
        // popup: true
      },
      {
        id: '23010101',
        title: '럭스테이 이용 약관 개정 안내',
        html: `<h3>시행일</h3>
      <p>2023년 1월 1일 (일)</p>

      <h3>상세 내용</h3>
      <p>1. 고객, 호스트, 서비스 약관 통합</p>
      <p>2. 정산 규정 추가</p>
      <p>3. 이용 규정 변경</p>`,
        date: '2023. 01. 01 (일)',
        blockId: 'ce394c917c6245eb807cbc5679a702a0',
        popup: true
      },
    ]
  },
  mutations: {
    checkCached(state, payload) {
      if (!state.cached) {
        state.cached = {};
        localStorage.setItem('__CUSTOM_CACHED__', JSON.stringify(state.cached));
      }
    }
  },
  getters: {
    notices: (state, getters) => (payload) => {
      return _.reverse(state.notices);
    },

    notice: (state, getters) => (payload) => {
      return _.find(state.notices, { id: payload.id });
    },

    popupNotice: (state, getters) => {
      const notice = _.find(_.filter(state.notices, (item) => state.cached['notice-' + item.id] !== true), { popup: true } );
      return notice;
    },

    hasNotice: (state, getters) => (payload) => {
      if (!state.cached) {
        return false;
      }

      const notice = getters.popupNotice;

      if (!notice) {
        return false;
      }
      return true;
    }
  },
  actions: {
    checkNoticePopups({ state, rootState, getters, commit, dispatch }, payload) {
      if (getters.hasNotice()) {
        store.commit('modal/ADD_ITEM', { component: NoticePopup, size: 'small', hideClose: false });
      }
    },

    showNoticePopup({ state, rootState, getters, commit, dispatch }, payload) {
      const { id } = payload;
      commit('checkCached', {});
      state.cached['notice-' + id] = true;
      localStorage.setItem('__CUSTOM_CACHED__', JSON.stringify(state.cached));
    }
  }
};

export default custom;
