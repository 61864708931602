import config from "@/config";
import loadScript from "load-script";
import { stringify } from "uuid";

const GoogleAnalyticsDirectives = {
  inserted: function(el, binding, vnode) {
    const events = Object.keys(binding.modifiers);

    if (events.length === 0) {
      events.push("click");
    }

    events.forEach((event) => {
      el.addEventListener(event, function() {
        let fn = typeof binding.value === "string" ? config.commands[binding.value] : binding.value;

        if (!fn) {
          throw new Error("[analytics] The value passed to v-ga is not defined in the commands list.");
        }

        fn.apply(vnode.context);
      });
    });
  },
};

const GoogleAnalytics = {
  name: "GoogleAnalytics",

  init(app, options = {}, callback = function() {}) {
    this._appId = options.appId;
    this._appName = options.appName;
    this._trackingId = options.trackingId || "";
    this._conversionId = options.conversionId || "";
    this._config = {};

    //
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      dataLayer.push(arguments);
    };

    app.directive("ga", GoogleAnalyticsDirectives);

    Object.defineProperties(app.prototype, {
      $ga: {
        get() {
          return this;
        },
      },
    });

    if (this._trackingId) {
      loadScript(`//www.googletagmanager.com/gtag/js?id=${this._trackingId}`, (error, script) => {
        if (error) {
          callback(error, script);
          return;
        }

        callback(error, script);
      });

      gtag("js", new Date());
      gtag("config", this._trackingId, this._config);
      gtag("config", this._conversionId, this._config);
    } else {
      callback();
    }
  },

  setVersion(version) {
    this._version = version;
  },

  setUserId(userId) {
    this._config["user_id"] = userId;

    if (this._trackingId) {
      gtag("config", this._trackingId, this._config);
    }
  },

  setDeviceId(deviceId) {
    //
  },

  setLanguage(language) {
    //
  },

  startSession(options = {}) {
    //
  },

  screen(screen_name) {
    if (this._trackingId) {
      if (this._appId && this._appName && this._version) {
        gtag("event", "screen_view", {
          app_id: this._appId,
          app_name: this._appName,
          app_version: this._version,
          screen_name: screen_name,
        });
      }
    }
  },

  pageView(title, location, path) {
    if (this._trackingId) {
      gtag("event", "page_view", {
        page_title: title,
        page_location: location,
        page_path: path,
        send_to: this._trackingId,
      });
    }
  },

  logEvent(eventName, ...args) {
    if (this._trackingId) {
      gtag("event", eventName, ...args);
    }
  },
};

export default GoogleAnalytics;
