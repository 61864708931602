<template>
  <div class="includes form" id="review-form">
    <div class="form-header">
      <div class="title">{{ $__t( "후기를 남겨주세요!" ) }}</div>
      <div classs="description">{{ payload.store_name }}</div>
    </div>
    <div class="form-body">
      <ui-form @submit="submitForm" :formData="formData" ref="form">
        <ui-form-list>
          <ui-form-list-item>
            <rating-select :placeholder="formData.rate.placeholder" :type="formData.rate.type" :label="formData.rate.label" :error="formData.rate.error" v-model="formData.rate.value" />
          </ui-form-list-item>
          <ui-form-list-item>
            <ui-textbox :placeholder="formData.message.placeholder" :type="formData.message.type" :label="formData.message.label" :error="formData.message.error" v-model="formData.message.value" />
          </ui-form-list-item>
          <ui-form-list-item>
            <ui-solid-button display="block"><button type="submit"><i class="fa fa-pencil-alt"></i>{{ $__t( "Save" ) }}</button></ui-solid-button>
          </ui-form-list-item>
        </ui-form-list>
      </ui-form>
    </div>
  </div>
</template>

<script>
import RatingSelect from "@/components/modules/RichForm/RatingSelect";
export default {
  components: {
    RatingSelect
  },
  props: [ "payload", "mode" ],
  data(){
    var formData = {
      store_id: {
        value: this.payload.store_id
      },
      ticket_id: {
        value: this.payload.ticket_id
      },
      rate: {
        type: "text",
        label: this.$__t( "별점" ),
        placeholder: this.$__t( "별점을 선택해주세요." ),
        validation: [
          { type: "required" },
        ],
        value: this.payload.review ? this.payload.review.rate : 8,
        error: ""
      },
      message: {
        type: "text",
        label: this.$__t( "후기 쓰기" ),
        placeholder: this.$__t( "이 상점에 대해 이야기해주세요." ),
        value: this.payload.review ? this.payload.review.message : "",
        error: ""
      },
    };
    return {
      formData
    };
  },
  methods: {
    submitForm( values ){
      
      this.$store.dispatch( "resourceReviews/" + ( this.payload.review ? "put" : "post" ), { id: this.payload.review ? this.payload.review.uid : null, data: values }).then( res => {
        this.$store.commit( "tickets/SET_ITEM", {
          data: {
            ticket_id: this.payload.ticket_id,
            review: res.data.data
          }
        });
        this.$store.commit( "modal/REMOVE_ITEM", { component: "ReviewForm" });
        this.$store.commit( "alert/ADD_ITEM", { message: "후기를 남겨주셔서 감사합니다.", status: "success" });
      }).catch( err => {
        this.$log.log(err);
        this.$store.commit( "alert/ADD_ITEM", { message: err, status: "error" });
      });
    },
  }
}
</script>


<style lang="scss">
#review-form {
  background-color: #fff;
}
</style>
