<template>
  <div class="interest-toggle" @click.stop.prevent="toggleInterest">
    <img src="/img/heart@3x.png" class="heart" id="not-interested" @mouseover="activated=true" v-if="!interested"/>
    <img src="/img/heart-filled@3x.png" class="heart" id="interested" @mouseout="activated=false" v-else/>
  </div>
</template>

<script>
export default {
  props: [ "store"],
  data() {
    return {
      activated: false,
    }
  },

  computed: {
    interested(){
      return this.store.interested
    }
  },

  methods: {
    toggleInterest( e ){
      var action;
      if( this.$store.state.auth.gid ){
        if( this.interested ){
          this.axios.delete( this.$store.state.config.apiURL + "/v2/stores/"+this.store.id+"/interest", { headers: this.$store.state.config.userHeaders }).then( res => {
            if( res.data.status == "success" ){
              this.$store.commit( "stores/UNSET_ITEM", { data: {id: this.store.id} });
              this.$store.commit( "stores/UNSET_LIST_ITEM", { data: {id: this.store.id}, list: "MyInterestStores" });
              this.activated = false;
            }else{
              this.$store.commit( "alert/ADD_ITEM", { message: res, status: "error" });
            };
          });
        }else{
          this.axios.post( this.$store.state.config.apiURL + "/v2/stores/"+this.store.id+"/interest", {}, { headers: this.$store.state.config.userHeaders }).then( res => {
            if( res.data.status == "success" ){
              this.$store.dispatch( "stores/getInterestList", { list: "MyInterestStores", query: { page: 1,size: 12} })
            }else{
              this.$store.commit( "alert/ADD_ITEM", { message: res, status: "error" });
            };
          });
        };
      }else{
        this.$router.push({ name: "AuthLogin", query: { redirect: this.$router.currentRoute.fullPath } } );
        this.$store.commit( "alert/ADD_ITEM", { message: this.$__t("로그인이 필요한 기능입니다."), status: "alert" });
      };
    }
  },
}
</script>
