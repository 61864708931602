import Vue from "vue"

const interval = {
  namespaced: true,
  state: {
    items: {}
  },
  mutations: {
    SET_DATA( state, payload ) {
      if(typeof payload.data == 'object' && payload.data.key && payload.data.interval){
        const INTERVAL = setInterval( payload.data.callback, payload.data.interval)
				Vue.set(state.items, payload.data.key, INTERVAL );
      }
    },
    UNSET_DATA( state, payload ) {
      if (payload.data.key) {
				if(Object.keys(state.items).includes(payload.data.key)){
          clearInterval(state.items[payload.data.key])
          Vue.delete(state.items, payload.data.key)
        }
			}
    },
    CLEAR_ITEMS( state ){
      if(Object.keys(state.items).length > 0){
        Object.keys(state.items).forEach( o => {
          clearInterval(state.items[o]);
          Vue.delete(state.items, o)
        })
      }
    }
  },
  getters: {
    GET_ITEM: ( state, getters ) => payload => {

    }
  },
  actions: {
    set({ state, rootState, commit, dispatch }, payload ){
      return new Promise( ( resolve, reject ) => {
        try {
          if(payload.key){
            let interval = payload.interval || 1000;
            
            commit('SET_DATA', { data: { key: payload.key, callback: payload.callback, interval: interval}})
          }
          resolve()
        }
        catch(e) {
          reject(e)
        }
      })
    },
    unset({ state, rootState, commit, dispatch }, payload ){
      return new Promise( ( resolve, reject ) => {
        try {
          if(payload.key){            
            commit('UNSET_DATA', { data: { key: payload.key }})
          }
          resolve()
        }
        catch(e) {
          reject(e)
        }
      })
    },
    clear({ state, rootState, commit, dispatch }){
      return new Promise( ( resolve, reject ) => {
        try {
          commit("CLEAR_ITEMS")
          resolve()
        }
        catch(e) {
          reject(e)
        }
      });
    }
  }
};

export default interval;
